<template>
  <div style="width: 218px; display: flex; justify-content: space-between; align-items: center;">
  <v-checkbox class="registCmsAgree" v-if="agree === true" @click="dialog = true" v-model="agree" color="indigo" :value=true hide-details>
  </v-checkbox>
  <div style="font-weight: 400;">
    <a @click="dialog = true" style="cursor: pointer;">
      [클릭] <span style="color:blue;">개인정보 제3자 제공 동의</span>
    </a>
  </div>
</div>

  <v-dialog v-model="dialog" height="800px" class="fontNotoSans400" persistent>
    <v-sheet class="policySheet">
      <v-sheet class="policySheet2"  ref="policyCard">
        <v-card class="policyCard">
          <div style="margin-bottom: 20px; text-align: center;">
            <h2>개인정보 제3자 제공 동의</h2>
          </div>
          <div style="text-align: left;" class="cmsAgreeP">
          <p>
            ◆ 개인정보를 제공받는 자: 나이스페이먼츠 주식회사, 은행(신한/SC제일/씨티/하나/농협/기업/국민/저축/수협/신협/우체국/새마을금고/대구/부산/경남/광주/전북/조흥/제주),
            카드사(국민/비씨/롯데/삼성/NH농협/현대/신한/하나/우리)
          </p>
          <p>
            ◆ 개인정보를 제공받는 자의 개인정보 이용 목적: 자동이체 서비스 제공 및 출금동의 확인, 신규 등록 및 해지 사실 확인 및 통지, 민원 처리
          </p>
          <p>
            ◆ 제공하는 개인정보의 항목: 신청(성명, 연락처, 휴대번호), 예금(카드)주명, 은행(카드)명, 계좌(카드)번호, 생년월일(사업자등록번호), 카드유효기간
          </p>
          <p>
            ◆ 개인정보를 제공받는 자의 개인정보 보유 및 이용기간: 자동이체 신청부터 해지까지. 단, 관련 법령에 의거 일정기간 보유
          </p>
          <p>
            신청자는 개인정보 제3자 제공을 거부할 수 있습니다. 단, 거부하는 경우 자동이체 신청이 정상적으로 처리되지 않습니다.
          </p>
          </div>
        </v-card>
      </v-sheet>
      <div style="text-align: center;">
      <v-btn style="height:40px; width:150px; font-size: 17px; margin: 20px 5px 0 5px; color: blue;" text="동의" @click="agreeCheck"></v-btn>
      <v-btn style="height:40px; width:150px; font-size: 17px; margin: 20px 5px 0 5px;" text="취소" @click="agreeNot"></v-btn>
    </div>

    </v-sheet>

  </v-dialog>

</template>
<script setup lang="ts">
import { ref } from 'vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['success', 'false'])

const dialog = ref(false);
const agree = ref(false);
const agreeCheck = () => {
  dialog.value = false;
  agree.value = true;
  emit('success');
}
const agreeNot = () => {
  dialog.value = false;
  agree.value = false;
  emit('false');
}
</script>
<style>
/* 기본 버튼 스타일 */
.custom-disabled {
  background-color: linear-gradient(45deg, #e0e0e0, #e0e0e0) !important; 
  color: #888888 !important;          /* 비활성화 시의 텍스트 색상 */
  cursor: not-allowed !important;     /* 클릭 불가 커서 */
  opacity: 1 !important;              /* 기본 흐림 효과 제거 */
}

.custom-disabled:hover {
  background-color: #e0e0e0 !important; /* 호버 시에도 같은 색상 유지 */
}
</style>