<template>
  <template v-if="loading">
    <AdminLoadingAction />
  </template>
  <v-data-table
    no-data-text="데이터가 존재하지 않습니다."
    fixed-header
    density="compact"
    scrollable
    :headers="headersArr"
    :items="filteredItems"
    :search="search"
    item-value="name"
    class="empWageBasicTb adminDataTb"
    :items-per-page="-1"
    :items-per-page-options="[
      { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
    ]"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="fontNotoSans700" style="max-width: 160px"
          >🧑‍💼 임금기초</v-toolbar-title
        >
        <h3><FormatMonth /></h3>
        <v-toolbar-title class="fontNotoSans700"> </v-toolbar-title>

        <WageBasicLoadNowEmp @success="getEmpWageBasic()" />
        <v-divider class="mx-4" inset vertical></v-divider>
        <WageBasicLoadEmp @success="getEmpWageBasic()" />
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn class="btnColorDark" v-bind="props"> 저장/삭제 ▾ </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                @click="updateAllEmpWageBasic()"
                class="btnColorUpdate"
                :append-icon="mdiContentSave"
                >일괄 저장</v-btn
              >
            </v-list-item>
            <v-list-item>
              <v-btn
                @click="deleteAllEmpAtt()"
                class="btnColorDark"
                :append-icon="mdiDelete"
                >일괄 삭제</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider class="mx-4" inset vertical></v-divider>
        <WageLedgerPayDate />
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn v-if="endEmpStatus" class="btnColorPurple" @click="endEmpWage">
          마감
        </v-btn>
        <v-btn v-else class="btnColorPurple" @click="cancelEndEmpAtt"> 마감해제 </v-btn>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          class="inoutSearchInput"
          style="
            background-color: #fff;
            max-width: 200px;
            width: auto;
            min-width: 70px;
            margin: 0 20px 0 0;
          "
          v-model="search"
          color="#0f7545"
          label="이름/직위 검색"
          single-line
          clearable
          :append-inner-icon="mdiMagnify"
          flat
          hide-details="auto"
          variant="outlined"
          density="compact"
        >
        </v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item="{ item, index }">
      <tr class="empWageBasicTbTr" @dblclick="onEdit(item)"  @keydown="handleKeydown(item, $event)">
        <td class="tac">{{ index + 1 }}</td>
        <!-- <td class="tac">{{ item.empNum }}</td> -->
        <td class="tac">
          {{ item.empName
          }}<span v-if="item.emp_status === 0">({{ item.emp_out_date }}퇴사)</span>
        </td>

        <td class="tac">{{ item.empJob }}</td>
        <template v-if="!item.isEditing">
          <td class="tar">{{ (item.eaRemuneration)}}</td>
          <!-- <td class="tar">{{ (item.eaTotalPay)}}</td> -->
          <td class="tac">{{ item.eaWorkTime }}</td>
          <!-- <td class="tac">{{ item.eaOverTime }}</td> -->
          <!-- <td class="tac">{{ item.eaNightTime }}</td> -->
          <!-- <td class="tac">{{ item.eaHolidayTime || 0 }}</td> -->
          <td class="tar">{{ (item.eaTimePay) }}</td>
          <td class="tar">{{ (item.eaBasicPay) }}</td>
          <td class="tar">{{ (item.eaOverPay) }}</td>
          <td class="tar">{{ (item.eaNightPay) }}</td>
          <td class="tar">{{ (item.eaBojonPay) }}</td>
          <td class="tar">{{ (item.eaLunchPay) }}</td>
          <td class="tar">{{ (item.eaCarPay) }}</td>

          <td class="tar">{{ (item.eaChildcarePay) }}</td>
          <td class="tar">{{ (item.eaLongtermPay) }}</td>
          <td class="tar">{{ (item.eaBonus) }}</td>
          <td class="tar">{{ (item.eaEduPay) }}</td>
          <td class="tar">{{ (item.eaHolidayPay) }}</td>
          <td class="tar">{{ (item.eaDayOffPay) }}</td>
          <td class="tar">{{ (item.eaEtcPay) }}</td>
          <td class="tar">{{ (item.eaMealDeduct) }}</td>
          <td class="tar">{{ (item.eaEtcDeduct) }}</td>
          <td class="tar">{{ (item.eaYearEnd) }}</td>
        </template>
        <template v-else>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaRemuneration"
              hide-details="auto"
              @focus="clearFormat(item, 'eaRemuneration')"
              @blur="applyFormat(item, 'eaRemuneration')"
              maxlength="12"
            />
          </td>
          <!-- <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaTotalPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaTotalPay')"
              @blur="applyFormat(item, 'eaTotalPay')"
              maxlength="12"
            />
          </td> -->
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaWorkTime"
              hide-details="auto"
              @focus="clearFormat(item, 'eaWorkTime')"
              @blur="applyFormat(item, 'eaWorkTime')"
              maxlength="4"
            />
          </td>
          <!-- <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaOverTime"
              hide-details="auto"
              @focus="clearFormat(item, 'eaOverTime')"
              @blur="applyFormat(item, 'eaOverTime')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaNightTime"
              hide-details="auto"
              @focus="clearFormat(item, 'eaNightTime')"
              @blur="applyFormat(item, 'eaNightTime')"
              maxlength="4"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaHolidayTime"
              hide-details="auto"
              @focus="clearFormat(item, 'eaHolidayTime')"
              @blur="applyFormat(item, 'eaHolidayTime')"
              maxlength="4"
            />
          </td> -->
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaTimePay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaTimePay')"
              @blur="applyFormat(item, 'eaTimePay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaBasicPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaBasicPay')"
              @blur="applyFormat(item, 'eaBasicPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaOverPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaOverPay')"
              @blur="applyFormat(item, 'eaOverPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaNightPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaNightPay')"
              @blur="applyFormat(item, 'eaNightPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaBojonPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaBojonPay')"
              @blur="applyFormat(item, 'eaBojonPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaLunchPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaLunchPay')"
              @blur="applyFormat(item, 'eaLunchPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaCarPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaCarPay')"
              @blur="applyFormat(item, 'eaCarPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaChildcarePay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaChildcarePay')"
              @blur="applyFormat(item, 'eaChildcarePay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaLongtermPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaLongtermPay')"
              @blur="applyFormat(item, 'eaLongtermPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaBonus"
              hide-details="auto"
              @focus="clearFormat(item, 'eaBonus')"
              @blur="applyFormat(item, 'eaBonus')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaEduPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaEduPay')"
              @blur="applyFormat(item, 'eaEduPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaHolidayPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaHolidayPay')"
              @blur="applyFormat(item, 'eaHolidayPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaDayOffPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaDayOffPay')"
              @blur="applyFormat(item, 'eaDayOffPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaEtcPay"
              hide-details="auto"
              @focus="clearFormat(item, 'eaEtcPay')"
              @blur="applyFormat(item, 'eaEtcPay')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaMealDeduct"
              hide-details="auto"
              @focus="clearFormat(item, 'eaMealDeduct')"
              @blur="applyFormat(item, 'eaMealDeduct')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaEtcDeduct"
              hide-details="auto"
              @focus="clearFormat(item, 'eaEtcDeduct')"
              @blur="applyFormat(item, 'eaEtcDeduct')"
              maxlength="12"
            />
          </td>
          <td>
            <v-text-field
              type="text"
              variant="plain"
              v-model="item.eaYearEnd"
              hide-details="auto"
              @focus="clearFormat(item, 'eaYearEnd')"
              @blur="applyFormat(item, 'eaYearEnd')"
              maxlength="12"
            />
          </td>
        </template>
        <td class="tar">{{ totalPay(item) || 0}}</td>
        <td class="tac" v-if="endEmpStatus">
          <v-icon
            v-if="item.isEditing"
            @click="updateEmpWageBasic(item)"
            color="green-darken-3"
            class="SsDeleteIcon"
            >{{ mdiContentSave }}</v-icon
          >
          <v-icon
            v-if="item.isEditing"
            @click="outEdit(item)"
            color="blue-darken-3"
            class="SsDeleteIcon"
            >{{ mdiPenRemove }}</v-icon
          >
          <v-icon
            v-if="!item.isEditing"
            @click="onEdit(item)"
            color="blue-darken-3"
            class="SsDeleteIcon"
            >{{ mdiPen }}</v-icon
          >
          <WageBasicEmpDelete
            v-if="!item.isEditing"
            :item="item"
            @success="getEmpWageBasic()"
          />
        </td>
        <td class="tac" v-else>
          <p>마감완료</p>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import AdminLoadingAction from "@/components/admin/layout/AdminLoadingAction.vue";
import WageBasicEmpDelete from "@/components/admin/services/wage/basic/WageBasicEmpDelete.vue";
import WageBasicLoadEmp from "@/components/admin/services/wage/basic/WageBasicLoadEmp.vue";
import WageBasicLoadNowEmp from "@/components/admin/services/wage/basic/WageBasicLoadNowEmp.vue";
import WageLedgerPayDate from "@/components/admin/services/wage/ledger/WageLedgerPayDate.vue";
import FormatMonth from "@/components/admin/common/datePicker/FormatMonth.vue";
import store from "@/store";
import axios from "axios";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { mdiMagnify, mdiContentSave, mdiPenRemove, mdiPen, mdiDelete } from "@mdi/js";
import router from "@/router";
const loading = ref(false);
const code = ref(store.state.AdminSisulCode) as any;
const date = ref(store.state.AdminYYMM);
const search = ref("");
const empWageBasicList = ref([] as any[]);
const handleKeydown = (item: any, event: any) => {
  if (event.ctrlKey && event.key === 'Enter') {
    updateEmpWageBasic(item);
  }
};
const onEdit = (item: any) => {
  if(!endEmpStatus.value){
    return;
  }
  item.originalItem = { ...item };
  item.isEditing = true;
};
const outEdit = (item: any) => {
  Object.assign(item, item.originalItem); // 원래 값을 복원
  item.isEditing = false;
};

function ac(number: any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return 0;
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}

const totalPay = (item: any) => {
  let calc = 0
  calc =
    nc(item.eaBasicPay || 0)+nc(item.eaOverPay || 0)+nc(item.eaNightPay || 0)+nc(item.eaBojonPay || 0)+
    nc(item.eaLunchPay || 0)+nc(item.eaCarPay || 0)+nc(item.eaChildcarePay || 0)+nc(item.eaLongtermPay || 0)+
    nc(item.eaBonus || 0)+nc(item.eaEduPay || 0)+nc(item.eaHolidayPay || 0)+nc(item.eaDayOffPay || 0)+
    nc(item.eaEtcPay || 0)-(nc(item.eaMealDeduct || 0)+nc(item.eaEtcDeduct || 0))-nc(item.eaYearEnd || 0);
  return ac(calc);
};


          
const headersArr = ref([
  { title: "No.", key: "index", align: "center", sortable: false },
  {
    title: "기초정보",
    align: "center",
    children: [
      // { title: "사원번호", key: "empNum", align: "center", sortable: true },
      { title: "이름", key: "empName", align: "center", sortable: true },
      { title: "직위", key: "empJob", align: "center", sortable: true },
      { title: "보수월액", key: "eaRemuneration", align: "center", sortable: true },
      // { title: "계약임금총액", key: "eaTotalPay", align: "center", sortable: true },
    ],
  },
  {
    title: "근로시간",
    align: "center",
    children: [
      { title: "기본", key: "eaWorkTime", align: "center", sortable: true },
      // { title: "연장", key: "eaOverTime", align: "center", sortable: true },
      // { title: "야간", key: "eaNightTime", align: "center", sortable: true },
      // { title: "휴일", key: "eaHolidayTime", align: "center", sortable: true },
    ],
  },
  {
    title: "계약임금",
    align: "center",
    children: [
      { title: "시급", key: "eaTimePay", align: "center", sortable: true },
      { title: "기본급", key: "eaBasicPay", align: "center", sortable: true },
      { title: "연장수당", key: "eaOverPay", align: "center", sortable: true },
      { title: "야간수당", key: "eaNightPay", align: "center", sortable: true },
      { title: "보존수당", key: "eaBojonPay", align: "center", sortable: true },
      { title: "중식대", key: "eaLunchPay", align: "center", sortable: true },
      { title: "차량비", key: "eaCarPay", align: "center", sortable: true },
      { title: "양육비", key: "eaChildcarePay", align: "center", sortable: true },
    ],
  },
  {
    title: "추가임금",
    align: "center",
    children: [
      { title: "장기근속", key: "eaLongtermPay", align: "center", sortable: true },
      { title: "상여금", key: "eaBonus", align: "center", sortable: true },
      { title: "교육비", key: "eaEduPay", align: "center", sortable: true },
      { title: "휴일수당", key: "eaHolidayPay", align: "center", sortable: true },
      { title: "연차수당", key: "eaDayOffPay", align: "center", sortable: true },
      { title: "기타수당", key: "eaEtcPay", align: "center", sortable: true },
    ],
  },
  {
    title: "공제",
    align: "center",
    children: [
      { title: "식비공제", key: "eaMealDeduct", align: "center", sortable: true },
      { title: "기타공제", key: "eaEtcDeduct", align: "center", sortable: true },
      { title: "연말정산", key: "eaYearEnd", align: "center", sortable: true },
    ],
  },
  { title: "총합계", key: totalPay, align: "center", sortable: true },
  { title: "기능", key: "", align: "center", sortable: false },
] as any[]);

const filteredItems = computed(() => {
  if (!search.value) {
    return empWageBasicList.value;
  }
  return empWageBasicList.value.filter((item) => {
    const matchesSearch =
      (item.empName && item.empName.toLowerCase().includes(search.value.toLowerCase())) ||
      (item.empJob && item.empJob.toLowerCase().includes(search.value.toLowerCase()));
    return matchesSearch;
  });
});

function clearFormat(item: any, key: any) {
  // 인풋의 값을 컴마 없이 만듭니다.
  item[key] = nc(item[key]);

  // 값이 0이면 빈 값으로 설정하여 placeholder를 보이도록 합니다.
  if (!item[key] || item[key] === "0" || item[key] === 0 || item[key] === "-0") {
    item[key] = "";
  }
}

function applyFormat(item: any, key: any) {
  // 값이 비어있으면 0으로 설정
  if (
    item[key] === "" ||
    item[key] === null ||
    item[key] === undefined ||
    item[key] === "-0"
  ) {
    item[key] = 0;
  } else {
    // 포커스가 해제되면 천 단위로 컴마를 추가
    item[key] = formatNumber(item[key]);
  }
}

function formatNumber(value: any): string {
  // 숫자인지 확인하고 천 단위로 컴마 추가
  const number = parseFloat(value);
  if (!isNaN(number)) {
    return number.toLocaleString();
  }
  return value;
}

function nc(value: any) {
  // value가 null 또는 undefined인 경우 빈 문자열로 처리
  if (value == null) {
    return 0; // 기본값을 0으로 설정하거나 다른 적합한 값으로 설정
  }

  // 컴마를 제거하고 숫자로 변환
  return parseFloat(value.toString().replace(/,/g, ""));
}

const getEmpWageBasic = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/getEmpWageBasic/${code.value}/${date.value}`);
    const responseData = response.data;
    if (responseData) {
      empWageBasicList.value = responseData;
      console.log(empWageBasicList.value, "empWageBasicList.value");
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    await getEndEmpAtt();
    loading.value = false;
  }
};
watch(
  () => store.state.AdminYYMM,
  (newValue) => {
    if (newValue) {
      date.value = newValue;
      getEmpWageBasic();
    }
  }
);
const updateEmpWageBasic = async (item: any) => {
  if (loading.value) return;
  if (item.eaWorkTime > 299) {
    alert("기본근로시간을 확인해주세요.");
    return;
  } else if (item.eaOverTime > 99) {
    alert("연장근로시간을 확인해주세요.");
    //일주일 최대 12시간 초과근무 = 12*4.345 = 한달 초과근무 최대시간
    return;
  } else if (item.eaNightTime > 299) {
    alert("야간근로시간을 확인해주세요.");
    return;
  } else if (item.eaHolidayTime > 99) {
    alert("휴일근로시간을 확인해주세요.");
    return;
  }
  // 1주 최대 근로시간은 법정근로시간 40시간, 연장근로 12시간, 휴일근로 16시간(사업장에 따라 휴일이 1일인 경우 8시간)이 가능하여 총 68시간(60시간) 근로가 가능하였습니다.
  const isConfirmed = window.confirm(`${item.empName}님의 정보를 수정하시겠습니까?`);
  if (isConfirmed) {
    loading.value = true;
    try {
      const response = await axios.post(`/api/updateEmpWageBasic/`, { form: item });
      const result = response.data;
      if (result.success === true) {
        console.log("수정완료");
      } else {
        alert("근무내역 수정에 실패하였습니다. 관리자에게 문의해주세요.");
      }
    } catch (error) {
      alert("근무내역 수정을 처리할 수 없습니다.");
      console.error(error);
    } finally {
      loading.value = false;
      item.isEditing = false;
    }
  } else {
    return;
  }
};

const updateAllEmpWageBasic = async () => {
  if (loading.value) return;
  empWageBasicList.value.forEach((item) => {
    if (isNaN(nc(item.eaWorkTime)) || nc(item.eaWorkTime) > 299) {
      alert("기본근로시간을 확인해주세요.");
      return;
    } else if (isNaN(nc(item.eaOverTime)) || nc(item.eaOverTime) > 99) {
      alert("연장근로시간을 확인해주세요.");
      //일주일 최대 12시간 초과근무 = 12*4.345 = 한달 초과근무 최대시간
      return;
    } else if (isNaN(nc(item.eaNightTime)) || nc(item.eaNightTime) > 299) {
      alert("야간근로시간을 확인해주세요.");
      return;
    } else if (isNaN(nc(item.eaHolidayTime)) || nc(item.eaHolidayTime) > 99) {
      alert("휴일근로시간을 확인해주세요.");
      return;
    }
  });

  // 1주 최대 근로시간은 법정근로시간 40시간, 연장근로 12시간, 휴일근로 16시간(사업장에 따라 휴일이 1일인 경우 8시간)이 가능하여 총 68시간(60시간) 근로가 가능하였습니다.
  const isConfirmed = window.confirm("모든 정보를 저장하시겠습니까?");
  if (isConfirmed) {
    loading.value = true;
    try {
      const response = await axios.post(`/api/updateAllEmpWageBasic/`, {
        form: empWageBasicList.value,
      });
      const result = response.data;
      if (result.success === true) {
        console.log("수정완료");
      } else {
        alert("저장에 실패하였습니다. 관리자에게 문의해주세요.");
      }
    } catch (error) {
      alert("저장할 수 없습니다. 입력된 정보를 확인해주세요.");
      console.error(error);
    } finally {
      loading.value = false;
      getEmpWageBasic();
    }
  } else {
    return;
  }
};
const deleteAllEmpAtt = async () => {
  try {
    const isConfirmed = window.confirm("모든 정보를 정말 삭제하시겠습니까?");
    if (isConfirmed) {
      const form = empWageBasicList.value.map((item) => item.empNum);
      const response = await axios.post(
        `/api/deleteAllEmpAtt/${code.value}/${date.value}`,
        { form: form }
      );
      const result = response.data;
      if (result.success === true) {
        await getEmpWageBasic();
        alert("삭제가 완료되었습니다.");
      } else {
        alert("삭제에 실패하였습니다. 관리자에게 문의해주세요.");
      }
    } else {
      return;
    }
  } catch (error) {
    alert("삭제작업을 처리할 수 없습니다.");
    console.error(error);
  }
};

//마감///////////////////////////////////////////////////////////////////////////////////////////
const endEmpStatus = ref(false);
const getEndEmpAtt = async () => {
  try {
    const response = await axios.get(`/api/getEndEmpAtt/${code.value}/${date.value}`);
    const result = response.data;
    if (result.success === true) {
      endEmpStatus.value = false;
    } else {
      endEmpStatus.value = true;
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
function formatDate(dateString: any) {
  const [year, month] = dateString.split("-");
  return `${year}년 ${parseInt(month, 10)}월`;
}
const endEmpWage = async () => {
  try {
    if (empWageBasicList.value.length === 0) {
      alert("임금기초를 작성해주세요.");
      return;
    }
    const isConfirmed = window.confirm(
      `${formatDate(date.value)} 임금기초를 마감하시겠습니까?`
    );
    if (isConfirmed) {
      const response = await axios.post(`/api/endEmpWage/${code.value}/${date.value}`);
      const result = response.data;

      if (result.success === true) {
        await getEmpWageBasic();
        alert("마감이 완료되었습니다.");
        router.push('/WageLedger')
      } else {
        alert("마감에 실패하였습니다. 관리자에게 문의해주세요.");
      }
    } else {
      return;
    }
  } catch (error) {
    alert("작업을 처리할 수 없습니다.");
    console.error(error);
  }
};
const cancelEndEmpAtt = async () => {
  try {
    if (empWageBasicList.value.length === 0) {
      alert("임금기초를 작성해주세요.");
      return;
    }
    const isConfirmed = window.confirm(
      `${formatDate(date.value)} 임금기초 마감을 해제하시겠습니까?`
    );
    if (isConfirmed) {
      const response = await axios.post(
        `/api/cancelEndEmpAtt/${code.value}/${date.value}`
      );
      const result = response.data;
      if (result.success === true) {
        await getEmpWageBasic();
        alert("마감 해제가 완료되었습니다.");
      } else {
        alert("마감 해제를 실패하였습니다. 관리자에게 문의해주세요.");
      }
    } else {
      return;
    }
  } catch (error) {
    alert("작업을 처리할 수 없습니다.");
    console.error(error);
  }
};

onMounted(async () => {
  await getEmpWageBasic();
});
onUnmounted(() => {
  loading.value = false;
});
</script>
