import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import MainLayout from './components/layout/MainLayout.vue'
import MngLayout from './components/layout/MngLayout.vue'
import SsLayout from './components/layout/SsLayout.vue'

// Pages
import DevPage from './pages/auth/DevPage.vue'

import indexPage from './pages/indexPage.vue'

import LoginPage from './pages/auth/LoginPage.vue'
import RegistPage from './pages/auth/RegistPage.vue'

import FinancialAccounting from './pages/main/FinancialAccounting.vue'
import FundManagement from './pages/main/FundManagement.vue'
import LaborManagement from './pages/main/LaborManagement.vue'
import MealManagement from './pages/main/MealManagement.vue'
import OperationConsulting from './pages/main/OperationConsulting.vue'
import BlogPage from './pages/main/BlogPage.vue'
import EditBlog from './pages/main/EditBlog.vue'
import BlogContentView from './pages/main/BlogContentView.vue'


import CrawlingPage from './pages/crawl/CrawlingPage.vue'


import WagePayBack from './pages/wage/WagePayBack.vue'
import WageComplete from './pages/wage/WageComplete.vue'

import MngIndexPage from './pages/admin/MngIndexPage.vue'
import MngDetail from './pages/admin/MngDetail.vue'
import MngOneClientPage from './pages/admin/MngOneClientPage.vue'
import MngMemInfo from './pages/admin/MngMemInfo.vue'
import MngSsPage from './pages/admin/MngSsPage.vue'
import MngCustPage from './pages/admin/MngCustPage.vue'
import MngBoardPage from './pages/admin/board/MngBoardPage.vue'
import MngBoardWright from './pages/admin/board/MngBoardWright.vue'
import MngBoardViewPage from './pages/admin/board/MngBoardViewPage.vue'
import MngEtcHoliday from './pages/admin/MngEtcHoliday.vue'
import MngEtcMail from './pages/admin/MngEtcMail.vue'
import MngInquiry from './pages/admin/MngInquiry.vue'

import SsIndexPage from './pages/sisul/SsIndexPage.vue'
import SsCompPage from './pages/sisul/comp_setting/SsCompPage.vue'
import SsEmpPage from './pages/sisul/emp_setting/SsEmpPage.vue'

//new admin pages
import AdminLayout from './components/admin/layout/AdminLayout.vue'
import AdminBasicLayout from './components/admin/layout/AdminBasicLayout.vue'
import AdminIndex from './pages/admin/common/index/AdminIndex.vue'
import WageMain from './pages/admin/services/wage/WageMain.vue'
import WageBasic from './pages/admin/services/wage/WageBasic.vue'
import WageLedger from './pages/admin/services/wage/WageLedger.vue'
import WageReal from './pages/admin/services/wage/WageReal.vue'
import CompContract from './pages/admin/common/basic-info/CompContract.vue'
import EmpContract from './pages/admin/common/basic-info/EmpContract.vue'


import EmpBasicInfo from './pages/admin/common/basic-info/EmpBasicInfo.vue'
import CompBasicInfo from './pages/admin/common/basic-info/CompBasicInfo.vue'

import FnaMain from './pages/admin/services/fna/FnaMain.vue'
import MealMain from './pages/admin/services/meal/MealMain.vue'


import WorkMain from './pages/admin/services/work/WorkMain.vue'
import ViewSchedules from './pages/admin/services/work/ViewSchedules.vue'
import ScheduleCreate from './pages/admin/services/work/ScheduleCreate.vue'
import WorkReq from './pages/admin/services/work/WorkReq.vue'
import EmpAccountApp from './pages/admin/services/work/EmpAccountApp.vue'
import WorkCog from './pages/admin/services/work/WorkCog.vue'
import SisulAdminDashBoard from './pages/admin/services/dashboard/SisulAdminDashBoard.vue'

import store from './store';
import { markRaw, ref } from 'vue'
const MngIntoSsLayout = ref(null) as any;
if (store.state.role > 3) {
  MngIntoSsLayout.value = markRaw(MngLayout);
} else {
  MngIntoSsLayout.value = markRaw(SsLayout);
}

const routes: readonly RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/indexPage',
  },
  
  { 
    path: '/DevPage',
    component: DevPage,
    name: 'DevPage',
    meta: { layout: MainLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/indexPage',
    component: indexPage,
    name: 'indexPage',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/WagePayBack',
    component: WagePayBack,
    name: 'WagePayBack',
    meta: { layout: MainLayout, requiresAuth: false } 
  },
  { 
    path: '/WageComplete',
    component: WageComplete,
    name: 'WageComplete',
    meta: { layout: MainLayout, requiresAuth: false, requiresWageComplete: true } 
  },
  { 
    path: '/LoginPage',
    component: LoginPage,
    name: 'LoginPage',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/RegistPage',
    component: RegistPage,
    name: 'RegistPage',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/FinancialAccounting',
    component: FinancialAccounting,
    name: 'FinancialAccounting',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/FundManagement',
    component: FundManagement,
    name: 'FundManagement',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/MealManagement',
    component: MealManagement,
    name: 'MealManagement',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/LaborManagement',
    component: LaborManagement,
    name: 'LaborManagement',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/OperationConsulting',
    component: OperationConsulting,
    name: 'OperationConsulting',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/BlogPage',
    component: BlogPage,
    name: 'BlogPage',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/BlogContentView',
    component: BlogContentView,
    name: 'BlogContentView',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/EditBlog',
    component: EditBlog,
    name: 'EditBlog',
    meta: { layout: MainLayout } 
  },
  { 
    path: '/CrawlingPage',
    component: CrawlingPage,
    name: 'CrawlingPage',
    meta: { layout: MainLayout, requiresAuth: true, requiredRole: 8 } 
  },
  { 
    path: '/MngIndexPage',
    component: MngIndexPage,
    name: 'MngIndexPage',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 7 } 
  },
  { 
    path: '/MngDetail',
    component: MngDetail,
    name: 'MngDetail',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 7 }
  },
  { 
    path: '/MngOneClientPage',
    component: MngOneClientPage,
    name: 'MngOneClientPage',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 7 } 
  },
  { 
    path: '/MngCustPage',
    component: MngCustPage,
    name: 'MngCustPage',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 7 } 
  },
  { 
    path: '/MngBoardPage',
    component: MngBoardPage,
    name: 'MngBoardPage',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 7 } 
  },
  { 
    path: '/MngBoardViewPage',
    component: MngBoardViewPage,
    name: 'MngBoardViewPage',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 7 } 
  },
  { 
    path: '/MngBoardWright',
    component: MngBoardWright,
    name: 'MngBoardWright',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 8 } 
  },
  { 
    path: '/MngMemInfo',
    component: MngMemInfo,
    name: 'MngMemInfo',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 8 } 
  },
  { 
    path: '/MngSsPage',
    component: MngSsPage,
    name: 'MngSsPage',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 8 } 
  },
  { 
    path: '/MngEtcHoliday',
    component: MngEtcHoliday,
    name: 'MngEtcHoliday',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 8 } 
  },
  { 
    path: '/MngEtcMail',
    component: MngEtcMail,
    name: 'MngEtcMail',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 8 } 
  },
  { 
    path: '/MngInquiry',
    component: MngInquiry,
    name: 'MngInquiry',
    meta: { layout: MngLayout, requiresAuth: true, requiredRole: 8 } 
  },
  { 
    path: '/SsIndexPage',
    component: SsIndexPage,
    name: 'SsIndexPage',
    meta: { layout: MngIntoSsLayout.value, requiresAuth: true, requiredRole: 3 } 
  },

  { 
    path: '/SsCompPage',
    component: SsCompPage,
    name: 'SsCompPage',
    meta: { layout: MngIntoSsLayout.value, requiresAuth: true, requiredRole: 3 } 
  },
  { 
    path: '/SsEmpPage',
    component: SsEmpPage,
    name: 'SsEmpPage',
    meta: { layout: MngIntoSsLayout.value, requiresAuth: true, requiredRole: 3 } 
  },

//new admins
  { 
    path: '/AdminIndex',
    component: AdminIndex,
    name: 'AdminIndex',
    meta: { layout: AdminBasicLayout, requiresAuth: true, requiredRole: 9 } 
  }, 
  { 
    path: '/FnaMain',
    component: FnaMain,
    name: 'FnaMain',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, { 
    path: '/MealMain',
    component: MealMain,
    name: 'MealMain',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, { 
    path: '/WageMain',
    component: WageMain,
    name: 'WageMain',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, { 
    path: '/WageBasic',
    component: WageBasic,
    name: 'WageBasic',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, { 
    path: '/WageLedger',
    component: WageLedger,
    name: 'WageLedger',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },{ 
    path: '/WageReal',
    component: WageReal,
    name: 'WageReal',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/EmpBasicInfo',
    component: EmpBasicInfo,
    name: 'EmpBasicInfo',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, 
  { 
    path: '/CompContract',
    component: CompContract,
    name: 'CompContract',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, 
  { 
    path: '/EmpContract',
    component: EmpContract,
    name: 'EmpContract',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, 
  { 
    path: '/CompBasicInfo',
    component: CompBasicInfo,
    name: 'CompBasicInfo',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  }, 
  { 
    path: '/WorkMain',
    component: WorkMain,
    name: 'WorkMain',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/ViewSchedules',
    component: ViewSchedules,
    name: 'ViewSchedules',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/ScheduleCreate',
    component: ScheduleCreate,
    name: 'ScheduleCreate',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/WorkReq',
    component: WorkReq,
    name: 'WorkReq',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/EmpAccountApp',
    component: EmpAccountApp,
    name: 'EmpAccountApp',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/WorkCog',
    component: WorkCog,
    name: 'WorkCog',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 9 } 
  },
  { 
    path: '/SisulAdminDashBoard',
    component: SisulAdminDashBoard,
    name: 'SisulAdminDashBoard',
    meta: { layout: AdminLayout, requiresAuth: true, requiredRole: 3 } 
  },
];


const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn; // role 이 0인가를 감지함 !isLoggedIn 이라면 0이거나, 로그인하지 않은 상태임.
  const userRole = store.getters.role; // 사용자의 역할(role)을 가져옵니다. 예를 들어, 9 또는 8이 될 수 있습니다.
  const isWageComplete = store.state.randomCode;
  const RegistPage = to.matched.some(record => record.meta.RegistPage);
  const requiresWageComplete = to.matched.some(record => record.meta.requiresWageComplete);
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth); // 로그인상태
  const requiredRole: number = to.meta.requiredRole as number; // 필요한 역할의 타입을 명시적으로 지정
  if (requiresAuth && !isLoggedIn) {
    next('/LoginPage');
  } else if (to.name === 'LoginPage' && isLoggedIn) {
    next('/');
    // 로그인상태인데 로그인페이지 가려고 할 때 index로 유도.
  } else if(to.name === 'RegistPage' && isLoggedIn){
    next('/');
  } else if(requiresWageComplete && !isWageComplete){
    next('/');
    // 상세진단완료 페이지주소로 바로 접근못하도록 방지.
  } else if(RegistPage && !isLoggedIn){
    next('/RegistPage')
  } else if (requiresAuth && (userRole === 0) && (userRole < requiredRole)){
    alert('회원 인증이 필요합니다.')
    next('/');

    // 미인증자 이상행동 방지.
    // 로그인이 필요한 페이지, role이 0일 때, 페이지role보다 작을 때.

  } else if ( requiresAuth && (userRole >= 1) && (userRole < requiredRole)) {
    alert('기관담당자 권한이 필요합니다.');
    next('/'); 

    // 사원 이상행동 방지.
    // 로그인이 필요한 페이지, role이 사원(1)이상이지만, 페이지role보다 작을 때.

  } else if ( requiresAuth && (userRole >= 3) && (userRole < requiredRole)) {
    alert('매니저 권한이 필요합니다.');
    next('/');

    // 기관담당자 이상행동 방지.
    // 로그인이 필요한 페이지, role이 기관담당자(3)이상이지만, 페이지role보다 작을 때.  

  } else if ( requiresAuth && (userRole >= 7) && (userRole < requiredRole)) {
    alert('관리자 권한이 필요합니다.');
    next('/MngIndexPage');
    // 매니저(영업자) 이상행동 방지.
    // 로그인이 필요한 페이지, role이 매니저(7)이상이지만, 페이지role보다 작을 때.  
  } else {
    next();
  }
});
export default router