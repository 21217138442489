<template>
  <v-card class="workMainCard workMain1Card">
    <div class="card-content">
      <p class="workMainTitle">공지사항</p>
    </div>

    <v-table class="workMain1_2Tb">
      <tbody>
        <tr v-for="(item, index) in notices" :key="index">
          <td class="tal">
            <v-chip
              class="pa-1 mr-1"
              :color="item.color"
              style="height: 20px; font-size: 12px; letter-spacing: -1.2px; font-weight: 500;"
              label
            >
              {{ item.category }}
            </v-chip>
            {{ item.description }}
          </td>
          <td class="tar">{{ item.date }}</td>
        </tr>
      </tbody>
    </v-table>

    <v-card-actions style="display: flex; justify-content: end; align-items: center">
      <v-btn color="primary" class="text-white fw-bold">
        전체 보기({{ notices.length }})
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const notices = ref([
  { category: '기능개선', description: '근무조회 월별기능', date: '2025-01-02', color: 'orange' },
  { category: '기능개선', description: '요청관리 개선', date: '2024-12-29', color: 'orange' },
  { category: '신규기능', description: '신규기능추가', date: '2024-12-21', color: 'green' },
  { category: '기능변경', description: '기준설정 변경안내', date: '2024-12-15', color: 'purple' },
  { category: '공지안내', description: '사원계정 등록안내', date: '2024-12-05', color: 'gray' },
  { category: '기능개선', description: '근무표작성 기능개선', date: '2024-11-30', color: 'orange' },
]);
</script>
