import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import { VCalendar } from 'vuetify/labs/VCalendar'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import 'vuetify/styles'

const myComponents = {
  ...components,
  VCalendar,
  VNumberInput
}

export default createVuetify({
  components: myComponents, 
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {  
      mdi,
    },
  }
})
