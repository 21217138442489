<template>
    <div class="fade-in-up">

  <h2 class="registMainTitle">가입하실 기관을 선택해주세요</h2>

    <p class="tac registSubTitle" style="font-size: 14px; opacity: 0.8; margin: 3px 0 25px 0;">지역,구분 선택 후 가입하실 기관을 선택해주세요.</p>
    <!--elevation : 그림자-->
    <div class="stepperDiv mb-2">
      <v-table class="stepperTb">
        <tbody>
          <tr>
            <td class="tal">지역</td>
            <td class="tal">
              <select
                style="cursor: pointer; min-width:40%;"
                v-model="form.sido"
                @change="getSigungu()"
                class="mg-right10"
              >
                <option value="" class="tac">--- 선택 ---</option>
                <option
                  class="tal"
                  v-for="sido in sidoInfo"
                  :key="sido.sido_id"
                  :value="sido.sido_id"
                >
                  {{ sido.sido_name }}
                </option>
              </select>
              <select
                style="cursor: pointer; min-width:40%;"
                v-model="form.sigungu"
                @change="getCompName()"
              >
                <option value="" class="tac">--- 선택 ---</option>
                <option
                  class="tal"
                  v-for="sigungu in sigunguInfo"
                  :key="sigungu.sigungu_id"
                  :value="sigungu.sigungu_id"
                >
                  {{ sigungu.sigungu_name }}
                </option>
              </select>
            </td>
          </tr>

          <tr>
            <td class="tal">구분</td>
            <td class="tal">
              <v-radio-group
                v-model="form.biz_gubun"
                @change="getCompName()"
                class="label-black"
                inline
                flat
                hide-details="auto"
              >
                <v-radio label="요양시설" value="요양시설"></v-radio>
                <v-radio label="주간보호" value="주간보호"></v-radio>
              </v-radio-group>
            </td>
          </tr>
          <tr>
            <td class="tal">기관 명</td>
            <td class="tal">
              <v-combobox
                v-model="form.name"
                :items="compNames"
                placeholder="기관 명을 입력해주세요."
                type="text"
                variant="outlined"
                density="compact"
                hide-details="auto"
                style="max-width: 280px !important; color: #000 !important"
                class="activeClick"
              >
              </v-combobox>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
   
    <v-btn
      :class="{ clientInfoBtn: status, 'not-agree-disabled': !status }"
      @click="getCompInfo()"
      >확인</v-btn
    >
    </div>
</template>
<script setup lang="ts">
import axios from "axios";
import { onMounted, reactive, ref, watch } from "vue";
import store from "@/store";
const sidoInfo = ref([] as any);
const sigunguInfo = ref([] as any);
const compNames = ref([] as any[]);
const compInfo = ref();
// eslint-disable-next-line no-undef
const emit = defineEmits(["success"]);
const form = reactive({
  name: "",
  sido: "",
  sigungu: "",
  biz_gubun: "",
});
const status = ref(false);

watch(form, async () => {
  if (form.name && form.sido && form.sigungu && form.biz_gubun) {
    status.value = true;
  } else {
    status.value = false;
  }
});

const getSido = async () => {
  try {
    const resSido = await axios.get(`/api/getSido/`);
    const SidoData = resSido.data;
    if (SidoData) {
      sidoInfo.value = SidoData;
      return SidoData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};

const getSigungu = async () => {
  form.sigungu = "";
  form.name = "";
  if (form.sido === "") {
    compNames.value = [];
    return;
  }
  try {
    const resSigungu = await axios.get(`/api/getSigungu/${form.sido}`);
    const SigunguData = resSigungu.data;
    if (SigunguData) {
      sigunguInfo.value = SigunguData;
      compNames.value = [];
      return SigunguData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};

const getCompName = async () => {
  form.name = "";

  if (!form.biz_gubun || !form.sido) {
    compNames.value = [];
    return;
  }
  if (!form.sigungu) {
    if (parseInt(form.sido) !== 36) {
      // 세종 시군구없음 방지
      return;
    } else {
      form.sigungu = "sejong";
    }
  }
  try {
    const response = await axios.get(
      `/api/getCompName/${form.sido}/${form.sigungu}/${form.biz_gubun}`
    );
    const responseData = response.data;
    if (responseData) {
      compNames.value = responseData.map((item: { hc_name: any }) => `${item.hc_name}`);
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};

const getCompInfo = async () => {
  if (
    !form.sido ||
    !form.sigungu ||
    !form.biz_gubun ||
    !form.name
  ) {
    return;
  }
  try {
    const response = await axios.get(
      `/api/getCompInfo/${form.sido}/${form.sigungu}/${form.biz_gubun}/${form.name}`
    );
    const responseData = response.data;
    if (responseData) {
      if(responseData === '실패'){
        alert('이미 완료된 요청입니다.');
      }else{
        compInfo.value = responseData;
        sisul_boss_email.value = store.state.RegistForm.sisul_boss_email;
        compInfo.value.sisul_boss_email = sisul_boss_email.value;
        store.commit("setRegistForm", compInfo.value);
        emit("success");
      }
        return responseData;
    } else {
      alert('기관을 찾을 수 없습니다. 관리자에게 문의해주세요.')
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
const sisul_boss_email = ref('');
onMounted(async () => {
  await getSido();
});
</script>
