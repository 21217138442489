<template>
  <v-app id="inspire" style="background-color: #fcfdff">
    <v-app-bar id="LayoutHeader" :style="headerStyle">
      <v-container
        class="mainLayoutHeaderContainer d-flex align-center justify-center"
      >
        <img
          class="mainLayoutHeaderLogo"
          @click="indexPage"
          src="@/assets/pictures/함우리로고.png"
        />

        <!-- <v-menu
          transition="scroll-x-transition"
          open-on-hover
          open-delay="0"
          close-delay="120"
          :offset="[13, -12]"
        >
          <template v-slot:activator="{ props }">
            <v-btn class="middleLayoutBtn" v-bind="props" :class="{ 'selected-main-menu': links.some(link => $route.path.includes(link)) }"> 서비스 </v-btn>
          </template>
          <v-row class="mainMenu_service">
            <v-col cols="12">
              <v-list>
                <v-list-item
                  v-for="(item, index) in titles"
                  :key="index"
                  :class="{ 'selected-main-service-item': $route.path.includes(links[index]) }"
                  @click="selectItem(index)"
                >
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-menu> -->
          <!-- <v-btn class="middleLayoutBtn" @click="goBlog()" :class="{ 'selected-main-menu': $route.path.includes('BlogPage') }"> 블로그 </v-btn> -->
        <!-- <v-btn class="middleLayoutBtn">고객지원</v-btn> -->
        <v-spacer></v-spacer>
        <div class="mainLayoutHeaderBtnDiv">
          <v-btn v-if="role === 3" @click="SsIndexPageMove" class="topLayoutBtn mg-right10"
            >기관관리페이지</v-btn
          >
          <v-btn v-if="role >= 7" @click="mngIndexPageMove" class="topLayoutBtn mg-right10"
            >관리자페이지</v-btn
          >
          <v-btn v-if="role >= 8" class="topLayoutBtn mg-right10" @click="openCrawlingPage"
            >크롤링</v-btn
          >
          <!-- <v-btn v-if="role >= 9" class="topLayoutBtn mg-right10" @click="openDevPage"
            >개발페이지</v-btn
          > -->
          <v-btn v-if="role >= 9" class="topLayoutBtn mg-right10" @click="openAdminPage"
            >개발새창!!</v-btn
          >
          <v-btn v-if="role >= 9 && id === 'RoleNineValue' " class="topLayoutBtn mg-right10" @click="goToTest"
            >로컬접속</v-btn
          >
          <InquiryForm />
          <v-btn class="topLayoutBtn" @click="logRemote"  :class="{ 'selected-main-menu': $route.path.includes('LoginPage') }">{{
            isLoggedIn ? "로그아웃" : "로그인"
          }}</v-btn>
        </div>
      </v-container>
    </v-app-bar>

    <v-main id="LayoutBody">
      <v-container class="mainContainer">
        <slot />
        <!-- <InquiryFormIcon /> -->
        <!-- <WagePayBack /> -->
      </v-container>
    </v-main>
    <v-footer color="#191919" class="footer">
      <v-container class="footerContainer">
        <v-row>
          <v-col
            cols="1"
            style="align-items: center; display: flex; justify-content: center"
          >
            <img
              class="footerLogo"
              src="@/assets/pictures/함우리로고.png"
              style="height: auto; cursor: pointer"
            />
          </v-col>
          <v-col cols="10" class="white--text">
            <div class="grey--text text--lighten-1">
              주식회사 함우리 | 사업자등록번호 : 185-81-02287 | 서울시 송파구 법원로90,
              전북특별자치도 전주시 덕진구 기지로77
            </div>
            <div class="grey--text text--lighten-1">
              이메일 : hamwoori25@naver.com | 전화번호 : 1833-7489
            </div>
            <div class="grey--text text--lighten-2">
              Copyright © 2024 Hamwoori. All Rights Reserved.
              <span class="grey--text text--lighten-2">Design by Hamwoori</span>
            </div>
          </v-col>
          <v-col
            cols="1"
            style=""
          >
            <img
              class="footerLogo"
              src="@/assets/pictures/가족친화인증마크.png"
              style="height: auto;"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { onMounted, ref, watch, onBeforeUnmount } from "vue";
import InquiryForm from "../wage/InquiryForm.vue";
// import InquiryFormIcon from "../wage/InquiryFormIcon.vue";
// import WagePayBack from "@/components/main/WagePayBack.vue";
import { format, lastDayOfMonth  } from "date-fns";

const topHeaderBgColor = ref("transparent");
const headerBgColor = ref("transparent");
const boxShadowStyle = ref("none");
const headerStyle = ref({
  backgroundColor: headerBgColor.value,
  boxShadow: boxShadowStyle.value,
  transition: "background-color 0.5s ease, box-shadow 0.5s ease", // 애니메이션 적용
});

watch(
  () => store.state.MainLayoutScroll, // 감시할 값
  (newValue) => {
    if (newValue > 0) {
      // 스크롤이 발생했을 때 스타일 변경
      topHeaderBgColor.value = "#090c34";
      headerBgColor.value = "rgba(255, 255, 255, 0.7)";
      boxShadowStyle.value = "0 2px 6px rgba(83,83,83,0.15)";
    } else {
      // 스크롤이 최상단일 때 스타일 초기화
      topHeaderBgColor.value = "transparent";
      headerBgColor.value = "transparent";
      boxShadowStyle.value = "none";
    }

    // 스타일 업데이트
    headerStyle.value = {
      backgroundColor: headerBgColor.value,
      boxShadow: boxShadowStyle.value,
      transition: "background-color 0.5s ease, box-shadow 0.5s ease", // 애니메이션 적용
    };
  }
);
onBeforeUnmount(() => {
  // 컴포넌트가 파괴될 때 스크롤 감지 핸들러 제거
  window.removeEventListener("scroll", handleScroll);
});

// 스크롤 이벤트 핸들러
function handleScroll() {
  store.commit("setMainLayoutScroll", window.scrollY); // 스크롤 위치 저장
}

const account = ref({
  id: null,
  name: "",
  role: "",
});
const isLoggedIn = store.getters.isLoggedIn;
const role = store.getters.role;
const id = store.getters.id
const indexPage = () => {
  router.push("/");
}
// const goBlog = () => {
//   router.push("/BlogPage");
// }
const LoginPage = () => {
  router.push("/LoginPage");
};
const openCrawlingPage = () => {
  router.push("/CrawlingPage");
};
// const openDevPage = () => {
//   router.push("/DevPage");
// };
const logout = () => {
  axios.delete("/api/account").then(() => {
    alert("로그아웃하였습니다.");
    account.value.id = null;
    account.value.name = "";
    account.value.role = "";
    // sessionStorage.removeItem('vuex_state');
    // sessionStorage.removeItem('mainDateInfo');
    // sessionStorage.removeItem('selected_member');
    // sessionStorage.removeItem('mngOneClient');
    // sessionStorage.removeItem('wageDirectPushData');
    // sessionStorage.removeItem('wageDirectPushBox');
    // sessionStorage.removeItem('status2Form');
    // sessionStorage.removeItem('mng2Form');
    // sessionStorage.removeItem('getBoardCode');
    // sessionStorage.removeItem('randomCode');
    // sessionStorage.removeItem('crawl_Result_List');
    // sessionStorage.removeItem('SsInfoBox');
    // sessionStorage.removeItem('MngIntoSs');
    // sessionStorage.removeItem('getMngTitle');
    // sessionStorage.removeItem('getSsYm');
    // sessionStorage.removeItem('getSsECDEmpInfo');
    // sessionStorage.removeItem('getSsECDBox');
    sessionStorage.clear();

    window.location.reload();
  });
};

// 기관관리 새창

const SsWindow = ref();
const SsIndexPageMove = () => {
  const screenWidth = window.screen.width; // 화면의 너비
  const screenHeight = window.screen.height; // 화면의 높이

  const width = Math.round(screenWidth * 1); // 화면 너비의 5분의 3
  const height = Math.round(screenHeight * 1); // 화면 높이의 5분의 3

  const left = (screenWidth - width) / 2; // 화면 가운데 정렬을 위한 left 값
  const top = (screenHeight - height) / 2; // 화면 가운데 정렬을 위한 top 값

  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}`;

  // 관리 페이지 URL
  const url = "#/SsIndexPage";
  // 새 창 열기 전에 로컬 스토리지에 페이지 제목 저장
  // localStorage.setItem('currentPageTitle', '메인');
  store.commit("setMngTitle", "메인");

  // 기존에 'Mng'가 포함된 URL의 창이 열려 있는지 확인
  if (SsWindow.value && !SsWindow.value.closed) {
    // 창이 열려 있고 'Mng'가 포함된 URL이라면 해당 창을 맨 앞으로 가져옴
    SsWindow.value.focus();
  } else {
    // 'Mng'가 포함된 URL의 창이 없거나 창이 닫혀 있으면 새 창을 열음
    SsWindow.value = window.open(url, "_blank", options);
  }
};

// 새 창을 관리할 ref 변수
const adminWindow = ref();
const openAdminPage = () => {
  const screenWidth = window.screen.width; // 화면의 너비
  const screenHeight = window.screen.height; // 화면의 높이

  const width = Math.round(screenWidth * 1);
  const height = Math.round(screenHeight * 1);

  const left = (screenWidth - width) / 2; // 화면 가운데 정렬을 위한 left 값
  const top = (screenHeight - height) / 2; // 화면 가운데 정렬을 위한 top 값

  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}, fullscreen=yes`;

  const url = "#/AdminIndex";
  if (adminWindow.value && !adminWindow.value.closed) {
    adminWindow.value.focus();
  } else {
    const currentDate = new Date();
    const startDate = format(currentDate, "yyyy-MM") + "-01";
    const endDate = format(lastDayOfMonth(currentDate), "yyyy-MM-dd");
    store.commit("setAdminYY", format(currentDate, "yyyy"));
    store.commit("setAdminYYMM", format(currentDate, "yyyy-MM"));
    store.commit("setAdminStartDate", startDate);
    store.commit("setAdminEndDate", endDate);
    store.commit("setAdminDate", format(currentDate, "yyyy-MM-dd"));
    if(role <= 3){
      //기관이 관리자페이지 접속 시 여기서 기관정보 가지고 가야 함.
      //새창열릴때도 다른 페이지가 열려야 할 것.
      store.commit("setAdminSisul", "관리자페이지");
      store.commit("setAdminBizGubun", "관리자페이지");
      store.commit("setAdminService", "관리자페이지");
    }

    adminWindow.value = window.open(url, "_blank", options);
  }
};
// 새 창을 관리할 ref 변수
const mngWindow = ref();
const mngIndexPageMove = () => {
  const screenWidth = window.screen.width; // 화면의 너비
  const screenHeight = window.screen.height; // 화면의 높이

  const width = Math.round(screenWidth * 1);
  const height = Math.round(screenHeight * 1);

  const left = (screenWidth - width) / 2; // 화면 가운데 정렬을 위한 left 값
  const top = (screenHeight - height) / 2; // 화면 가운데 정렬을 위한 top 값

  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}, fullscreen=yes`;

  // 관리 페이지 URL
  const url = "#/MngIndexPage";
  // 새 창 열기 전에 로컬 스토리지에 페이지 제목 저장
  // localStorage.setItem('currentPageTitle', '관리자페이지');
  store.commit("setMngTitle", "관리자페이지");

  // 기존에 'Mng'가 포함된 URL의 창이 열려 있는지 확인
  if (mngWindow.value && !mngWindow.value.closed) {
    // 창이 열려 있고 'Mng'가 포함된 URL이라면 해당 창을 맨 앞으로 가져옴
    mngWindow.value.focus();
  } else {
    // 'Mng'가 포함된 URL의 창이 없거나 창이 닫혀 있으면 새 창을 열음
    mngWindow.value = window.open(url, "_blank", options);
  }
};
// 새 창을 관리할 ref 변수
const goToTest = () => {
  const screenWidth = window.screen.width; // 화면의 너비
  const screenHeight = window.screen.height; // 화면의 높이

  const width = Math.round(screenWidth * 1);
  const height = Math.round(screenHeight * 1);

  const left = (screenWidth - width) / 2; // 화면 가운데 정렬을 위한 left 값
  const top = (screenHeight - height) / 2; // 화면 가운데 정렬을 위한 top 값

  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}, fullscreen=yes`;

  // 관리 페이지 URL
  const url = "#/MngIndexPage";
  // 새 창 열기 전에 로컬 스토리지에 페이지 제목 저장
  // localStorage.setItem('currentPageTitle', '관리자페이지');
  store.commit("setMngTitle", "관리자페이지");
  window.open(url, "_blank", options);
};
const logRemote = () => {
  if (isLoggedIn) {
    logout();
  } else {
    LoginPage();
  }
};
const texts = ref([
  "😊 요양시설 운영지원 플랫폼 함우리입니다.",
  "🏅 대한민국 모든 요양시설을 지원합니다.",
  "✨ 경쟁력 있는 운영! 지속 가능한 미래!",
  "❤️ 요양시설의 모든 어려움을 해결해 드리겠습니다.",
]);

const currentIndex = ref(0);
const totalSlides = texts.value.length;

// function getStyle(index:any) {
//   const offset = (currentIndex.value + index) % totalSlides;
//   return {
//     transform: `translateY(${offset * 140}%)`,
//     transition: 'transform 0.5s ease-in-out',
//     color: '#fcfdff',
//     fontSize: '13px',
//     fontWeight: '400',
//   };
// }

function changeText() {
  currentIndex.value = (currentIndex.value + 1) % totalSlides;
}

// const titles = ["노무관리", "재무회계", "급식관리"];
// const links = [
//   "LaborManagement",
//   "FinancialAccounting",
//   "MealManagement",
// ];
// const selectedIndex = ref(null);

// function selectItem(index: any) {
//   selectedIndex.value = index;
//   router.push(`/${links[index]}`);
// }

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  setInterval(changeText, 3000); // 3초마다 전환
  store.getters.isLoggedIn;
  mngWindow.value = null;
});
</script>

<style scoped>
.selected-main-menu {
  background-color: #325a9e31;
}
.selected-main-service-item {
  background-color: lightgray;
}

#text-container {
  position: relative;
  height: 20px;
  overflow: hidden;
}

.text-slide {
  position: absolute;
}
</style>
