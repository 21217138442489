<template>
  <div class="rightClient">
      <!-- <v-btn class="rightClientBtn align-self-center me-4"
       variant="text"
        v-for="section in sections"
        :key="section.title"
          @click="loadComponent(section.link); setActiveItem(section)"
          :class="{ 'v-btn--active': isActiveLink(section.link) }"
        >
          {{ section.title }}
      </v-btn> -->
    </div>
  <v-card class="AdminOneClientMngRightCard">
        <v-sheet rounded="lg">
        <!-- dynamicComponent 변수를 사용하며 디폴트 값으로 LaborCost를 설정 -->
        <component :is="dynamicComponent"></component>
      </v-sheet>
  </v-card>
</template>
<script setup lang="ts">
import { onMounted, shallowRef } from 'vue';

const dynamicComponent = shallowRef('ClientMenu1');
// const activeItem = shallowRef('ClientMenu1');

// const sections = [
//   { title: '관리자', link: 'ClientMenu1' },
//   // { title: '일반', link: 'ClientMenu4' },
// ];
const loadComponent = async (componentName: string) => {
  // 비동기적으로 컴포넌트를 불러옴
  const { default: Component } = await import(`@/components/mng/one-client/${componentName}.vue`);

  // 불러온 컴포넌트를 동적으로 설정
  dynamicComponent.value = Component;
};


// const setActiveItem = (item: any) => {
//   activeItem.value = item.link;
// };

// const isActiveLink = (link: string) => {
//   return activeItem.value === link;
// };


onMounted(() => {
  loadComponent(dynamicComponent.value);
});
</script>