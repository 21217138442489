<template>
  <div class="signature-container">
    <h2>자동이체 신청 서명</h2>
      <canvas
        ref="signatureCanvas"
        width="300"
        height="200"
        @mousedown="startDrawing"
        @mousemove="draw"
        @mouseup="stopDrawing"
        @touchstart="startDrawing"
        @touchmove="draw"
        @touchend="stopDrawing"
        style="border: 1px solid black;"
      ></canvas>
      <v-btn @click="clearCanvas" color="grey">지우기</v-btn>
      <v-btn @click="saveSignature" color="blue-darken-2">저장</v-btn>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import store from "@/store";
// eslint-disable-next-line no-undef
const emit = defineEmits(["signSuccess"]);
// 상태 관리
const isDrawing = ref(false);
const lastX = ref(0);
const lastY = ref(0);
const signatureCanvas = ref(null) as any;

// 서명 시작
const startDrawing = (event: any) => {
  const canvas = signatureCanvas.value;
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX || event.touches[0].clientX;
  const y = event.clientY || event.touches[0].clientY;

  lastX.value = x - rect.left;
  lastY.value = y - rect.top;
  isDrawing.value = true;
};

// 서명 그리기
const draw = (event: any) => {
  if (!isDrawing.value) return;

  const canvas = signatureCanvas.value;
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX || event.touches[0].clientX;
  const y = event.clientY || event.touches[0].clientY;

  const ctx = canvas.getContext("2d");
  ctx.lineWidth = 3; // 펜 굵기 설정 (3px)
  ctx.lineCap = "round"; // 선 끝을 둥글게 처리 (더 부드럽게 보이도록)
  ctx.strokeStyle = "black"; // 선 색상 (기본 검정)

  ctx.beginPath();
  ctx.moveTo(lastX.value, lastY.value);
  ctx.lineTo(x - rect.left, y - rect.top);
  ctx.stroke();

  lastX.value = x - rect.left;
  lastY.value = y - rect.top;
};

// 서명 종료
const stopDrawing = () => {
  isDrawing.value = false;
};

// 서명 지우기
const clearCanvas = () => {
  const canvas = signatureCanvas.value;
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  isDrawing.value = false;
};

// 서명 저장
const saveSignature = async () => {

  const canvas = signatureCanvas.value;
  const imageData = canvas.toDataURL("image/png"); // 캔버스 내용을 PNG 이미지로 변환

  try {
    const num = store.state.RegistForm.hc_number;
    // 서버로 이미지 전송
    const response = await axios.post(`/api/registCmsSign/${num}`, {
      image: imageData,
    });
    if(response.data){
      emit('signSuccess')
    }
  } catch (error) {
    console.error('서명 저장 실패:', error);
  } finally {
    isDrawing.value = false;
  }
};
</script>

<style scoped>
.signature-container {
  text-align: center;
  margin: 20px auto;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #eee;
}
.signature-container h2{
  padding: 10px;
}
canvas {
  display: block;
  margin: 0 auto;
  background-color: #fff;
}
.signature-container .v-btn {
  margin: 10px 5px;
  border: none;
}
</style>
