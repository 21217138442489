<template>
  <v-card v-if="!loading" class="SsWorkControllerCard">
    <v-card-text class="SsWorkCardTopText">
      <h2>{{ store.state.SsYm }}-{{ numDay }} 근무내역</h2>
      <div class="SsWorkCountDiv">
        <li v-for="(count, key) in countValues" :key="key">
          {{ getLabel(key) }} : {{ count }}명
        </li>
      </div>
    </v-card-text>
    <v-card-text>
      <div class="SsWorkJobCheckDiv">
        <span v-for="job in jobOptions" :key="job.emp_job">
          <v-checkbox
            class="SsWorkScheduleJobCheckBox"
            :label="job.emp_job"
            :color="job.color"
            v-model="selectJob"
            :value="job.emp_job"
            hide-details
          />
        </span>
      </div>

      <div class="SsWorkSearchDiv">
        <v-text-field
          class="inoutSearchInput"
          style="background-color: #fff"
          width="180"
          v-model="search"
          color="#0f7545"
          label="이름/직위 검색"
          single-line
          clearable
          :append-inner-icon="mdiMagnify"
          flat
          hide-details="auto"
          variant="outlined"
          density="compact"
        />
      </div>
      <!-- empList를 v-for로 반복하여 각 항목을 카드에 표시 -->
      <div class="SsWorkVrowHover" v-for="(emp, index) in filteredItems" :key="index">
      <div style="width: 180px; padding-left:10px;">
        <b>{{ formatIndex(index + 1) }}.</b>
        <b>{{ emp.emp_name }}</b> - {{ emp.emp_job }}<br />
        <v-tooltip activator="parent" location="start"
          >사번: {{ emp.emp_num }}</v-tooltip
        >

      </div>
      <div>
        <v-radio-group
          v-model="emp[day]"
          class="label-black SsWsRadio"
          inline
          flat
          hide-details="auto"
        >
          <v-radio label="주간" value="D1" color="yellow-darken-2"></v-radio>
          <v-radio label="야간" value="N1" color="brown-darken-3"></v-radio>
          <v-radio label="비번" value="B1" color="blue-darken-4"></v-radio>
          <v-radio label="연차" value="H1" color="red-darken-4"></v-radio>
          <v-radio label="대체" value="H2" color="purple-darken-1"></v-radio>
          <v-radio label="대기" :value="null" color="gray-darken-1"></v-radio>
        </v-radio-group>
      </div>
      </div>
    </v-card-text>
    <v-btn class="btnColorBlue" @click="saveWorkSchedule()">저장</v-btn>
    <v-btn class="btnColorDark" @click="dialogClose">닫기</v-btn>
  </v-card>
  <div v-if="loading" class="spinner tac"></div>
</template>
<script setup lang="ts">
import store from "@/store";
import axios from "axios";
import { ref, onMounted, computed } from "vue";
import { mdiMagnify } from "@mdi/js";

const search = ref("");
const loading = ref(false);

// eslint-disable-next-line no-undef
const emit = defineEmits(["close", "success"]);
const dialogClose = async () => {
  emit("close");
};
let code = ref(store.state.MngIntoSs.sisul_code);
let ym = ref(store.state.SsYm) as any;
let day = ref(store.state.SsSchedule_day) as any;
let dayWithoutPrefix = store.state.SsSchedule_day.replace("d_", "");
let numDay = dayWithoutPrefix.padStart(2, "0");

const empList = ref([]) as any;
const jobOptions = ref([]) as any;
const selectJob = ref<string[]>([]);
const readWorkScheduleDate = async () => {
  try {
    loading.value = true;
    const response = await axios.get(
      `/api/readWorkScheduleDate/${code.value}/${ym.value}/${day.value}`
    );
    const responseData = response.data;
    if (responseData) {
      empList.value = responseData.result;
      jobOptions.value = responseData.jobValues;

      selectJob.value = jobOptions.value.map((item: { emp_job: any }) => item.emp_job);
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};
// 최대 자리수 계산
const maxDigits = computed(() => {
  return empList.value.length.toString().length; // 최대 자리수 계산 (예: 3 -> 001, 10 -> 010)
});

// 인덱스 포맷팅 함수
const formatIndex = (index: number) => {
  return index.toString().padStart(maxDigits.value, "0"); // 최대 자리수에 맞춰 0을 채움
};

// 각 값에 대한 라벨 매핑
const labels = {
  D1: "주간",
  N1: "야간",
  B1: "비번",
  H1: "연차",
  H2: "대체",
  null: "대기",
} as any;

// 라벨을 반환하는 함수
const getLabel = (key: string | number) => {
  return labels[key] || key; // key에 해당하는 라벨을 반환 (없으면 key 자체 반환)
};

const countValues = computed(() => {
  // 초기화된 집계 객체
  const counts = {
    D1: 0,
    N1: 0,
    B1: 0,
    H1: 0,
    H2: 0,
    null: 0, // `null` 또는 선택되지 않은 값 처리
  } as any;

  // empList의 day 값 기반으로 집계
  empList.value.forEach((emp: any) => {
    const value = emp[day.value]; // day.value에 해당하는 값

    // Object.prototype.hasOwnProperty 대신 call 사용
    if (Object.prototype.hasOwnProperty.call(counts, value)) {
      counts[value]++;
    } else {
      // 정의되지 않은 값은 null 카테고리로 처리
      counts.null++;
    }
  });
  return counts;
});

onMounted(async () => {
  await readWorkScheduleDate();
  console.log(empList.value, "empList.value");
});

const filteredItems = computed(() => {
  const hasJobFilter = selectJob.value.length > 0;
  if (!hasJobFilter) {
    return [];
  }
  return empList.value.filter((item: any) => {
    let searchTerm = null;
    if (!search.value) {
      searchTerm = search.value;
    } else {
      searchTerm = search.value.trim().toLowerCase();
    }
    // 검색어에 대해 상태와 직업을 필터링할 수 있는지 확인합니다.
    const matchesSearch =
      !searchTerm ||
      (item.emp_name && item.emp_name.toLowerCase().includes(searchTerm)) ||
      (item.emp_job && item.emp_job.toLowerCase().includes(searchTerm));

    // 직업 필터링
    const matchesJobs = !hasJobFilter || selectJob.value.includes(item.emp_job);

    return matchesSearch && matchesJobs;
  });
});
const saveWorkSchedule = async () => {
  try {
    const form = empList.value.filter((item: { [x: string]: null }) => {
      // d_로 시작하는 컬럼이 하나라도 null이 아닌 값이 있으면 해당 행을 남김
      return Object.keys(item).some((key) => key.startsWith("d_") && item[key] !== null);
    });
    if (form.length === 0) {
      return;
    }
    loading.value = true;

    const response = await axios.post(`/api/saveWorkSchedule/${day.value}/${ym.value}`, {
      form: form,
    });
    const result = response.data;
    if (result.success === true) {
      emit("success");
      alert('저장이 완료되었습니다.')
    } else {
      alert("실패하였습니다. 관리자에게 문의해주세요.");
    }
    return result;
  } catch (error) {
    alert("작업을 처리할 수 없습니다.");
    console.error(error);
  } finally {
    loading.value = false;
  }
};
</script>

