<template>
  <v-sheet class="is2">
    <div class="is2_container">
      <b class="is2_overlay_text_b">Business Scope</b>
      <p class="is2_overlay_text_p">장기요양기관 전문 맞춤 서비스</p>
      <v-row class="is2_rectangle_row is_max_width">
        <v-col cols="4" class="is2_v-col">
          <div class="is2_rectangle">
            <b>HR SVC</b>
          </div>
        </v-col>
        <v-col cols="4" class="is2_v-col">
          <div class="is2_rectangle">
            <b>FA Sys</b>
          </div>
        </v-col>
        <v-col cols="4" class="is2_v-col">
          <div class="is2_rectangle">
            <b>Meal MGT</b>
          </div>
        </v-col>
      </v-row>

      <video class="is2_video" ref="videoPlayer" autoplay loop muted>
        <source src="@/assets/pictures/main/green_lines.mp4" type="video/mp4" />
        브라우저가 동영상을 지원하지 않습니다.
      </video>

        <p class="is2_last_ment" style="color: #fff">최고의 전문성으로 추가비용 없이 기대를 넘어선 가치를 경험하세요.</p>
      
      <div class="is2_gradation"></div>
      <!-- 상단 그라데이션 추가 -->
    </div>
  </v-sheet>
</template>
