<template>
  <v-card class="blogWriteCard" elevation="0">
    <v-select
      v-model="form.cate"
      variant="outlined"
      label="카테고리"
      :items="cateValue"
      color="indigo"
      autocomplete="off"
      hide-details="auto"
      flat
      density="compact"
      style="width: auto; min-width: 70px; max-width: 150px"
    ></v-select>
    <v-text-field
      class="inoutSearchInput"
      style="width: 100%; background-color: #fff; margin: 15px 0 15px 0"
      v-model="form.title"
      color="#0f7545"
      label="제목을 입력해주세요."
      clearable
      flat
      hide-details="auto"
      variant="outlined"
      density="compact"
      maxLength="45"
    >
    </v-text-field>
    <v-text-field
      class="inoutSearchInput"
      style="width: 100%; background-color: #fff; margin: 0 0 15px 0"
      v-model="form.sub"
      color="#0f7545"
      label="부제목을 입력해주세요."
      clearable
      flat
      hide-details="auto"
      variant="outlined"
      density="compact"
      maxLength="60"
    >
    </v-text-field>

    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
      @ready="onEditorReady"
    ></ckeditor>

    <v-file-input
      v-model="file"
      density="compact"
      label="첨부파일 업로드"
      hide-details="auto"
      max-width="400"
      variant="underlined"
      style="margin: 15px 0; padding: 0 10px;"
    />

    <div v-if="!loading">
        <v-file-input
          accept="image/*"
          @change="handleFileUpload"
          label="썸네일 업로드"
          variant="underlined"
          density="compact"
          hide-details="auto"
          clearable
          max-width="400"
          style="
            margin: 15px 0;
            padding: 0 10px;
          "
          :prepend-icon="mdiImageAlbum"
        />
      <v-card class="ml-11 gridStyleBlog" max-width="400" height="280" elevation="0" v-if="imageMiribogi || imageUrl">

        <div  class="blogImgBox" style="background-color: #d1d1d1;">
          <v-img
            height="200"
            :src="imageMiribogi || imageUrl"
            alt="썸네일미리보기"
            cover
          ></v-img>
        </div>

      </v-card>
    </div>
    <div v-if="loading" class="spinner tac"></div>
    <v-btn
      @click="$router.push('/BlogPage')"
      class="btnColorDark"
      style="float: right; margin-top: 10px"
    >
      취소
    </v-btn>

    <v-btn
      @click="saveBlog('real')"
      class="btnColorGreen"
      style="float: right; margin-top: 10px; margin-right: 3px"
    >
      저장
    </v-btn>
    <v-btn
      @click="saveBlog('no-real')"
      class="btnColorWhite"
      style="float: right; margin-top: 10px; margin-right: 3px"
    >
      임시저장
    </v-btn>
  </v-card>
</template>

<script setup lang="ts">
import axios from "axios";
import CKEditor from "@/plugins/ckeditor/build/ckeditor.js";
import { reactive, ref } from "vue";
import store from "@/store";
import router from "@/router";
import {
  mdiImageAlbum 
} from '@mdi/js'
const editor = CKEditor;
const cateValue = ["노무관리", "재무회계", "위탁급식", "기타"];

const form = reactive({
  cate: null,
  title: "",
  sub: "",
  content: "",
  thumbnail: "",
  fileName: "",
  editor: store.getters.id,
  status: 0,
  ext: '',
});
const file = ref();
const duplicateFileName = ref([] as any[]);

let editorData = "";
const editorConfig = {
  language: "ko",
};

const saveBlogFile = async () => {
  if (!file.value || file.value === '' || file.value === null) {
    file.value = null;
    return;
  }
  let files = file.value;
  const formData = new FormData();
  const fileExtension = files.name.split(".").pop(); // 확장자 추출
  const fileNameWithoutExtension = files.name.split(".").slice(0, -1).join("."); // 확장자 제외 나머지

  // if (fileExtension !== 'xlsx') {
  //   alert('엑셀 파일만 업로드해주세요.')
  // }
  const allowedExtensions = [
    "xlsx",
    "pdf",
    "hwp",
    "docx",
    "pptx",
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "zip",
  ];
  if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
    alert("허용되지 않는 파일 형식입니다.");
  }
  form.fileName = fileNameWithoutExtension + "." + fileExtension;

  await readBlogDupFileName(form.fileName);
  let isDuplicate = duplicateFileName.value.some(
    (item) => item.fileName === form.fileName
  );
  // 중복된 파일 이름이 있다면 숫자를 늘려서 새로운 파일 이름 생성

  if (isDuplicate) {
    let count = 1;
    let baseFileName = form.fileName.split(".").slice(0, -1).join("."); // 확장자를 제거한 파일 이름
    while (isDuplicate) {
      form.fileName = `${count}hC25Jes_${baseFileName}.${fileExtension}`;
      isDuplicate = duplicateFileName.value.some(
        (item) => item.fileName === form.fileName
      );
      count++;
    }
  }

  let newFile = new File([files], form.fileName); // 새로운 파일 생성
  formData.append("file", newFile);
  try {
    await axios.post(`/api/saveBlogFile/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("파일 업로드 오류:", error);
  }
};

// 저장 버튼 클릭 시 호출되는 메서드
const saveBlog = async (suc: any) => {
  try {
    await saveBlogFile();
    await sendThumbnail();
    form.ext = ext.value;
    if(suc === 'real'){
      form.status = 2;//저장
    }else{
      form.status = 1;//임시저장
    }
    const response = await axios.post(`/api/saveBlogContent`, {
      content: editorData,
      form: form,
    });
    const result = response.data;
    if (result.success) {
      alert("게시글이 정상 등록되었습니다.");
      router.push("/BlogPage");
    } else {
      alert("게시글 등록에 실패했습니다.");
    }
  } catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};

const readBlogDupFileName = async (fileName: string) => {
  try {
    const response = await axios.get(`/api/readBlogDupFileName/${fileName}`);
    const result = response.data;
    duplicateFileName.value = result;
    return result;
  } catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};

// CKEditor의 ready 이벤트를 처리할 함수
const onEditorReady = (editorInstance: any) => {
  editorInstance.ui
    .getEditableElement()
    .parentElement.insertBefore(
      editorInstance.ui.view.toolbar.element,
      editorInstance.ui.getEditableElement()
    );
};

const loading = ref(false);
const imageMiribogi = ref();
const imageFile = ref();
const ext = ref();
const imageUrl = ref();
const imgName = ref();

const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    imgName.value = file.name;
    ext.value = file.name.split(".").pop();
    const reader = new FileReader();
    reader.onload = () => {
      imageMiribogi.value = reader.result;
    };
    reader.readAsDataURL(file);
    imageFile.value = file;
  }
};

const sendThumbnail = async () => {
  if (!imageFile.value) {
    return;
  }
  const allowedTypes = ["image/jpeg", "image/png"];
  if (!allowedTypes.includes(imageFile.value.type)) {
    alert("JPG 또는 PNG 파일만 업로드 가능합니다.");
    loading.value = false;
    return;
  }
  loading.value = true;

  try {
    // if (imageUrl.value !== "nothing") {
    //   await deleteThumbnail();
    // }

    const formData = new FormData();
    formData.append("image", imageFile.value);
    const whatKindOfSend = 'first-send';
    const response = await axios.post(
      `/api/sendThumbnail/${imgName.value}/${ext.value}/${whatKindOfSend}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response && response.data.success) {
      // await fetchImage();
    } else {
      console.error("이미지 업로드 실패", response.data);
    }
  } catch (error) {
    console.error("이미지 업로드 중 에러 발생", error);
  } finally {
    loading.value = false;
  }
};

</script>
<style>
/* .ck-heading-dropdown{
  display: none !important;
} */
</style>
