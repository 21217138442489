<template>
  <v-sheet v-if="store.state.SsECDEmpInfo !== 'notSelectStatus'" class="empDetails fontNotoSans400">
        <v-table>
          <thead>
            <tr>
              <th colspan="2"><h2>기초정보</h2></th>
            </tr>
          </thead>
          <tbody>         
            <tr>
              <td>사원번호</td>
              <td>
                <v-text-field 
                  type="text"
                  v-model="form.emp_num"
                  hide-details="auto"
                  color="indigo"
                  variant="plain" 
                  placeholder="사원등록이 완료되면 자동생성됩니다."
                  readonly
                />
              </td>
            </tr>
            <tr>
              <td>이름</td>
              <td>
                <v-text-field 
                  type="text"
                  v-model="form.emp_name"
                  hide-details="auto"
                  maxlength="20" 
                  color="indigo"
                  variant="plain" 
                  placeholder="이름을 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>직위</td>
              <td>
                <v-select
                  v-model="form.emp_job"
                  :items="jobValue"
                  hide-details="auto"
                  color="indigo"
                  variant="plain" 
                  placeholder="- 선택 -"
                />
              </td>
            </tr>
            <tr @click="bossbirthstatus = true">
              <td>주민번호</td>
              <td>
                <v-text-field 
                  type="text"
                  v-model="form.emp_birth_num"
                  @focus="clearFormatHP(form, 'emp_birth_num')"
                  @blur="applyFormatHP(form, 'emp_birth_num')"
                  hide-details="auto"
                  maxlength="13" 
                  color="indigo"
                  variant="plain" 
                  placeholder="숫자만 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>휴대폰번호</td>
              <td>
                <v-text-field 
                  type="text"
                  v-model="form.emp_phone"
                  @focus="clearFormatHP(form, 'emp_phone')"
                  @blur="applyFormatHP(form, 'emp_phone')"
                  hide-details="auto"
                  maxlength="11" 
                  color="indigo"
                  variant="plain" 
                  placeholder="숫자만 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>이메일</td>
              <td>
                <v-text-field 
                  type="email"
                  v-model="form.emp_email"
                  hide-details="auto"
                  maxlength="50" 
                  color="indigo"
                  variant="plain" 
                  placeholder="이메일을 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>주소</td>
              <td>
                <v-text-field 
                type="text"
                v-model="form.emp_addr"
                @click="findAddress"
                readonly
                hide-details="auto"
                maxlength="150" 
                color="indigo"
                variant="plain" 
                placeholder="주소를 입력해주세요."
                autocomplete="off"
                />
              </td>
            </tr>
            <tr>
              <td>은행명</td>
              <td>
                <v-select
                  v-model="form.emp_bank"
                  :items="bankValue"
                  hide-details="auto"
                  color="indigo"
                  variant="plain" 
                  placeholder="- 선택 -"
                />
              </td>
            </tr>
            <tr>
              <td>계좌번호</td>
              <td>
                <v-text-field 
                  type="text"
                  v-model="form.emp_bank_num"
                  hide-details="auto"
                  maxlength="30" 
                  color="indigo"
                  variant="plain" 
                  placeholder="숫자만 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>예금주</td>
              <td>
                <v-text-field 
                  type="text"
                  v-model="form.emp_bank_owner"
                  hide-details="auto"
                  maxlength="20" 
                  color="indigo"
                  variant="plain" 
                  placeholder="예금주를 입력해주세요."
                />
              </td>
            </tr>
            <tr>
              <td>입사일</td>
              <td class="pl-6">
                <input type="date" color="indigo" v-model="form.emp_in_date" min="2000-01-01" max="2099-12-31" />
              </td>
            </tr>
            <tr @click="bossbirthstatus = true">
              <td>대표자 여부</td>
              <td>
                <v-radio-group v-model="form.emp_isBoss" inline hide-details="auto">
                  <v-radio label="예" :value=1></v-radio>
                  <v-radio label="아니오" :value=0></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>사회보험적용</td>
              <td>
                <input type="checkbox" v-model="form.emp_insu" :value=1> 국민연금
                <input type="checkbox" v-model="form.emp_insu" :value=2> 건강보험
                <input type="checkbox" v-model="form.emp_insu" :value=3> 고용보험 
                <input type="checkbox" v-model="form.emp_insu" :value=4> 산재보험 
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-table style="margin-top: 35px;">
          <thead>
            <tr>
              <th colspan="2"><h2>퇴사 및 삭제</h2>
                <span style="font-size: 13px;">입력한 퇴사일에 맞춰 처리됩니다.</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>퇴사일</td>
              <td>
                <input type="date" color="indigo" v-model="form.emp_out_date" min="2000-01-01" max="2099-12-31" />
              </td>
            </tr> 
            <tr>
              <td colspan="2" class="tar">
              <EmpDelete :item="form" @success="doubleEmit()" />
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-btn @click="setEmpDetail" color="#333" style="font-size: 17px; width: 100%; min-height: 50px;">사원정보저장</v-btn>
  </v-sheet>
  <v-sheet v-else style="display: flex; align-items: center; justify-content: center; height: 200px;"> 
    <h3>사원을 선택해주세요.</h3>
  </v-sheet>
</template> 
<script setup lang="ts">
import store from '../../../../store';
import axios from 'axios';
import EmpDelete from '../../../../components/admin/common/emp/EmpDelete.vue';
import { ref, reactive, onMounted, computed, watch } from 'vue';
// eslint-disable-next-line no-undef
const emit = defineEmits(['success'])
const doubleEmit = () => {
  emit('success');
}
const jobValue= [
'시설장',
'요양보호사',
'사회복지사',
'간호사',
'간호조무사',
'물리치료사',
'작업치료사',
'사무장',
'사무원',
'영양사',
'조리사',
'조리원',
'위생원',
'운전사',
'기타',
]
const bankValue= [
'기업은행',
'국민은행',
'외환은행',
'수협은행',
'농협은행',
'지역농협',
'우리은행',
'SC제일은행',
'서울은행',
'한국씨티은행',
'대구은행',
'부산은행',
'광주은행',
'제주은행',
'전북은행',
'경남은행',
'새마을금고연합회',
'신협중앙회',
'하나은행',
'신한은행',
'케이뱅크',
'카카오뱅크',
'토스뱅크'
]
const form = reactive({
  sisul_code : store.state.AdminSisulCode,
  emp_status: 0,
  emp_num: '',
  emp_name : '',
  emp_job : null,
  emp_birth_num : '',
  emp_phone : '',
  emp_email : '',
  emp_addr : '',
  emp_bank  : null,
  emp_bank_num  : '',
  emp_bank_owner  : '',
  emp_in_date : '',
  emp_out_date : '',
  emp_biz_gubun  : '',
  emp_isBoss : 0,
  emp_insu : [1,2,3,4],
  ec_pay_type : '',
  ec_pay_month: 0,
  ec_pay_time_type : '최저시급',
  ec_pay_time : 0,
  ec_day : 0,
  ec_night : 0,
  work_period : '',
  work_shift : null,
});





function unformatNumber(value: any) {
  // 컴마를 제거
  return value?.toString().replace(/,/g, '');
}

function addComma(number: any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return 0; 
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}

// 입력값의 길이에 따라 하이픈을 자동으로 추가
function clearFormatHP(item: any, key: any) {
  // 인풋의 값을 하이푼 없이 만듭니다.
  item[key] = unformatNumberHP(item[key]);

  // 값이 0이면 빈 값으로 설정하여 placeholder를 보이도록 합니다.
  if (item[key] === '0' || item[key] === 0) {
    item[key] = '';
  }
}

function applyFormatHP(item: any, key: any) {
  // 값이 비어있으면 ''으로 설정
  if (item[key] === '' || item[key] === null || item[key] === undefined) {
    item[key] = '';
  } else {
    // 포커스가 해제되면 하이푼 추가00
    if(key === 'emp_birth_num'){
      item[key] = formatEmpNumHP(item[key]);
    }else if(key === 'emp_phone'){
      item[key] = formatPhoneHP(item[key]);
    }
  }
}

function formatEmpNumHP(value: any){
  // 숫자인지 확인하고 하이푼 추가
  const number = parseFloat(value);
  if (!isNaN(number)) {
    return  `${value.slice(0, 6)}-${value.slice(6)}`
  }
  return value;
}
function formatPhoneHP(value: any){
  // 숫자인지 확인하고 하이푼 추가
  const number = parseFloat(value);
  if (!isNaN(number)) {
    return  `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`
  }
  return value;
}

function unformatNumberHP(value: any) {
  return value?.toString().replace(/-/g, '');
}
    
const bossbirthstatus = ref(false);
const WPTInfo = ref([] as any);
const changeSsECDEmpInfo = computed(() => {
  const SsECDEmpInfoComputed = store.state.SsECDEmpInfo
  return SsECDEmpInfoComputed;
});
watch(changeSsECDEmpInfo, async (newValue: any) => {
  if (newValue) {
    bossbirthstatus.value = false;
    await getEmpContract();
  }
});
const getEmpContract = async () => {
  try {
    const empNum = store.state.SsECDEmpInfo.emp_num;
    if(!empNum){
      form.emp_status = 1;
      form.emp_num = '';
      form.emp_name = '';
      form.emp_job = null;
      form.emp_birth_num = '';
      form.emp_phone = '';
      form.emp_email ='';
      form.emp_addr = '';
      form.emp_bank  = null;
      form.emp_bank_num  = '';
      form.emp_bank_owner  = '';
      form.emp_in_date = '';
      form.emp_out_date = '';
      form.emp_biz_gubun  = '';
      form.emp_isBoss =0;
      form.emp_insu = [1,2,3,4];
      form.ec_pay_type = '약정';
      form.ec_pay_time_type = '최저시급';
      form.ec_pay_month = 0;
      form.ec_pay_time =  0;
      form.work_period = '';
      form.work_shift = null;
      form.ec_day = 0;
      form.ec_night = 0;
      return;
    }
    const response = await axios.get(`/api/getEmpContract/${form.sisul_code}/${empNum}`);
    const responseData = response.data;
    if (responseData.length !== 0) {
      WPTInfo.value = responseData[0];
      form.sisul_code = WPTInfo.value.sisul_code;
      form.emp_status = WPTInfo.value.emp_status;
      form.emp_num = WPTInfo.value.emp_num;
      form.emp_name = WPTInfo.value.emp_name;
      form.emp_job = WPTInfo.value.emp_job;
      form.emp_birth_num = formatEmpNumHP(WPTInfo.value.emp_birth_num);
      form.emp_phone = formatPhoneHP(WPTInfo.value.emp_phone);
      form.emp_email = WPTInfo.value.emp_email;
      form.emp_addr = WPTInfo.value.emp_addr;
      form.emp_bank  = WPTInfo.value.emp_bank;
      form.emp_bank_num  = WPTInfo.value.emp_bank_num;
      form.emp_bank_owner  = WPTInfo.value.emp_bank_owner;
      form.emp_in_date = WPTInfo.value.emp_in_date;
      form.emp_out_date = WPTInfo.value.emp_out_date;
      form.emp_biz_gubun  = WPTInfo.value.emp_biz_gubun;
      form.emp_isBoss = WPTInfo.value.emp_isBoss;
      form.emp_insu = JSON.parse(WPTInfo.value.emp_insu);
      form.ec_pay_type = WPTInfo.value.ec_pay_type;
      form.ec_pay_month = addComma(WPTInfo.value.ec_pay_month);
      form.ec_pay_time_type = WPTInfo.value.ec_pay_time_type;
      form.ec_pay_time = addComma(WPTInfo.value.ec_pay_time);
      form.work_period = WPTInfo.value.work_period;
      form.work_shift = WPTInfo.value.work_shift;
      
      if(form.work_shift){
        if(form.work_shift === '주간근무'){
          form.ec_day = 1;
          form.ec_night = 0;
        }else if(form.work_shift === '야간근무'){
          form.ec_day = 0;
          form.ec_night = 1;
        }else if(form.work_shift === null){
          form.ec_day = 0;
          form.ec_night = 0;
        }else{
          form.ec_day = 1;
          form.ec_night = 1;
        }
      }
      
      form.ec_day = WPTInfo.value.ec_day;
      form.ec_night = WPTInfo.value.ec_night;
      return responseData;
    } else {
      store.commit('setSsECDEmpInfo', 'notSelectStatus');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const setEmpDetail = async () => {
  if(!form.emp_name || !form.emp_job || !form.emp_in_date){
    alert('이름, 직위, 입사일은 필수입니다.')
    return;
  }
  try {
    form.ec_pay_time = unformatNumber(form.ec_pay_time) || 0;
    form.ec_pay_month = unformatNumber(form.ec_pay_month) || 0;
    form.emp_birth_num = unformatNumberHP(form.emp_birth_num) || null;
    form.emp_phone = unformatNumberHP(form.emp_phone) || null;

    const empNum = store.state.SsECDEmpInfo.emp_num || 'notValueEmpNum';
    const response = await axios.post(`/api/setEmpDetail/${empNum}`, { form: form });
    const result = response.data;
    if (result.success === true) {
      await emit('success');
      await getEmpContract();
      alert('정상 처리되었습니다.');
    } else {
      alert('실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('처리할 수 없습니다.');
    console.error(error);
  }
};

const birthNumComputed = computed(() => {
  const birthNum = form.emp_birth_num;
  if (!birthNum || birthNum.length < 6) {
    // 입력이 유효하지 않거나 너무 짧은 경우
    return null;
  }

  // 출생 연도와 월일을 가져옵니다.
  const yearPrefix = birthNum.substring(0, 2); // 앞 두 자리
  const month = parseInt(birthNum.substring(2, 4), 10); // 3번째와 4번째 자리
  const day = parseInt(birthNum.substring(4, 6), 10); // 5번째와 6번째 자리

  // 현재 연도, 월, 일을 가져옵니다.
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1
  const currentDay = today.getDate();

  // 출생 연도 계산
  let birthYear = parseInt(yearPrefix, 10);
  if (birthYear > currentYear % 100) {
    // 1900년대 출생 (예: 95는 1995년)
    birthYear += 1900;
  } else {
    // 2000년대 출생 (예: 00은 2000년)
    birthYear += 2000;
  }

  // 만나이 계산
  let age = currentYear - birthYear;
  // 생일이 지났는지 확인하여 나이 조정
  if (month > currentMonth || (month === currentMonth && day > currentDay)) {
    age -= 1; // 생일이 지나지 않았으면 나이에서 1을 뺍니다.
  }
  return age;
});

watch(birthNumComputed, async (nv: any) => {
  if(bossbirthstatus.value === true){
    if (nv >= 60) {
      form.emp_insu = form.emp_insu.filter(value => value !== 1);
      if (nv >= 65) {
        form.emp_insu = form.emp_insu.filter(value => value !== 3);
      }
    }else{
      if (!form.emp_insu.includes(1)) {
        form.emp_insu.push(1);
      }
      if (!form.emp_insu.includes(3)) {
        form.emp_insu.push(3);
      }
    }
  }else{
    return;
  }

});
const computedIsBoss = computed(() => {
  const cib = form.emp_isBoss;
  return cib;
});
watch(computedIsBoss, async (nv: any) => {
  if(bossbirthstatus.value === true){
    if (nv === 1) {
      form.emp_insu = form.emp_insu.filter(value => value !== 3 && value !== 4);
    } else if (nv === 0) {
      // Add values 3 and 4 if not present
      if (!form.emp_insu.includes(3)) {
        if(birthNumComputed.value && birthNumComputed.value < 65){
          form.emp_insu.push(3);
        }
      }
      if (!form.emp_insu.includes(4)) {
        form.emp_insu.push(4);
      }
    }
  }else{
    return;
  }
});

// 다음주소
const findAddress = () => {
  // eslint-disable-next-line no-undef
  const Postcode = (window as any).daum?.Postcode;
  new Postcode({
    oncomplete: (data: any) => {
      form.emp_addr = data.address;
    },
  }).open();
};
onMounted (() => {
  getEmpContract();
}) 
</script>