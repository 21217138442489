<template>
<v-card class="clientLeftCardHeight">
       
       <v-card-title class="tac pt-6 pb-2">
        <img @click="dialog = true" style="width: auto; height: 150px; cursor:pointer;" :src="imageUrl" alt="Promotional Image" />
         <p class="fontNotoSans700 mt-1" style="font-size: 17px;">
           {{ SsInfo.sisul_name }}
         </p>
       </v-card-title>
       <v-card-text class="mt-1 mb-0 pb-0 d-flex justify-center flex-wrap ">
       <SsSignImg/>
         <div
           v-for="(service, index) in sortedServices"
           :key="index"
         >
           <v-chip style="margin: 0 1px;" :color="service.color">
             <h4>{{ service.type }}</h4>
           </v-chip >
         </div>
       </v-card-text>
       <v-card-text>
         <v-divider class="mb-2"></v-divider>
         <v-list class="mb-2">
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>장기요양기관기호: </b><span>{{ SsInfo.sisul_code }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>사업자등록번호: </b><span>{{ SsInfo.sisul_biz_num }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>기관 주소: </b><span>{{ SsInfo.sisul_addr }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>기관 전화번호: </b><span>{{ SsInfo.sisul_tell }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>은행: </b><span>{{ SsInfo.sisul_bank }} / {{ SsInfo.sisul_bank_num }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>예금주: </b><span>{{ SsInfo.sisul_bank_owner }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>대표자 성명: </b><span>{{ SsInfo.sisul_boss_name }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>대표자 번호: </b><span>{{ SsInfo.sisul_boss_phone }}</span>
           </v-list-item>
           <v-list-item class="pl-0 pr-0 pt-1 pb-1" style="min-height: 20px;">
             <b>대표자 이메일: </b><span>{{ SsInfo.sisul_boss_email }}</span>
           </v-list-item>
         </v-list>
         <SsBasicEdit @success="readSsId()"/>
       </v-card-text>

       <v-dialog v-model="dialog" style="width: 700px;" class="fontNotoSans400">
        <v-card>
          <div style="background-color: #333; color: #fff; padding: 10px 20px;">
            <h3>이미지 변경 시 기존 이미지는 삭제됩니다.</h3>
          </div>
          <v-card-text>
            <div>
              <input type="file" accept="image/*" @change="handleFileUpload"
                style="width: 100%; margin-bottom: 10px; border-bottom: 2px solid #333;">
            </div>
            <div>
              <img :src="imageMiribogi || imageUrl" alt=" 이미지 확인 후 업로드버튼을 눌러주세요."
                style="max-width: 100%; max-height: 100%; margin-bottom: 5px;">
            </div>
          </v-card-text>
          <v-btn style="border-radius: 0 !important;" @click="sendSisulMainImg" class="btnColorBlue">업로드</v-btn>
          <v-btn style="border-radius: 0 !important;" @click="dialog = false" class="btnColorDark">닫기</v-btn>
        </v-card>
      </v-dialog>


     </v-card>


</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { ref , onMounted, computed, onUnmounted} from 'vue';
import {
} from '@mdi/js'
import SsSignImg from '@/components/sisul_comp/SsSignImg.vue'
import SsBasicEdit from '@/components/sisul_comp/SsBasicEdit.vue'
let id = ref('') as any;

// const roleName = store.state.roleName;
// const name = store.state.name;
const SsInfo = ref([] as any);
type Service = {
  type: string;
  in: string;
  out: string | null;
  color: string;
};
const services = ref<Service[]>([]);

const sortedServices = computed(() => {
  return services.value
    .filter(service => service.in && !service.out)
    .sort((a, b) => new Date(a.in).getTime() - new Date(b.in).getTime());
});



const dialog = ref(false);
const loading = ref(false);
const imageMiribogi = ref();
const imageFile = ref();
const ext = ref();
const imageType = ref();
const imageUrl = ref();
const imgName = ref();
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    ext.value = file.name.split('.').pop();
    const reader = new FileReader();
    reader.onload = () => {
      imageMiribogi.value = reader.result;
    };
    reader.readAsDataURL(file);
    imageFile.value = file;
  }
};
const fetchImage = async () => {
  imageUrl.value = await getSisulMainImg();
  console.log(imageUrl.value,'imageUrl.value')
};
const getSisulMainImg = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`/api/getSisulMainImg/${imgName.value}`, { responseType: 'blob' });
    if(response.data){
      //이미지타입 추출
      imageType.value = response.data.type.split('/')[1];
      if(imageType.value === 'jpeg'){
        imageType.value = 'jpg';
      }
      const imageBlob = response.data;
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL; // 가져온 이미지 URL을 반환
    }
  } catch (error) {
    const imageObjectURL = 'nothing'
    return imageObjectURL;
  } finally {
    loading.value = false;
  }
};

const sendSisulMainImg = async () => {

if (!imageFile.value) {
  alert('이미지를 선택하세요.');
  return;
}
const allowedTypes = ['image/jpeg', 'image/png'];
if (!allowedTypes.includes(imageFile.value.type)) {
  alert('JPG 또는 PNG 파일만 업로드 가능합니다.');
  loading.value = false;
  return;
}
loading.value = true;

try {
  if (imageUrl.value !== 'nothing') {
    await deleteSilsulMainImg();
  }
  const formData = new FormData();
  formData.append('image', imageFile.value);
  const response = await axios.post(`/api/sendSisulMainImg/${imgName.value}/${ext.value}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  if (response && response.data.success) {
    await fetchImage();
    dialog.value = false;
  } else {
    console.error('이미지 업로드 실패', response.data);
  }
} catch (error) {
  console.error('이미지 업로드 중 에러 발생', error);
} finally {
  loading.value = false;
}
};
const deleteSilsulMainImg = async () => {
try {
  const response = await axios.post(`/api/deleteSilsulMainImg/${imgName.value}`);
  if (!response) {
    alert('기존 파일 삭제 에러 발생.')
    return;
  }
} catch (error) {
  console.error('이미지 업로드 중 에러 발생', error);
}
};




const readSsId = async () => {
  try {
    const response = await axios.get(`/api/readSsId/${id.value}`);
    const responseData = response.data;
    if (responseData) {
      SsInfo.value = responseData;
      services.value = [
        { type: '노무관리', in: SsInfo.value.sisul_wage_in, out: SsInfo.value.sisul_wage_out, color: 'blue-darken-2' },
        { type: '재무회계', in: SsInfo.value.sisul_fna_in, out: SsInfo.value.sisul_fna_out, color: 'purple-darken-2' },
        { type: '위탁급식', in: SsInfo.value.sisul_meal_in, out: SsInfo.value.sisul_meal_out, color: 'orange-darken-2' },
      ];
      imgName.value = SsInfo.value.sisul_code + 'img';

      store.commit('setSsInfoBox', SsInfo.value);
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
onMounted(async() => {
  if(store.state.MngIntoSs && store.state.MngIntoSs.length !== 0) {
    id.value = store.state.MngIntoSs.sisul_id;
  }else{
    id.value = store.state.id;
  }
 await readSsId();
  await fetchImage();
});
onUnmounted(() => {
  sessionStorage.removeItem('SsInfoBox');
})
</script>