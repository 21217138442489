<template>
  <template v-if="loading">
    <AdminLoadingAction />
  </template>
  <template v-else>
    <v-toolbar flat class="toolbarHeightHalf">
      <v-toolbar-title
        class="fontNotoSans700"
        v-if="!editingMode"
        style="max-width: 160px"
        >임금정보</v-toolbar-title
      >
      <v-toolbar-title class="fontNotoSans700" v-if="editingMode" style="max-width: 160px"
        >임금정보 등록</v-toolbar-title
      >

      <h4><FormatMonth /></h4>
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-btn color="primary" @click="editingMode = !editingMode">토글</v-btn>

      <v-toolbar-title class="fontNotoSans700"> </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <WageLedgerPayDate @success="empWageReal()" />
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-text-field
        class="inoutSearchInput"
        style="
          background-color: #fff;
          max-width: 200px;
          width: auto;
          min-width: 200px;
          margin: 0 20px 0 0;
        "
        v-model="search"
        color="#0f7545"
        label="이름/직위 검색"
        single-line
        clearable
        :append-inner-icon="mdiMagnify"
        flat
        hide-details="auto"
        variant="outlined"
        density="compact"
      >
      </v-text-field>
    </v-toolbar>

    <v-data-table
      v-if="!editingMode"
      no-data-text="데이터가 존재하지 않습니다."
      fixed-header
      density="compact"
      scrollable
      :headers="headerCateList"
      :items="filteredItems"
      :search="search"
      item-value="name"
      class="nonTbFooter empWageRealTb"
      :items-per-page="-1"
      :items-per-page-options="[
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
      ]"
    >
      <template v-slot:item="{ item, index }">
        <tr class="empWageRealTbTr" @click="readyToCalcTr(item)">
          <td class="stopedTd" @click="editingMode = true">{{ index + 1 || 0 }}</td>
          <td class="stopedTd" @click="editingMode = true">{{ item.empNum || "" }}</td>
          <td class="stopedTd" @click="editingMode = true">{{ item.empName || "" }}</td>
          <td class="stopedTd" @click="editingMode = true">{{ item.empJob || "" }}</td>
          <td>{{ item.monthPay || 0 }}</td>
          <td>{{ item.oneDayWorkTime || 0 }}</td>
          <td>{{ item.oneDayDutyPay || 0 }}</td>
          <td>{{ item.timePay || 0 }}</td>
          <td>{{ item.totalDays || 0 }}</td>
          <td>{{ item.totalDutyDays || 0 }}</td>
          <td>{{ item.base || 0 }}</td>
          <td>{{ item.overtime || 0 }}</td>
          <td>{{ item.night || 0 }}</td>
          <td>{{ item.holiday || 0 }}</td>
          <td>{{ item.dayOffPay || 0 }}</td>
          <td>{{ item.longTerm || 0 }}</td>
          <td>{{ item.bonus || 0 }}</td>
          <td>{{ item.eduPay || 0 }}</td>
          <td>{{ item.bojon || 0 }}</td>
          <td>{{ item.etc || 0 }}</td>
          <td>{{ item.lunch || 0 }}</td>
          <td>{{ item.car || 0 }}</td>
          <td>{{ item.childcare || 0 }}</td>
          <td style="font-weight: 700">{{ item.total || 0 }}</td>
          <td>{{ item.taxable || 0 }}</td>
          <td>{{ item.usual || 0 }}</td>
          <td>{{ item.minimum || 0 }}</td>
          <td>{{ item.l_yeon || 0 }}</td>
          <td>{{ item.l_gun || 0 }}</td>
          <td>{{ item.l_jang || 0 }}</td>
          <td>{{ item.l_go || 0 }}</td>
          <td>{{ item.l_so || 0 }}</td>
          <td>{{ item.l_ji || 0 }}</td>
          <td>{{ item.l_jiwon || 0 }}</td>
          <td>{{ item.l_sik || 0 }}</td>
          <td>{{ item.l_etc || 0 }}</td>
          <td>{{ item.l_yearEnd || 0 }}</td>
          <td style="font-weight: 700">{{ item.l_total || 0 }}</td>
          <td>{{ item.c_yeon || 0 }}</td>
          <td>{{ item.c_gun || 0 }}</td>
          <td>{{ item.c_jang || 0 }}</td>
          <td>{{ item.c_go || 0 }}</td>
          <td>{{ item.c_sil || 0 }}</td>
          <td>{{ item.c_san || 0 }}</td>
          <td>{{ item.c_out || 0 }}</td>
          <td style="font-weight: 700">{{ item.c_total || 0 }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-row v-if="editingMode" style="padding: 0; margin: 0">
      <div style="width: 316px">
        <v-data-table
          no-data-text="데이터가 존재하지 않습니다."
          fixed-header
          density="compact"
          scrollable
          :headers="editHeader"
          :items="filteredItems"
          :search="search"
          item-value="name"
          class="nonTbFooter empWageEditRealTb"
          :items-per-page="-1"
          :items-per-page-options="[
            { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
          ]"
        >
          <template v-slot:item="{ item, index }">
            <tr
              class="empWageEditRealTbTr"
              @click="readyToCalcTr(item)"
              :class="[
                'empWageEditRealTbTr_selected',
                { selected: selectedRow === item.empNum },
              ]"
            >
              <td class="stopedTd">{{ index + 1 || 0 }}</td>
              <td class="stopedTd">{{ item.empNum || "" }}</td>
              <td class="stopedTd">{{ item.empName || "" }}</td>
              <td class="stopedTd">{{ item.empJob || "" }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <v-col style="padding: 0; margin: 0">
        <div>{{ form }}</div>
      </v-col>
    </v-row>
  </template>
</template>
<script setup lang="ts">
import AdminLoadingAction from "@/components/admin/layout/AdminLoadingAction.vue";

import WageLedgerPayDate from "@/components/admin/services/wage/ledger/WageLedgerPayDate.vue";

import FormatMonth from "@/components/admin/common/datePicker/FormatMonth.vue";

import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import axios from "axios";
import store from "@/store";
import { mdiMagnify } from "@mdi/js";

const wageList = ref([] as any[]);
const loading = ref(false);
const code = ref(store.state.AdminSisulCode) as any;
// const sisulName = ref(store.state.AdminSisulName) as any;
const tax = ref([] as any);
const soji = ref([] as any);
const editingMode = ref(false);
const search = ref("");
const date = ref(store.state.AdminYYMM);
const editHeader = ref([
  {
    title: "사원정보",
    align: "center",
    children: [
      { title: "순번", key: "index", align: "center", sortable: false },
      { title: "사번", key: "empNum", align: "center", sortable: false },
      { title: "이름", key: "empName", align: "center", sortable: false },
      { title: "직종", key: "empJob", align: "center", sortable: false },
    ],
  },
]) as any;
const headerCateList = ref([
  {
    title: "사원정보",
    align: "center",
    children: [
      { title: "순번", key: "index", align: "center", sortable: false },
      { title: "사번", key: "empNum", align: "center", sortable: false },
      { title: "이름", key: "empName", align: "center", sortable: false },
      { title: "직종", key: "empJob", align: "center", sortable: false },
    ],
  },
  {
    title: "기초정보",
    align: "center",
    children: [
      { title: "약정월급", key: "monthPay", align: "center", sortable: false },
      { title: "일일근로시간", key: "oneDayWorkTime", align: "center", sortable: false },
      { title: "일일당직수당", key: "oneDayDutyPay", align: "center", sortable: false },
      { title: "시급", key: "timePay", align: "center", sortable: false },
      { title: "이번달근로일수", key: "totalDays", align: "center", sortable: false },
      { title: "이번달당직일수", key: "totalDutyDays", align: "center", sortable: false },
    ],
  },
  {
    title: "임금구성",
    align: "center",
    children: [
      { title: "기본급", key: "base", align: "center", sortable: false },
      { title: "연장수당", key: "overtime", align: "center", sortable: false },
      { title: "야간수당", key: "night", align: "center", sortable: false },
      { title: "휴일수당", key: "holiday", align: "center", sortable: false },
      { title: "연차수당", key: "dayOffPay", align: "center", sortable: false },
      { title: "장기근속", key: "longTerm", align: "center", sortable: false },
      { title: "상여금", key: "bonus", align: "center", sortable: false },
      { title: "교육비", key: "eduPay", align: "center", sortable: false },
      { title: "보존수당", key: "bojon", align: "center", sortable: false },
      { title: "기타수당", key: "etc", align: "center", sortable: false },
      { title: "중식대", key: "lunch", align: "center", sortable: false },
      { title: "차량비", key: "car", align: "center", sortable: false },
      { title: "양육비", key: "childcare", align: "center", sortable: false },
      { title: "임금합계", key: "total", align: "center", sortable: false },
    ],
  },
  {
    title: "조정내역",
    align: "center",
    children: [
      { title: "과세임금", key: "taxable", align: "center", sortable: false },
      { title: "행 삭제", key: "usual", align: "center", sortable: false },
      { title: "최저임금", key: "minimum", align: "center", sortable: false },
    ],
  },
  {
    title: "근로자 공제",
    align: "center",
    children: [
      { title: "연 금", key: "", align: "center", sortable: false },
      { title: "건 강", key: "", align: "center", sortable: false },
      { title: "장 기", key: "", align: "center", sortable: false },
      { title: "고 용", key: "", align: "center", sortable: false },
      { title: "소득세", key: "", align: "center", sortable: false },
      { title: "지방세", key: "", align: "center", sortable: false },
      { title: "지원공제", key: "", align: "center", sortable: false },
      { title: "식비공제", key: "", align: "center", sortable: false },
      { title: "기타공제", key: "", align: "center", sortable: false },
      { title: "연말정산", key: "", align: "center", sortable: false },
      { title: "합 계", key: "", align: "center", sortable: false },
    ],
  },
  {
    title: "기관 공제",
    align: "center",
    children: [
      { title: "연 금", key: "", align: "center", sortable: false },
      { title: "건 강", key: "", align: "center", sortable: false },
      { title: "장 기", key: "", align: "center", sortable: false },
      { title: "고 용", key: "", align: "center", sortable: false },
      { title: "실 업", key: "", align: "center", sortable: false },
      { title: "산 재", key: "", align: "center", sortable: false },
      { title: "퇴직금", key: "", align: "center", sortable: false },
      { title: "합 계", key: "", align: "center", sortable: false },
    ],
  },
] as any[]);

const filteredItems = computed(() => {
  if (!search.value) {
    return wageList.value;
  }
  return wageList.value.filter((item) => {
    const matchesSearch =
      (item.empName && item.empName.toLowerCase().includes(search.value.toLowerCase())) ||
      (item.empJob && item.empJob.toLowerCase().includes(search.value.toLowerCase()));
    return matchesSearch;
  });
});
watch(
  () => store.state.AdminYYMM,
  (newValue) => {
    if (newValue) {
      date.value = newValue;
      empWageReal();
    }
  }
);

// const onInput = (item: any, value: any) => {
//   item[value] = item[value].replace(/[^0-9]/g, "");

//   // 숫자를 천 단위 콤마로 포맷팅
//   item[value] = new Intl.NumberFormat().format(Number(item[value]));
// };
const selectedRow = ref(null);
const readyToCalcTr = (item: any) => {
  selectedRow.value = item.empNum;
};

const form = reactive({
  emp_idx: 0,
  emp_id: "",
  sisul_code: "",
  emp_num: "",
  emp_name: "",
  emp_job: "",
  emp_birth_num: "",
  emp_phone: "",
  emp_email: "",
  emp_addr: "",
  emp_bank: "",
  emp_bank_num: "",
  emp_bank_owner: "",
  emp_status: 0,
  emp_biz_gubun: "",
  emp_in_date: "",
  emp_out_date: "",
  emp_memo: "",
  emp_wdate: "",
  emp_ddate: "",
  emp_isBoss: 0,
  emp_insu: "",
  ew_idx: 0,
  sisul_name: "",
  ew_ym: "",
  ew_base: 0,
  ew_overtime: 0,
  ew_night: 0,
  ew_holiday: 0,
  ew_dayOff: 0,
  ew_longTerm: 0,
  ew_bonus: 0,
  ew_edu_pay: 0,
  ew_bojon: 0,
  ew_etc: 0,
  ew_lunch: 0,
  ew_car: 0,
  ew_childcare: 0,
  ew_total: 0,
  ew_taxable: 0,
  ew_usual: 0,
  ew_minimum: 0,
  ew_l_yeon: 0,
  ew_l_gun: 0,
  ew_l_jang: 0,
  ew_l_go: 0,
  ew_l_so: 0,
  ew_l_ji: 0,
  ew_l_jiwon: 0,
  ew_l_sik: 0,
  ew_l_etc: 0,
  ew_l_year_end: 0,
  ew_l_total: 0,
  ew_c_yeon: 0,
  ew_c_gun: 0,
  ew_c_jang: 0,
  ew_c_go: 0,
  ew_c_sil: 0,
  ew_c_san: 0,
  ew_c_out: 0,
  ew_c_total: 0,
  ew_time_pay: 0,
  ew_work_day: 0,
  ew_work_time: 0,
  ew_one_day_work_time: 0,
  ew_pay_dat: "",
  ew_onh_non_tax: 0,
  ew_night_hour: 0,
  ew_overtime_hour: 0,
  ew_holiday_hour: 0,
  emp_birth: 0,
  ew_real_money: 0,
  ew_manual: "",
  ew_wdate: "",
  ew_ddate: "",
  ew_month_pay: 0,
  ew_one_day_duty_pay: 0,
  ew_total_duty_days: 0,
});

const empWageReal = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/empWageReal/${code.value}/${date.value}`);
    const responseData = response.data;
    if (responseData) {
      wageList.value = responseData.result;
      soji.value = responseData.soji;
      tax.value = responseData.tax;
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await empWageReal();
});
onUnmounted(() => {
  loading.value = false;
});
</script>
