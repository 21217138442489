<template>
  <v-sheet class="is3">
  <div class="is_max_width">
    <div class="is3_TitleLine">
      <b class="is3_title">HR Service</b>
      <span class="is3_title_right"
        >최고의 전문성으로 추가비용 없는 서비스를 만들다.</span
      >
    </div>

    <div class="is3_HRService_ment">
      <p>가장 많은 이슈 중의 하나는 인사노무에 대한 부분입니다.</p>
      <p>수 많은 원장님들이 노사문제로 골머리를 앓으셨고</p>
      <p>소 잃고 외양간 고치는 방식으로 힘들게 살아오셨습니다.</p>
      <p>이러한 고질적 문제를 해결하고자 전문가들과 함께 고민했습니다.</p>
    </div>
    
    <v-row class="is3_big_steps_row displayFlexDefault">
      <v-col cols="5">
        <div class="is3_steps is3_step_1">
          <p class="is3_steps_title">Step1. 2021년도 비과세법 개정</p>
          <div class="is3_steps_content">
            <p>• 요양보호사의 정부지정 근로자에 포함</p>
            <p>• 연장, 야간, 휴일 수당이 연 240만원 한도 내 비과세 혜택</p>
            <p>• '통상임금 210만원 이내' 라는 조건으로 적용 힘듦</p>
          </div>
        </div>
      </v-col>
      <v-col cols="2" class="displayFlexDefault">
        <img class="is3_steps_arrow" src="@/assets/pictures/main/arrow.png" />
      </v-col>
      <v-col cols="5">
        <div class="is3_steps is3_step_2">
          <p class="is3_steps_title">Step2. 제휴 세무사, 노무사와의 해결방안 연구</p>
          <div class="is3_steps_content">
            <p>• 지자체 주무부처 상시 회견</p>
            <p>• 적용 가능한 방안 마련을 위한 지속적인 연구</p>
            <p>• 연구 내용에 따른 SaaS 시스템 개발</p>
            <p>• 적법한 결과값 도출 확인</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="is3_big_steps_row">
      <v-col cols="9"> </v-col>
      <v-col>
        <img
          class="is3_steps_arrow rotatedImage90"
          src="@/assets/pictures/main/arrow.png"
        />
      </v-col>
    </v-row>
    <v-row class="is3_big_steps_row displayFlexDefault">
      <v-col cols="5">
        <div class="is3_steps is3_step_4">
          <p class="is3_steps_title" style="color: rgb(10, 59, 255)">
            Step4. 전문가가 인정하고 책임지는 서비스
          </p>
          <div class="is3_steps_content">
            <p>• 법적 기준에 부합된 전문 서비스</p>
            <p>• 법적 문제 발생에 책임지는 서비스</p>
            <p>• 추가비용 없는 장기요양기관만을 위한 전문 서비스</p>
          </div>
        </div>
      </v-col>
      <v-col cols="2" class="displayFlexDefault">
        <img
          class="is3_steps_arrow rotatedImage180"
          src="@/assets/pictures/main/arrow.png"
        />
      </v-col>
      <v-col cols="5">
        <div class="is3_steps is3_step_3">
          <p class="is3_steps_title">Step3. HR SVC 시스템에 대한 '24 시범사업</p>
          <div class="is3_steps_content">
            <p>• 관련 법령에 따른 임금대장과 세금신고 이후 문제점 확인</p>
            <p>• 수정 신고 이후 문제점 확인</p>
            <p>• 근로자의 불이익 여부에 대한 검산 및 반응 확인</p>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="is3_small_steps_row displayFlexDefault">
      <v-col cols="9">
        <div class="is3_steps is3_step_1">
          <p class="is3_steps_title">Step1. 2021년도 비과세법 개정</p>
          <div class="is3_steps_content">
            <p>• 요양보호사의 정부지정 근로자에 포함</p>
            <p>• 연장, 야간, 휴일 수당이 연 240만원 한도 내 비과세 혜택</p>
            <p>• '통상임금 210만원 이내' 라는 조건으로 적용 힘듦</p>
          </div>
        </div>
      </v-col>
      <v-col cols="9" class="displayFlexDefault">
        <img class="is3_steps_arrow rotatedImage90" src="@/assets/pictures/main/arrow.png" />
      </v-col>
      <v-col cols="9">
        <div class="is3_steps is3_step_2">
          <p class="is3_steps_title">Step2. 제휴 세무사, 노무사와의 해결방안 연구</p>
          <div class="is3_steps_content">
            <p>• 지자체 주무부처 상시 회견</p>
            <p>• 적용 가능한 방안 마련을 위한 지속적인 연구</p>
            <p>• 연구 내용에 따른 SaaS 시스템 개발</p>
            <p>• 적법한 결과값 도출 확인</p>
          </div>
        </div>
      </v-col>
      <v-col cols="9" class="displayFlexDefault">
        <img class="is3_steps_arrow rotatedImage90" src="@/assets/pictures/main/arrow.png" />
      </v-col>
      <v-col cols="9">
        <div class="is3_steps is3_step_3">
          <p class="is3_steps_title">Step3. HR SVC 시스템에 대한 '24 시범사업</p>
          <div class="is3_steps_content">
            <p>• 관련 법령에 따른 임금대장과 세금신고 이후 문제점 확인</p>
            <p>• 수정 신고 이후 문제점 확인</p>
            <p>• 근로자의 불이익 여부에 대한 검산 및 반응 확인</p>
          </div>
        </div>
      </v-col>
      <v-col cols="9" class="displayFlexDefault">
        <img class="is3_steps_arrow rotatedImage90" src="@/assets/pictures/main/arrow.png" />
      </v-col>
      <v-col cols="9">
        <div class="is3_steps is3_step_4">
          <p class="is3_steps_title" style="color: rgb(10, 59, 255)">
            Step4. 전문가가 인정하고 책임지는 서비스
          </p>
          <div class="is3_steps_content">
            <p>• 법적 기준에 부합된 전문 서비스</p>
            <p>• 법적 문제 발생에 책임지는 서비스</p>
            <p>• 추가비용 없는 장기요양기관만을 위한 전문 서비스</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="is3_intro_ment">
      <b> ◈ 우리 기관에 맞는 HR 전략, 시스템으로 체계적인 운영관리를 지원합니다. </b>
    </div>
    <div class="is3_tb_wage_box tac is3_small_tb_box">
      Document
    </div>
    <table class="is3_tb">
      <tbody>
        <tr>
          <td rowspan="3" class="is3_big_tb_box">
            <div class="is3_tb_wage_box tac">Document</div>
          </td>
          <td class="tal">
            <b>근로계약서</b>
          </td>
          <td>
            <span
              >근로자의 근무내역과 지급 금액에 따른 명확한 임금항목 설계의 가이드라인을
              제시</span
            >
          </td>
        </tr>
        <tr>
          <td class="tal">
            <b>합의서</b>
          </td>
          <td>
            <span
              >우리 기관에 최적화된 올바른 탄력적 근로시간제, 휴일대체, 연차휴가 이월 등을
              제공</span
            >
          </td>
        </tr>
        <tr>
          <td class="tal">
            <b>취업규칙</b>
          </td>
          <td>
            <span
              >최신 법령을 적용하고, 올바른 정년의 설정과 촉탁계약직의 유연한 운용
              지원</span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div class="is3_tb_wage_box tac is3_small_tb_box">
      Wages
    </div>
    <table class="is3_tb">
      <tbody>
        <tr>
          <td rowspan="3" class="is3_big_tb_box">
            <div class="is3_tb_wage_box tac">Wages</div>
          </td>
          <td class="tal">
            <b>임금설계</b>
          </td>
          <td>
            <span
              >최적화된 임금설계로 정확한 임금 및 퇴직급여를 지급하고, 사회보험의 부담
              감소</span
            >
          </td>
        </tr>
        <tr>
          <td class="tal">
            <b>임금대장</b>
          </td>
          <td>
            <span
              >근로자에게 안정적인 급여지급과 포괄임금제에 따른 명확한 자료를 지원하여
              리스크 감소</span
            >
          </td>
        </tr>
        <tr>
          <td class="tal">
            <b>기타관리</b>
          </td>
          <td>
            <span>연차촉진, 대체휴일 등에 대한 노사갈등 요소를 미연에 방지</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="is3_tb_wage_box tac is3_small_tb_box">
      Social insurance
    </div>
    <table class="is3_tb">
      <tbody>
        <tr>
          <td rowspan="3" class="is3_big_tb_box">
            <div class="is3_tb_wage_box tac">
              Social <br />
              insurance
            </div>
          </td>
          <td class="tal">
            <b>취득/상실/수정</b>
          </td>
          <td>
            <span
              >임금내역의 과세임금에 대한 취득, 상실, 수정 신고 → 불필요한 지출 방지</span
            >
          </td>
        </tr>
        <tr>
          <td class="tal">
            <b>이직확인서</b>
          </td>
          <td>
            <span>근로자 이직에 따른 확인서 지원</span>
          </td>
        </tr>
        <tr>
          <td class="tal">
            <b>보수총액신고</b>
          </td>
          <td>
            <span>연말정산 이후 과세임금에 따른 보수총액신고</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="is3_tb_wage_box tac is3_small_tb_box">
      Tax
    </div>
    <table class="is3_tb">
      <tbody>
        <tr>
          <td rowspan="4" class="is3_big_tb_box">
            <div class="is3_tb_wage_box tac">Tax</div>
          </td>
          <td class="tal" colspan="2">
            <b>원천징수이행상황신고</b>
          </td>
          <td style="border: none">
            <span></span>
          </td>
        </tr>
        <tr>
          <td class="tal" colspan="2">
            <b>간이지급명세서신고</b>
          </td>
          <td style="border: none">
            <span></span>
          </td>
        </tr>
        <tr>
          <td class="tal" colspan="2">
            <b>퇴직소득세신고</b>
          </td>
          <td style="border: none">
            <span></span>
          </td>
        </tr>
        <tr>
          <td class="tal" colspan="2">
            <b>연말정산</b>
          </td>
          <td style="border: none">
            <span></span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="is3_TitleLine">
      <b class="is3_title">Financial Account</b>
      <span class="is3_title_right"></span>
    </div>

    <v-row class="is3_Fna_Row is3_big_steps_row displayFlexDefault">
      <v-col cols="5">
        <table class="is3_tb">
          <tbody>
            <tr>
              <td rowspan="3">
                <div class="is3_tb_fna_box tac">업무처리</div>
              </td>
              <td class="tac">
                <b>재무회계 규칙준수</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>정밀성 책임성 신속성</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>경영의사결정 지원</b>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="is3_tb">
          <tbody>
            <tr>
              <td rowspan="3">
                <div class="is3_tb_fna_box tac">문서관리</div>
              </td>
              <td class="tac">
                <b>월별 회계장부</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>분기별 보고서</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>각종 지원문서</b>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="is3_fna_report">
        <img src="@/assets/pictures/main/fna-example.png" />
      </v-col>
    </v-row>


    <v-row class="is3_Fna_Row is3_small_steps_row displayFlexDefault">
      <v-col cols="8">
        <table class="is3_tb">
          <tbody>
            <tr>
              <td rowspan="3">
                <div class="is3_tb_fna_box tac">업무처리</div>
              </td>
              <td class="tac">
                <b>재무회계 규칙준수</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>정밀성 책임성 신속성</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>경영의사결정 지원</b>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="is3_tb">
          <tbody>
            <tr>
              <td rowspan="3">
                <div class="is3_tb_fna_box tac">문서관리</div>
              </td>
              <td class="tac">
                <b>월별 회계장부</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>분기별 보고서</b>
              </td>
            </tr>
            <tr>
              <td class="tac">
                <b>각종 지원문서</b>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="12" class="is3_fna_report">
        <img src="@/assets/pictures/main/fna-example.png" />
      </v-col>
    </v-row>



    <div class="is3_TitleLine">
      <b class="is3_title">Meal Management</b>
      <span class="is3_title_right is3_title_right_meal"
        >요양시설에서의 급식소 운영에 대한 문제가 현실화되고 있습니다.</span
      >
    </div>

    <div class="is3_intro_ment">
      <b class="is3_meal_ment">◈ 식재료비 수입에 대한 경비처리 문제</b>
      <p class="is3_meal_content">• 식재료비 수입에 대한 경비처리 문제</p>
      <p class="is3_meal_content">
        • 비급여로 분류되어 반드시 100% 경비처리하거나 남을 경우에는 반환해야 합니다.
      </p>
    </div>
    <div class="is3_intro_ment">
      <b class="is3_meal_ment">◈ 장기요양기관 제3차 기본계획</b>
      <p class="is3_meal_content">• 비급여 공개 가이드라인 마련</p>
      <p class="is3_meal_content">• 급식서비스 제공체계 강화</p>
      <p class="is3_meal_content">• 장기요양기관 투명성 제고</p>
    </div>
    <div class="is3_intro_ment">
      <b class="is3_meal_ment">◈ 사회복지시설 집단급식소 관리감독 강화</b>
      <p class="is3_meal_content">
        • 식약처 : 어린이, 사회복지시설 급식지원센터 확대 운영
      </p>
    </div>
    <div class="is3_intro_ment">
      <b class="is3_meal_ment">◈ 요양시설 수급자 규모에 따른 급식소 운영 선택사항</b>

      <table class="is3_meal_tb">
        <thead>
          <tr>
            <th colspan="2" rowspan="2">구분</th>
            <th rowspan="2">형태</th>
            <th colspan="2">1식 식수인원에 따른 기준</th>
          </tr>
          <tr>
            <th>50명 미만</th>
            <th>50명 이상</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2" class="tac">직접운영</td>
            <td>
              • 직접 인력구성<br />
              • 식자재 업체 선정 또는 직접구매<br />
              • '식재료비수입'에서 식자재비 이외 지출불가
            </td>
            <td>• YES</td>
            <td>
              • NO<br />
              - 경비처리 어려움<br />
              - 인건비 증가<br />
              • 경비처리에 대한 해법이 있다면 운영해도 무방
            </td>
          </tr>
          <tr>
            <td rowspan="2" class="tac">위탁</td>
            <td class="tac">
              이동급식<br />
              (부분위탁)<br />
            </td>
            <td>
              • 완제품 음식 <br />
              • 가공 후 배송
            </td>
            <td>• YES</td>
            <td>
              • NO<br />
              - 식품위생법 위반
            </td>
          </tr>
          <tr>
            <td class="tac">
              위탁급식 <br />
              (전량위탁)
            </td>
            <td>
              • 인력관리<br />
              • 식자재관리<br />
              • 완전 위탁경영
            </td>
            <td>
              • YES or NO<br />
              - 인건비증가 극복
            </td>
            <td>• YES</td>
          </tr>
        </tbody>
      </table>
      <span class="is3_meal_tb_under_text"
        >※식수인원에는 종사자도 포함</span
      >
    </div>
    </div>
  </v-sheet>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
});
</script>
