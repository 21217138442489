<template>
  <v-sheet>
    <v-row class="nonPM nonPMDashBoard">
      <v-col>
      <v-btn color="yellow-darken-2" @click="goToLink('WageMain')">노무관리 Go</v-btn>
      </v-col>
      <v-col>
        <v-btn color="blue-darken-3" :prepend-icon="mdiLock">재무회계 Go</v-btn>
        </v-col>
      <v-col>
        <v-btn color="green-darken-2" :prepend-icon="mdiLock">위탁급식 Go</v-btn>
        </v-col>
    </v-row>
  </v-sheet>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router';
import {
  mdiLock
} from '@mdi/js';

const router = useRouter();

const goToLink = (link : any) => {
  router.push(`/${link}`);
}
</script>