<template>
  <div class="">
    <!-- <div><IndexSection1 /></div>
    <div><IndexSection2 /></div>
    <div><IndexSection3 /></div> -->
    <!-- <div><IndexSection4 /></div> -->
</div>
  <v-sheet id="MainIndexSheet">
    <IndexSection1 />
    <IndexSection2 />
    <IndexSection3 />
  </v-sheet>

</template>

<script setup lang="ts">
import IndexSection1 from '@/components/main/new_index_page/IndexSection1.vue';
import IndexSection2 from '@/components/main/new_index_page/IndexSection2.vue';
import IndexSection3 from '@/components/main/new_index_page/IndexSection3.vue';
// import IndexSection4 from '@/components/main/new_index_page/IndexSection4.vue';
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
});
</script>