<template>
  <v-row class="nonPM">
    <v-col class="nonPM">
      <v-btn @click="getCompContract()">시설계약서 열기</v-btn>
      <v-btn @click="getCMSContract()">자동이체 이용 신청서 열기</v-btn>
    </v-col>
    <v-col class="nonPM">
      <iframe v-if="pdfUrl" :src="pdfUrl" width="100%" height="100%" frameborder="0"></iframe>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import store from "@/store";
import axios from "axios";
import { ref, reactive, onMounted } from "vue";

const pdfUrl = ref<string | null>(null);
const code = ref('') as any;
onMounted(async () => {
  code.value = store.state.AdminSisulCode;
});

const getCompContract = async () => {
  try {
    // 서버로부터 PDF 데이터를 요청
    const response = await axios.post(`/api/getCompContract/${code.value}`
    ,{ responseType: "json" }
    );

    // 서버로부터 받은 데이터에서 PDF Base64 문자열 추출
    const pdfBase64 = response.data.pdf;

    // Base64 문자열을 이진 데이터로 변환
    const binaryString = window.atob(pdfBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // 이진 데이터로부터 Blob 생성
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Blob URL 생성
    pdfUrl.value = window.URL.createObjectURL(blob);
  } catch (error) {
    console.error("PDF 미리보기 중 에러 발생:", error);
  }
};
const form = reactive({
  send_name: "",
  send_rel_depo: "",
  send_phone: "",
  cms_playing_agree: true,
  cms_policy_agree: true,
  sisul_name: "",
  sisul_num: "",
  sisul_tell: "",
  cms_apply_gubun: "신규",
  recv_comp_name: "㈜함우리",
  recv_comp_boss: "김승식",
  recv_comp_addr: "전북특별자치도 전주시 덕진구 기지로77",
  recv_comp_biz_num: "185-81-02287",
  recv_bank_history: "업무위탁 수임료",
  recv_day: "매월 25일",
  payment_method: "계좌",
  bank_owner: "",
  bank_owner_birth: "",
  bank_name: "",
  send_biz_num: "",
  bank_num: "",
  bank_owner_phone: "",
  cms_contract_day: "2025-04-02",
});
const getCMSContract = async () => {
  try {
    // 서버로부터 PDF 데이터를 요청
    const level = 0;
    const response = await axios.post(
      `/api/getCMSContract/${level}`,
      { form: form },
      { responseType: "json" }
    );

    // 서버로부터 받은 데이터에서 PDF Base64 문자열 추출
    const pdfBase64 = response.data.pdf;

    // Base64 문자열을 이진 데이터로 변환
    const binaryString = window.atob(pdfBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // 이진 데이터로부터 Blob 생성
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Blob URL 생성
    pdfUrl.value = window.URL.createObjectURL(blob);
  } catch (error) {
    console.error("PDF 미리보기 중 에러 발생:", error);
  }
};
</script>
