<template>
  <v-navigation-drawer 
    v-model="store.state.drawer"
    id="MenuNavi"
    disable-resize-watcher
  >
    <v-list v-model:opened="openGroups">
      <!-- 홈 -->
      <v-list-item
        :prepend-icon="mdiHomeOutline"
        title="Home"
        :class="{ 'bg-grey-darken-2': isActive('/') }"
        @click="navigateTo('SisulAdminDashBoard')"
      ></v-list-item>

      <!-- 메뉴 그룹 -->
      <v-list-group 
        v-for="(group, groupName) in sectionsMap"
        :key="groupName"
        :value="groupName"
      >
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" :prepend-icon="getGroupIcon(groupName)" :title="groupName"></v-list-item>
        </template>

        <v-list-item
          v-for="item in group"
          :key="item.title"
          :prepend-icon="item.prependIcon"
          :title="item.title"
          :class="{ 'bg-grey-darken-2': isActive(item.link) }"
          @click="navigateTo(item.link)"
        ></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import {
  mdiFaceMan,
  mdiHomeOutline,
  mdiClipboardTextOutline,
  mdiAccount,
  mdiClipboardEditOutline,
  mdiInformationBoxOutline,
  mdiOfficeBuildingOutline,
  mdiNotebookOutline,
  mdiHandshakeOutline,
  mdiFileSign,
  mdiNotebookEdit,
  // mdiCurrencyKrw,
  // mdiFinance,
  mdiLock
} from '@mdi/js';

const store = useStore();
const router = useRouter();
const route = useRoute();
const openGroups = ref<string[]>([]); // 기본적으로 열린 그룹 설정

// 현재 경로와 메뉴 링크 비교하여 활성화 상태 확인
const isActive = (link: string) => {
  return route.path === `/${link}`;
};

// 현재 활성화된 페이지가 속한 그룹 찾기
const findGroupByLink = () => {
  return Object.entries(sectionsMap).find(([, items]) =>
    items.some(item => `/${item.link}` === route.path)
  )?.[0];
};


// 페이지 이동 시 메뉴 그룹 열기
const navigateTo = (link: string) => {
  router.push(`/${link}`);
};

// 현재 활성화된 페이지의 그룹을 찾아서 `openGroups` 업데이트
const updateOpenGroups = () => {
  const activeGroup = findGroupByLink();
  if (activeGroup && !openGroups.value.includes(activeGroup)) {
    openGroups.value.push(activeGroup);
  }
};

// 그룹별 아이콘 설정
const getGroupIcon = (groupName: string) => {
  const icons: Record<string, string> = {
    'HR서비스': mdiFaceMan,
    '기초정보': mdiInformationBoxOutline ,
    '근태관리': mdiClipboardTextOutline,
    '재무회계': mdiLock,
    '위탁급식': mdiLock
  };
  return icons[groupName] || mdiHomeOutline;
};

// 전체 메뉴 데이터
const sectionsMap: Record<string, { title: string; link: string; prependIcon: string }[]> = {
  '기초정보': [
    { title: '기관관리', link: 'CompBasicInfo', prependIcon: mdiOfficeBuildingOutline },
    { title: '사원목록', link: 'EmpBasicInfo', prependIcon: mdiAccount },
    { title: '사원계약', link: 'EmpContract', prependIcon: mdiHandshakeOutline },
    { title: '기관계약', link: 'CompContract', prependIcon: mdiFileSign },
  ],
  'HR서비스': [
    { title: 'HOME', link: 'WageMain', prependIcon: mdiHomeOutline },
    { title: '임금기초', link: 'WageBasic', prependIcon: mdiClipboardEditOutline },
    { title: '임금대장', link: 'WageLedger', prependIcon: mdiNotebookOutline },
    { title: '임금정보등록', link: 'WageReal', prependIcon: mdiNotebookEdit },
  ],
  '재무회계': [
  ],
  '위탁급식': [
  ],
};

// 스토어 값 감시하여 메뉴 업데이트
watch(() => store.state.AdminService, (newValue) => {
  if (newValue) openGroups.value = [newValue];
  console.log(newValue,'newValue')
});

// 라우트 변경 감지하여 `openGroups` 업데이트
watch(() => route.path, updateOpenGroups, { immediate: true });

// 초기 메뉴 설정
onMounted(updateOpenGroups);
</script>
