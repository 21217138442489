<template>
<div style="width: 230px; display: flex; justify-content: space-between; align-items: center;">
  <v-checkbox class="registCmsAgree" v-if="agree === true" @click="dialog = true" v-model="agree" color="indigo" :value=true hide-details>
  </v-checkbox>
  <div style="font-weight: 400;">
    <a @click="dialog = true" style="cursor: pointer;">
      [클릭] <span style="color:blue;">개인정보 수집 및 이용 동의</span>
    </a>
  </div>
</div>


  <v-dialog v-model="dialog" height="800px" class="fontNotoSans400" persistent>
    <v-sheet class="policySheet">
      <v-sheet class="policySheet2"  ref="policyCard">
        <v-card class="policyCard">
          <div style="margin-bottom: 20px; text-align: center;">
            <h2 class="modal-title">개인정보 수집 및 이용 동의</h2>
          </div>
          <div style="text-align: left;" class="cmsAgreeP">
          <p>
            ◆ 수집 및 이용목적: 자동이체를 통한 요금 수납, 민원처리 및 동의여부 통지
          </p>
          <p>
            ◆ 수집 항목: 신청인(성명, 연락처, 휴대번호), 예금(카드)주명, 은행(카드)명, 계좌(카드)번호, 생년월일(사업자등록번호), 카드유효기간, 전자서명
          </p>
          <p>
            ◆ 전자서명 이용목적<br/>
             - 서명 참여자의 본인 확인 진행<br/>
             - 서명 요청자와 서명 참여자간 전자계약 체결<br/>
             - 서명 요청자와 서명 참여자의 문서 이력 관리 및 보관<br/>
             - 서명 요청자와 서명 참여자의 민원 처리<br/>
             - 법적 분쟁 발생 시, 증거로 활용<br/>
          </p>
          <p>
            ◆ 보유 및 이용기간: 수집 이용 동의일부터 자동이체서비스 종료일(해지일)까지며, 보유는 해지일로부터 5년간 보존 후 파기(관계 법령에 의거)
          </p>
          <p>
            신청자는 개인정보 수집 및 이용을 거부할 수 있습니다. 단, 거부하는 경우 자동이체 신청이 정상적으로 처리되지 않습니다.
          </p>
          </div>
        </v-card>
      </v-sheet>
      <div style="text-align: center;">
        <v-btn style="height:40px; width:150px; font-size: 17px; margin: 20px 5px 0 5px; color: blue;" text="동의" @click="agreeCheck"></v-btn>
        <v-btn style="height:40px; width:150px; font-size: 17px; margin: 20px 5px 0 5px;" text="취소" @click="agreeNot"></v-btn>
</div>
    </v-sheet>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue';
// eslint-disable-next-line no-undef
const emit = defineEmits(['success', 'false'])

const dialog = ref(false);
const agree = ref(false);
const agreeCheck = () => {
  dialog.value = false;
  agree.value = true;
  emit('success');
}
const agreeNot = () => {
  dialog.value = false;
  agree.value = false;
  emit('false');
}
</script>
