<template>
  <v-card class="workMainCard workMain1Card">
    <div class="card-content">
      <p class="workMainTitle">직원관리</p>
    </div>

    <v-table class="workMain1_3Tb">
      <tbody>
        <tr v-for="(item, index) in employeeStatus" :key="index">
          <td class="tal">{{ item.title }}</td>
          <td style="display: flex; justify-content: end; align-items: center;">
            <div
              :style="{
                backgroundColor: item.bgColor,
                color: item.textColor,
                padding: '0',
                width: '33px',
                height: '33px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                fontSize: '15px',
              }"
              class="tac"
            >
              {{ item.count }}
            </div>
            <v-icon>{{ mdiChevronRight }}</v-icon>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script setup>
import { mdiChevronRight } from "@mdi/js";

const employeeStatus = [
  {
    title: "계정등록 미완료 직원",
    count: 0,
    bgColor: "rgb(213, 218, 231)",
    textColor: "#666666",
  },
  {
    title: "퇴사를 앞둔 직원(1달)",
    count: 3,
    bgColor: "#1325c6",
    textColor: "#ffffffc6",
  },
  {
    title: "퇴사하였지만 활동상태인 직원",
    count: 5,
    bgColor: "#1325c6",
    textColor: "#ffffffc6",
  },
  {
    title: "요청 승인 일자 임박",
    count: 2,
    bgColor: "#1325c6",
    textColor: "#ffffffc6",
  },
  {
    title: "서비스개선요청사항",
    count: 0,
    bgColor: "rgb(213, 218, 231)",
    textColor: "#666666",
  },
  {
    title: "직원상태점검",
    count: 6,
    bgColor: "#1325c6",
    textColor: "#ffffffc6",
  },
];
</script>
