<template>
  <v-card class="normalMngCard" style="width: 100%;">
    <v-data-table
    no-data-text="데이터가 존재하지 않습니다."  fixed-header density="compact" scrollable :headers="headersArr" :items="filteredItems"
      :search="search" item-value="name" class="fontNotoSans400" style="
        height: calc(100vh - 217px); 

        overflow-y: hidden; 
        position: relative;
      " :items-per-page="50" :items-per-page-options="[
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: 200, title: '200' }
        // { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]" v-if="SsList.length !== 0">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="fontNotoSans700">🏢 고객관리</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field class="inoutSearchInput" style="
          background-color: #fff;
          max-width:200px; 
          width:auto; 
          min-width:70px;
          margin: 0 20px 0 0;
        " v-model="search" color="#0f7545" label="고객기관, 주소 검색" single-line clearable :append-inner-icon="mdiMagnify" flat
            hide-details="auto" variant="outlined" density="compact">
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, index }">
        <tr class="datatableTr">
          <td class="tac">{{ index + 1 }}</td>
          <td class="">
            <v-chip @click="SsIndexPageMove(item)" variant="text" label class="ma-2" color="black">
             
             <v-chip class="tar" color="orange " label v-if="item.sisul_biz_gubun === '주간보호'">
               주간
             </v-chip>
             <v-chip class="tar" color="blue" label v-else-if="item.sisul_biz_gubun === '요양시설'">
               요양
             </v-chip>
             <v-chip class="tar" color="blue" label v-else>
             </v-chip>
             <span style="margin-left: 5px;">{{ item.sisul_name || '' }}</span>
               </v-chip>
               
          </td>
          <td class="tac">
            {{ item.sisul_boss_name }} / {{ item.sisul_boss_phone }}<br>{{ item.sisul_boss_email }}
          </td>
          <td class="tac">
            {{ item.sisul_since }}
          </td>
          <td class="tac">
            {{ item.sisul_hyunwon }}/{{ item.sisul_jungwon }}
          </td>
          <td class="tac">
            {{ item.sisul_small_addr }}
          </td>
          <td class="tac">
            <template v-if="!item.editing">
              <v-chip color="red-accent-4 " v-if="item.sisul_role === 0">
                {{ item.role_name }}
              </v-chip>
              <v-chip color="blue-accent-4" v-else-if="item.sisul_role === 3">
                {{ item.role_name }}
              </v-chip>
              <br/>
              <p v-if="item.sisul_biz_hm_name">
                영업: {{ item.sisul_biz_hm_name }}
              </p>
            </template>
            <template v-else>
              <select v-model="item.sisul_role" style="outline: auto; padding: 3px 20px 3px 20px;">
                <option v-for="option in SsRoleOptions" :key="option.value" :value="option.value">{{ option.label }}
                </option>
              </select>
                          <br/>
                          <v-divider class="mb-1 mt-1"></v-divider>

            <select v-model="item.sisul_biz_hm" style="outline: auto; padding: 3px 20px 3px 20px;">
              <option disabled>영업자</option>
              <option v-for="mng in mngNameList" :key="mng" :value="mng">
                {{ mng }}
              </option>
            </select>

            
            </template>

          </td>
          <td class="tac">
            <template v-if="!item.editing">
              <v-chip color="black" v-if="item.sisul_status === 0">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="green-darken-2" v-else-if="item.sisul_status === 1">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="orange-accent-4" v-else-if="item.sisul_status === 2">
                {{ item.status_name }}
              </v-chip>
              <v-chip color="yellow-darken-1" v-else-if="item.sisul_status === 3">
                {{ item.status_name }}
              </v-chip>
            </template>
            <template v-else>
              <select v-model="item.sisul_status" style="outline: auto; padding: 3px 20px 3px 20px;">
                <option v-for="status in SsStatusOptions" :key="status.value" :value="status.value">{{ status.label }}
                </option>
              </select>
            </template>
          </td>
          <td class="tac">
            <template v-if="!item.editing">
                <p v-if="item.sisul_wage_in">임금: {{ item.sisul_wage_in }}</p>
                <p v-if="item.sisul_fna_in">회계: {{ item.sisul_fna_in }}</p>
                <p v-if="item.sisul_meal_in">급식: {{ item.sisul_meal_in }}</p>
            </template>
            <template v-else>
              임금: <input 
                type="date" 
                style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px;"
                :value="item.sisul_wage_in"
                v-model="item.sisul_wage_in"
              />
              <br>
              회계: <input 
                type="date" 
                style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px;"
                v-model="item.sisul_fna_in"
              />
              <br>
              급식: <input 
                type="date" 
                style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px;"
                v-model="item.sisul_meal_in"
              />
            </template>
          </td>
          <td class="tac">
            <template v-if="!item.editing">
              <p v-if="item.sisul_wage_out">임금: {{ item.sisul_wage_out }}</p>
              <p v-if="item.sisul_fna_out">회계: {{ item.sisul_fna_out }}</p>
              <p v-if="item.sisul_meal_out">급식: {{ item.sisul_meal_out }}</p>
            </template>
            <template v-else>
              임금: <input 
                type="date" 
                style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px;"
                v-model="item.sisul_wage_out"
              />
              <br>
              회계: <input 
                type="date" 
                style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px;"
                v-model="item.sisul_fna_out"
              />
              <br>
              급식: <input 
                type="date" 
                style="border: 1px solid #000; padding: 1px 0 1px 3px; border-radius: 3px;"
                v-model="item.sisul_meal_out"
              />
            </template>
          </td>
          <td class="tac">
            <template v-if="!item.editing">
              <v-btn @click="toggleEditMode(item)" class="btnColorWhite" style="height: 28px;">수정</v-btn>
            </template>
            <template v-else>
              <v-btn @click="changeSs(item)" class="btnColorBlue" style="height: 28px;" v-if="!changeStatus">확정</v-btn>
              <v-btn @click="cancelEdit(item)" class="btnColorPink" style="height: 28px; margin-left: 5px;" v-if="!changeStatus">취소</v-btn>
            </template>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script setup lang="ts">
// import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import {
  mdiMagnify,
} from '@mdi/js'
import axios from 'axios';
import store from '@/store';
const mngNameList = ref([] as any[]);

const SsList = ref(['test'] as any[])
const search = ref('');
const headersArr = ref([
  { title: 'No.', key: 'index', align: 'center', sortable: false },
  { title: '기관명', key: 'sisul_name', align: 'center', sortable: true },
  { title: '대표자', key: 'sisul_boss_name', align: 'center', sortable: true },
  { title: '설립일', key: 'sisul_since', align: 'center', sortable: true },
  { title: '수급자', key: 'sisul_jungwon', align: 'center', sortable: true },
  { title: '주소', key: 'sisul_addr', align: 'center', sortable: true },
  { title: '인증', key: 'sisul_role', align: 'center', sortable: true },
  { title: '상태', key: 'sisul_status', align: 'center', sortable: true },
  { title: '서비스시작일', key: 'sisul_wage_in', align: 'center', sortable: true },
  { title: '서비스탈퇴일', key: 'sisul_wage_out', align: 'center', sortable: true },
  { title: '수정', key: '', align: 'center', sortable: false }
] as any[]);
const SsRoleOptions = ref([
  { label: '미인증', value: 0 },
  { label: '인증', value: 3 }
]);

const SsStatusOptions = ref([
  { label: '탈퇴', value: '0' },
  { label: '정상', value: '1' },
  { label: '휴직', value: '2' },
  { label: '경고', value: '3' },
]);
const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return SsList.value;
  }
  return SsList.value.filter(item =>
    (item.sisul_name && item.sisul_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.sisul_addr && item.sisul_addr.toLowerCase().includes(search.value.toLowerCase()))
  );
});


const saveItem = ref([]) as any;
const saveRole = ref(null) as any;
const saveStat = ref(null) as any;

const toggleEditMode = (item: any) => {
  saveItem.value = item;
  saveRole.value = item.sisul_role;
  saveStat.value = item.sisul_status;
  item.editing = !item.editing;
};
const changeStatus = ref(false);
// 수정 내용을 저장하는 메서드 (서버와의 통신 등 필요한 처리를 추가할 수 있음)
const changeSs = async (item: any) => {
  if(changeStatus.value){
    return;
  }
  const str = item.sisul_biz_hm;
  if(str){
    const result = str.split('(');
    item.sisul_biz_hm_name = result[0] || '';
    item.sisul_biz_hm_id = result[1].replace(')', '') || '';
  }
  try {
    changeStatus.value = true;
    const response = await axios.post(`/api/changeSs/`, {
      item: item
    });
    const result = response.data
    if (result.success) {
      alert("수정되었습니다.");
      await readSs();
    } else {
      alert("수정에 실패하였습니다.");
    }
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  } finally {
    item.editing = false; // 수정 모드 종료
    changeStatus.value = false;
  }
};

// 수정 취소하는 메서드
const cancelEdit = (item: any) => {
  item = saveItem.value;
  item.sisul_role = saveRole.value;
  item.sisul_status = saveStat.value;
  item.editing = false; // 수정 모드 종료
};


const readSs = async () => {
  try {
    const response = await axios.get(`/api/readSs`);
    const responseData = response.data;
    if (responseData) {
      SsList.value = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const SsIndexPageMove =  (item: any) => {
   store.commit('setMngIntoSs', item);
   store.commit('setMngTitle', {title: '메인', link: ''});
};

const getMngNameId = async () => {
  try {
    const response = await axios.get(`/api/getMngNameId`);
    const responseData = response.data;
    if (responseData) {

      const fwaManagers = responseData.map((item: { hm_name_id: any; }) => item.hm_name_id);
      mngNameList.value = fwaManagers;

      return responseData;
    } else {
      console.error('에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

onMounted(async () => {
  await getMngNameId();
  await readSs();
  // store.commit('setMngIntoSs', '');
  // store.commit('setMngTitle', '');
})
</script>
<style scoped>
.datatable {
  width: 100%;
  border-collapse: collapse;
}

.datatable th,
.datatable td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.form-control {
  width: 100%;
  padding: 4px;
}
</style>