<template>
  <div class="fade-in-up">
    <h2 class="registMainTitle">자동이체 신청 정보를 작성해주세요.</h2>
    <p
      class="tac registSubTitle"
      style="font-size: 14px; opacity: 0.8; margin: 3px 0 45px 0"
    >
      CMS자동이체 신청은 필수입니다.
    </p>
    <v-table class="registCmsTable">
      <tbody>
        <tr>
          <td>신청인 명</td>
          <td>
            <v-text-field
              variant="plain"
              v-model="form.send_name"
              type="text"
              maxlength="10"
              color="indigo"
              autocomplete="off"
              density="compact"
              hide-details="auto"
            />
          </td>
        </tr>
        <tr>
          <td>예금주와의 관계</td>
          <td>
            <v-text-field
              variant="plain"
              v-model="form.send_rel_depo"
              type="text"
              maxlength="10"
              color="indigo"
              autocomplete="off"
              density="compact"
              hide-details="auto"
            />
          </td>
        </tr>
        <tr>
          <td>신청인 휴대폰</td>
          <td>
            <v-text-field
              variant="plain"
              v-model="form.send_phone"
              type="text"
              maxlength="11"
              color="indigo"
              autocomplete="off"
              density="compact"
              hide-details="auto"
            />
          </td>
        </tr>

      </tbody> 
    </v-table>
    <CmsPlaying @success="form.cms_playing_agree = true" @false="form.cms_playing_agree = false"/>
    <CmsPolicy @success="form.cms_policy_agree = true" @false="form.cms_policy_agree = false"/>
    <CmsSign @signSuccess="cmsSignSuccess()" />
  </div>
  <v-btn @click="getCMSContract()" class="openTheCmsPdfBtn" color="#fff">자동이체 이용 신청서 열기</v-btn>
  <div class="text-center">
    <v-snackbar v-model="snackbar" :snacktime="snacktime">
      <p class="tac fontNotoSans700">{{ snacktext }}</p>
    </v-snackbar>
  </div>
  <v-dialog v-model="dialog" max-width="2000" height="90%">
    <div style="position: relative; width: 100%; height: 100%;">
      <embed
        v-if="pdfUrl"
        :src="pdfUrl"
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
      <v-btn block @click="dialog = false" color="blue-darken-2">닫기</v-btn>
  </v-dialog>

  <v-btn
      :class="{ clientInfoBtn: status, 'not-agree-disabled': !status }"
      @click="doneCms()"
      >신청서 제출</v-btn
    >
    <p class="registPrevBtn" @click="prev()">이전단계로 돌아가기</p>

</template>
<script setup lang="ts">
import store from "@/store";
import axios from "axios";
import { ref, reactive, watch } from "vue";
import CmsPlaying from '@/components/login-regist/CmsPlaying.vue';
import CmsPolicy from '@/components/login-regist/CmsPolicy.vue';
import CmsSign from '@/components/login-regist/CmsSign.vue';
// eslint-disable-next-line no-undef
const emit = defineEmits(["success", 'prev']);
const snackbar = ref(false);
const isCmsSignStatus = ref(false);
const snacktext = ref(`자동이체 신청 서명이 저장되었습니다.`);
const snacktime = ref(3000);

const cmsSignStatus = ref(false) as any;
const status = ref(false);


const pdfUrl = ref<string | null>(null);
const today = new Date();
const formattedDate = today.toISOString().split("T")[0];
const dialog = ref(false);
const form = reactive({
  send_name: "",
  send_rel_depo: "",
  send_phone: "",
  cms_playing_agree: false,
  cms_policy_agree: false,
  sisul_name: store.state.RegistForm.hc_name,
  sisul_num: store.state.RegistForm.hc_number,
  sisul_tell: store.state.RegistForm.hc_tell,
  cms_apply_gubun: "신규",
  recv_comp_name: "㈜함우리",
  recv_comp_boss: "김승식",
  recv_comp_addr: "전북특별자치도 전주시 덕진구 기지로77",
  recv_comp_biz_num: "185-81-02287",
  recv_bank_history: "업무위탁 수임료",
  recv_day: "매월 25일",
  payment_method: "계좌",
  bank_owner: store.state.RegistForm.sisul_bank_owner_name,
  bank_owner_birth: store.state.RegistForm.sisul_bank_owner_birth,
  bank_name: store.state.RegistForm.sisul_bank,
  send_biz_num: store.state.RegistForm.sisul_biz_num,
  bank_num: store.state.RegistForm.sisul_bank_num,
  bank_owner_phone: store.state.RegistForm.sisul_boss_phone,
  cms_contract_day: formattedDate,
});
const cmsSignSuccess = () => {
  snackbar.value = true;
  isCmsSignStatus.value = true;
}
const doneCms = async () => {
  if (!status.value) return;
  try {
    const response = await axios.post("/api/saveCMSContractPdf", { form });
    
    if (response.data.success) {
      snackbar.value = true;
      snacktext.value = "PDF 저장 완료!";
      emit("success");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("PDF 저장 실패:", error);
    snacktext.value = "PDF 저장 실패!";
    snackbar.value = true;
  }
};

const prev = () => {
  emit('prev');
}

watch([form, isCmsSignStatus], async () => {
  if (
    form.send_name &&
    form.send_rel_depo &&
    form.send_phone &&
    form.cms_playing_agree &&
    form.cms_policy_agree &&
    isCmsSignStatus.value
  ) {
    status.value = true;
  } else {
    status.value = false;
  }
});

const getCMSContract = async () => {
  if(cmsSignStatus.value){
    return;
  }
  try {
    cmsSignStatus.value = true;
    dialog.value = true;
    const level = 0;
    const response = await axios.post(
      `/api/getCMSContract/${level}`,
      { form: form },
      { responseType: "json" }
    );

    // 서버로부터 받은 데이터에서 PDF Base64 문자열 추출
    const pdfBase64 = response.data.pdf;

    // Base64 문자열을 이진 데이터로 변환
    const binaryString = window.atob(pdfBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // 이진 데이터로부터 Blob 생성
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Blob URL 생성
    pdfUrl.value = window.URL.createObjectURL(blob);
  } catch (error) {
    console.error("PDF 미리보기 중 에러 발생:", error);
  } finally{
    cmsSignStatus.value = false;
  }
};
</script>
