<template>
  <div style="display: flex; align-items: center; justify-content: center;">
    <!-- 이전 날로 이동 -->
    <v-icon color="#002e00" @click="previousDay" size="48">{{ mdiChevronLeft }}</v-icon>

    <!-- 연도, 월 선택 메뉴 -->
    <v-menu v-model="isYearMonthMenuOpen" :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <h2
          class="pb-1"
          style="cursor: pointer; color: #333;"
          v-bind="props"
          @click="openYearMonthMenu"
        >
          {{ currentYearMonth }}
        </h2>
      </template>
      <v-card style="width: 300px;">
        <v-card-title style="display: flex; align-items: center; justify-content: center;">
          <v-icon color="#002e00" @click="previousYear" size="48" class="pt-1">{{ mdiChevronLeft }}</v-icon>
          <h2 class="text-center">{{ currentYear }}</h2>
          <v-icon color="#002e00" @click="nextYear" size="48" class="pt-1">{{ mdiChevronRight }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-4" justify="center">
            <v-col v-for="month in months" :key="month.value" cols="3" class="d-flex justify-center">
              <v-btn
                class="monthLabelBtn"
                :class="{ 'btnBg': month.value === currentMonth, 'ma-1': true }"
                @click="selectMonth(month)"
                :disabled="month.value === currentMonth"
              >
                {{ month.label }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>

    <!-- 날짜 선택 메뉴 -->
    <v-menu v-model="isDayMenuOpen" :close-on-content-click="false">
  <template v-slot:activator="{ props }">
    <h2
      class="pb-1"
      style="cursor: pointer; color: #333;"
      v-bind="props"
      @click="openDayMenu"
    >
      -{{ currentDayWithWeekday }}
    </h2>
  </template>
  <v-card style="width: 300px;">
    <v-card-text>
      <v-row class="pa-1" justify="center" v-if="currentMonth">
        <v-col
          v-for="day in daysInMonth"
          :key="day"
          cols="2"
          class="d-flex justify-center"
          style="padding: 4px; width: 100%;"
        >
          <v-btn
            class="dayLabelBtn"
            :class="{ 'btnBg': day === currentDay, 'ma-1': true }"
            @click="selectDay(day)"
            :disabled="day === currentDay"
          >
            {{ day }}일
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</v-menu>


    <!-- 다음 날로 이동 -->
    <v-icon color="#002e00" @click="nextDay" size="48">{{ mdiChevronRight }}</v-icon>
  </div>
</template>



<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { format, parse, addYears, addDays, getYear, getDaysInMonth, setDate } from 'date-fns';
import store from '../../../../store';
import { ko } from 'date-fns/locale';

const months = [
  { label: '1월', value: '01' },
  { label: '2월', value: '02' },
  { label: '3월', value: '03' },
  { label: '4월', value: '04' },
  { label: '5월', value: '05' },
  { label: '6월', value: '06' },
  { label: '7월', value: '07' },
  { label: '8월', value: '08' },
  { label: '9월', value: '09' },
  { label: '10월', value: '10' },
  { label: '11월', value: '11' },
  { label: '12월', value: '12' },
];
const currentDate = ref(parse(store.state.AdminDate, 'yyyy-MM-dd', new Date()));
const isYearMonthMenuOpen = ref(false);
const isDayMenuOpen = ref(false);

const currentYear = computed(() => getYear(currentDate.value));
const currentMonth = computed(() => format(currentDate.value, 'MM'));
const currentYearMonth = computed(() => format(currentDate.value, 'yyyy-MM'));
const currentDay = computed(() => format(currentDate.value, 'dd')) as any;
const currentDayWithWeekday = computed(() => {
  return format(currentDate.value, 'dd (eee)', { locale: ko });
});

// 선택한 월의 마지막 날짜 계산
const daysInMonth = computed(() => {
  return Array.from({ length: getDaysInMonth(currentDate.value) }, (_, i) => i + 1);
});

// 현재 날짜 포맷
const previousYear = () => {
  currentDate.value = addYears(currentDate.value, -1);
  updateStore();
};

const nextYear = () => {
  currentDate.value = addYears(currentDate.value, 1);
  updateStore();
};

// 월 선택 메뉴 열기
const openYearMonthMenu = () => {
  isYearMonthMenuOpen.value = true;
  isDayMenuOpen.value = false;
};

// 날짜 선택 메뉴 열기
const openDayMenu = () => {
  isDayMenuOpen.value = true;
  isYearMonthMenuOpen.value = false;
};

// 월 선택
const selectMonth = (month: any) => {
  const monthIndex = parseInt(month.value, 10) - 1;
  currentDate.value = new Date(currentYear.value, monthIndex, 1);
  isYearMonthMenuOpen.value = false;
  store.commit('setAdminDate', format(currentDate.value, 'yyyy-MM-dd'));
};

// 일 선택
const selectDay = (day: number) => {
  currentDate.value = setDate(currentDate.value, day);
  isDayMenuOpen.value = false;
  store.commit('setAdminDate', format(currentDate.value, 'yyyy-MM-dd'));
};

// 이전/다음 날로 이동
const previousDay = () => {
  currentDate.value = addDays(currentDate.value, -1);
  store.commit('setAdminDate', format(currentDate.value, 'yyyy-MM-dd'));
};
const nextDay = () => {
  currentDate.value = addDays(currentDate.value, 1);
  store.commit('setAdminDate', format(currentDate.value, 'yyyy-MM-dd'));
};

const updateStore = () => {
  store.commit('setAdminDate', format(currentDate.value, 'yyyy-MM-dd'));
};

watch(
  () => store.state.AdminDate,
  (newValue) => {
    if (newValue) {
      currentDate.value = parse(newValue, 'yyyy-MM-dd', new Date());
    }
  }
);

</script>

<style scoped>
.monthLabelBtn {
  background-color: #fff;
  color: #333;
  border: 1px solid rgb(203, 203, 203);
  font-size: 16px;
  min-width: 50px !important;
  max-height: 40px !important;
}
.btnBg {
  background-color: #002e00 !important;
  color: #fff !important;
}
</style>
