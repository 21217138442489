<template>
  <template v-if="loading">
    <AdminLoadingAction />
  </template>
  <template v-else>
    <v-toolbar flat>
      <v-toolbar-title class="fontNotoSans700" v-if="!editingMode"
        >🧑‍💼 사원 목록</v-toolbar-title
      >
      <v-toolbar-title class="fontNotoSans700" v-if="editingMode"
        >🧑‍💼 사원 등록 및 수정</v-toolbar-title
      >

      <v-btn
        v-if="editingMode"
        class="btnColorWhite"
        text="목록으로 돌아가기"
        @click="editingModeFalse()"
      ></v-btn>
      <!-- <v-btn
      v-if="!editingMode"
      class="btnColorBlue"
      text="사원 등록/수정"
      @click="editingMode = true"
    ></v-btn> -->

      <v-btn v-if="!editingMode" class="btnColorWhite" @click="editingMode = true"
        >등록</v-btn
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn @click="dialog = true" class="btnColorWhite">필터</v-btn>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-text-field
        class="inoutSearchInput"
        style="
          background-color: #fff;
          max-width: 200px;
          width: auto;
          min-width: 70px;
          margin: 0 20px 0 0;
        "
        v-model="search"
        color="#0f7545"
        label="이름/직위 검색"
        single-line
        clearable
        :append-inner-icon="mdiMagnify"
        flat
        hide-details="auto"
        variant="outlined"
        density="compact"
      >
      </v-text-field>
    </v-toolbar>

    <!-- 수정로직 -->
    <v-row v-if="editingMode === true" class="nonPM">
      <v-col cols="3">
        <v-sheet style="border-right: 1px solid #e6deef">
          <v-data-table
            no-data-text="사원을 등록해주세요."
            fixed-header
            density="compact"
            scrollable
            :headers="updateHeadersArr"
            :items="filteredItems"
            :search="search"
            item-value="name"
            class="SsEmpWorkPayTypeTb SsEmpListTb fontNotoSans400"
            :items-per-page="-1"
            :items-per-page-options="[
              { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
            ]"
          >
            <template v-slot:item="{ item, index }">
              <tr
                :class="['SsEmpListTbTr', { selected: selectedRowIndex === index }]"
                @click="() => handleRowClick(item, index)"
              >
                <td class="tac">{{ item.emp_num }}</td>
                <td class="tac">{{ item.emp_name }}</td>
                <td class="tac">{{ item.emp_job }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-btn @click="() => handleRowClickBlank()" color="#333" style="width: 100%"
            >사원등록</v-btn
          >
        </v-sheet>
      </v-col>
      <v-col cols="9">
        <EmpDetail @success="readEmpInfo" />
      </v-col>
    </v-row>
    <!-- 수정로직 -->

    <v-data-table
      no-data-text="사원을 등록해주세요."
      v-if="editingMode === false"
      fixed-header
      density="compact"
      scrollable
      :headers="headersArr"
      :items="filteredItems"
      :search="search"
      item-value="name"
      class="adminDataTb"
      :items-per-page="-1"
      :items-per-page-options="[
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
      ]"
    >
      <template v-slot:item="{ item, index }">
        <tr
          :class="{ activeSsTr: expandedRows.includes(index) }"
          class="SsTbTr"
        >
          <td class="tac">{{ index + 1 }}</td>
          <td class="tac">{{ item.emp_num || "" }}</td>
          <td class="tac">{{ item.emp_name || "" }}</td>
          <td class="tac">{{ item.emp_job || "" }}</td>
          <td class="tac">{{ item.emp_birth_num_front || "" }}</td>
          <td class="tac">
            {{ item.emp_phone?.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") || "" }}
          </td>
          <td class="tac">{{ item.emp_email || "" }}</td>
          <td style="text-align: left;">{{ item.emp_addr || "" }}</td>
          <td class="tac">
            {{ item.emp_bank }}<span v-if="item.emp_bank_num">({{ item.emp_bank_num }})</span><span v-if="item.emp_bank_owner">({{ item.emp_bank_owner }})</span>
          </td>
          <td class="tac">{{ item.emp_in_date || "" }}</td>

          <td class="tac">
            <v-chip color="black" v-if="item.emp_status === 0">
              {{ item.status_name }}
            </v-chip>
            <v-chip color="green-darken-4" v-else-if="item.emp_status === 1">
              {{ item.status_name }}
            </v-chip>
            <v-chip color="orange-darken-4" v-else-if="item.emp_status === 2">
              {{ item.status_name }}
            </v-chip>
            <v-chip color="blue-darken-1" v-else-if="item.emp_status === 3">
              {{ item.status_name }}
            </v-chip>
          </td>
          <td @click="toggleRowDetails(item, index)">
            <v-btn class="toggleRowDetailsBtn" color="grey-lighten-3">수정</v-btn>
          </td>
        </tr>
        <td class="bonusTd" :colspan="headersArr.length">
          <v-expand-transition>
            <div v-show="expandedRows.includes(index)">
              <div class="flexCenter">
                <div class="SsEmpDetailTitle">
                  <h4>상세정보</h4>
                </div>
                <v-divider inset vertical class="SsVerticalDivider"></v-divider>
                <div class="SsEmpDetailContent">
                  <p v-if="item.emp_birth_num">
                    주민번호 : {{ `${item.emp_birth_num?.slice(0, 6)}-*******` || null }}
                  </p>
                  <p v-else></p>
                  <p>자택주소 : {{ item.emp_addr }}</p>
                  <p v-show="item.emp_out_date">퇴사일자 : {{ item.emp_out_date }}</p>
                </div>
                <div class="SsEmpDetailTitle">
                  <h4>계좌정보</h4>
                </div>
                <v-divider inset vertical class="SsVerticalDivider"></v-divider>
                <div class="SsEmpDetailContent">
                  <p>사용은행 : {{ item.emp_bank }}</p>
                  <p>계좌번호 : {{ item.emp_bank_num }}</p>
                  <p>예금주명 : {{ item.emp_bank_owner }}</p>
                </div>
                <div class="SsEmpDetailTitle">
                  <h4>메모</h4>
                  <span style="font-size: 10px; opacity: 0.8">(300자)</span>
                </div>
                <div v-if="memoLoading" class="memoSpainner tac SsEmpDetailMemo"></div>
                <div v-else class="SsEmpDetailMemo">
                  <v-textarea
                    v-model="item.emp_memo"
                    rows="2"
                    variant="outlined"
                    maxlength="300"
                    width="300px"
                    hide-details="auto"
                    @focus="focusMemo(item)"
                    @blur="updateEmpMemo(item)"
                  ></v-textarea>
                </div>
                <div class="SsEmpDetailTitle">
                  <h4>상태변경</h4>
                </div>
                <v-divider inset vertical class="SsVerticalDivider"></v-divider>
                <div class="SsEmpDetailContent">
                  <select
                    v-model="item.status_name"
                    @change="updateEmpStatus(item)"
                    style="
                      cursor: pointer;
                      border: 1px solid #454545;
                      padding: 6px 10px;
                      border-radius: 5px;
                    "
                  >
                    <option
                      class="tac"
                      v-for="status in statusOptions2"
                      :key="status"
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </v-expand-transition>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" height="800px" class="fontNotoSans400" persistent>
      <v-card class="SsFilterCard">
        <v-card-title class="SsFilterCardTitle tac">
          <b style="margin-left: 109px">직원검색필터</b>
          <v-btn
            style="float: right; margin-right: 10px"
            class="btnColorWhite"
            @click="allSelectDie()"
            >전체 체크해제</v-btn
          >
        </v-card-title>
        <v-card-actions class="SsFilterCheck">
          <b style="margin-right: 22px">직원상태</b>
          <div v-for="status in statusOptions" :key="status.value" class="inline-div">
            <v-checkbox
              color="secondary"
              :label="status.label"
              v-model="selectStatus"
              :value="status.value"
              hide-details
            />
          </div>
        </v-card-actions>
        <v-card-actions class="SsFilterCheck">
          <b class="SsFilterTitle">직원직위</b>
          <v-sheet class="SsFilterSheet">
            <div v-for="job in jobOptions" :key="job.value" class="inline-div">
              <v-checkbox
                :label="job.label"
                v-model="selectJob"
                :value="job.value"
                hide-details
              />
            </div>
          </v-sheet>
        </v-card-actions>
        <v-btn @click="dialog = false" class="cancelRegistBtn">닫기</v-btn>
      </v-card>
    </v-dialog>
  </template>
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { mdiMagnify } from "@mdi/js";
import axios from "axios";
import AdminLoadingAction from "@/components/admin/layout/AdminLoadingAction.vue";
import store from "@/store";
import EmpDetail from "@/components/admin/common/emp/EmpDetail.vue";

const editingMode = ref(false);
const editingModeFalse = async () => {
  editingMode.value = false;
};
let originalEmp = ref("");
let originalMemo = ref("");

const loading = ref(false);
const memoLoading = ref(false);
const empList = ref([] as any[]);
const code = ref(store.state.AdminSisulCode) as any;
const search = ref("");
const dialog = ref(false);

const selectStatus = ref<number[]>([1, 2, 3]);
const statusOptions = [
  { label: "현원", value: 1 },
  { label: "유급휴가", value: 2 },
  { label: "무급휴가", value: 3 },
  { label: "퇴사", value: 0 },
];
const statusOptions2 = ["현원", "유급휴가", "무급휴가"];
const selectJob = ref<string[]>([
  "시설장",
  "사회복지사",
  "간호사",
  "간호조무사",
  "물리치료사",
  "사무장",
  "사무원",
  "영양사",
  "조리사",
  "조리원",
  "위생원",
  "요양보호사",
  "운전사",
  "기타",
]);
const jobOptions = [
  { label: "시설장", value: "시설장" },
  { label: "사회복지사", value: "사회복지사" },
  { label: "간호사", value: "간호사" },
  { label: "간호조무사", value: "간호조무사" },
  { label: "물리치료사", value: "물리치료사" },
  { label: "사무장", value: "사무장" },
  { label: "사무원", value: "사무원" },
  { label: "영양사", value: "영양사" },
  { label: "조리사", value: "조리사" },
  { label: "조리원", value: "조리원" },
  { label: "위생원", value: "위생원" },
  { label: "요양보호사", value: "요양보호사" },
  { label: "운전사", value: "운전사" },
  { label: "기타", value: "기타" },
];
const allSelectDie = () => {
  selectStatus.value = [];
  selectJob.value = [];
};
const headersArr = ref([
  { title: "No.", key: "index", align: "center", sortable: false },
  { title: "사원번호", key: "emp_num", align: "center", sortable: true },
  { title: "이름", key: "emp_name", align: "center", sortable: true },
  { title: "직위", key: "emp_job", align: "center", sortable: true },
  { title: "생년월일", key: "emp_birth_num_front", align: "center", sortable: true },
  { title: "휴대폰", key: "emp_phone", align: "center", sortable: true },
  { title: "이메일", key: "emp_email", align: "center", sortable: true },
  { title: "주소", key: "emp_addr", align: "center", sortable: true },
  { title: "은행", key: "emp_bank", align: "center", sortable: true },
  { title: "입사일", key: "emp_in_date", align: "center", sortable: true },
  { title: "상태", key: "emp_status", align: "center", sortable: true },
  { title: "기능", key: "", align: "center", sortable: false },
] as any[]);
const filteredItems = computed(() => {
  // `selectStatus`와 `selectJob`의 배열 길이가 모두 0일 때는 검색어와 관계없이 빈 배열을 반환합니다.
  const hasStatusFilter = selectStatus.value.length > 0;
  const hasJobFilter = selectJob.value.length > 0;

  // 두 배열이 모두 비어있으면 빈 배열을 반환합니다.
  if (!hasStatusFilter && !hasJobFilter) {
    return [];
  }

  return empList.value.filter((item) => {
    let searchTerm = null as any;
    if (!search.value) {
      searchTerm = search.value;
    } else {
      searchTerm = search.value.trim().toLowerCase();
    }
    // 검색어에 대해 상태와 직업을 필터링할 수 있는지 확인합니다.
    const matchesSearch =
      !searchTerm ||
      (item.emp_name && item.emp_name.toLowerCase().includes(searchTerm)) ||
      (item.emp_job && item.emp_job.toLowerCase().includes(searchTerm));

    // 상태 필터링
    const matchesStatus =
      !hasStatusFilter || selectStatus.value.includes(item.emp_status);

    // 직업 필터링
    const matchesJobs = !hasJobFilter || selectJob.value.includes(item.emp_job);

    return matchesSearch && matchesStatus && matchesJobs;
  });
});
// const show = ref(false);
const showVal = () => {
  // show.value = !show.value
};
const expandedRows = ref([] as number[]);
const toggleRowDetails = (item: any, index: number) => {
  // if (expandedRows.value.includes(index)) {
  //   expandedRows.value = expandedRows.value.filter((i) => i !== index);
  // } else {
  //   expandedRows.value.push(index);
  // }
  showVal();

  editingMode.value = true;
  selectedRowIndex.value = index;
  store.commit("setSsECDEmpInfo", item);
};
const focusMemo = (item: any) => {
  originalEmp.value = item.emp_num;
  originalMemo.value = item.emp_memo;
};
const updateEmpMemo = async (item: any) => {
  if (memoLoading.value === true) return;

  if (item.emp_memo === originalMemo.value && item.emp_num === originalEmp.value) {
    return;
  }
  if (!item.emp_memo || item.emp_memo === "") {
    item.emp_memo = "isNullTextPOSTMEMO";
  }
  try {
    const empMemo = encodeURIComponent(item.emp_memo);
    memoLoading.value = true;
    const response = await axios.post(
      `/api/updateEmpMemo/${empMemo}/${item.emp_num}/${code.value}`
    );
    const result = response.data;
    if (result.success) {
      await readEmpInfo();
    }
  } catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  } finally {
    memoLoading.value = false;
  }
};

const updateEmpStatus = async (item: any) => {
  try {
    let empStatus = 0;
    if (item.status_name === "현원") {
      empStatus = 1;
    } else if (item.status_name === "유급휴가") {
      empStatus = 2;
    } else if (item.status_name === "무급휴가") {
      empStatus = 3;
    }
    const response = await axios.post(
      `/api/updateEmpStatus/${empStatus}/${item.emp_num}/${code.value}`
    );
    const result = response.data;
    if (result.success) {
      await readEmpInfo();
    }
  } catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};
const readEmpInfo = async () => {
  if (loading.value) return;
  loading.value = true;
  try {
    const response = await axios.get(`/api/readEmpInfo/${code.value}`);
    const responseData = response.data;
    if (responseData) {
      empList.value = responseData;
      console.log(empList.value, "empList.value");

      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
    distroyHandleRowClick();
  }
};
//수정로직
const selectedRowIndex = ref(null) as any;
const handleRowClick = (item: any, index: any) => {
  selectedRowIndex.value = index;
  store.commit("setSsECDEmpInfo", item);
};

const handleRowClickBlank = () => {
  selectedRowIndex.value = null;
  store.commit("setSsECDEmpInfo", []);
};
const distroyHandleRowClick = () => {
  selectedRowIndex.value = null;
  store.commit("setSsECDEmpInfo", "notSelectStatus");
};
const updateHeadersArr = ref([
  { title: "사원번호", key: "emp_num", align: "center", sortable: true },
  { title: "이름", key: "emp_name", align: "center", sortable: true },
  { title: "직위", key: "emp_job", align: "center", sortable: true },
] as any[]);
//수정로직

onMounted(async () => {
  await readEmpInfo();
  store.commit("setSsECDEmpInfo", []);
});
onUnmounted(() => {
  loading.value = false;
  sessionStorage.removeItem("getSsECDEmpInfo");
});
</script>
