<template>
  <v-sheet class="mainMaxWidth labor-menu main-menu-sheet">
    <div class="image-container">
        <img src="@/assets/pictures/main/메뉴_노무.png" class="main-menu-img" cover />
        <div class="main-menu-overlay">
          <p class="main-menu-text">노무관리</p>
          <span class="main-menu-sub-text">Labor management</span>
          <span></span>
        </div>
      </div>
    <div class="main-menu-box">
      <div class="main-menu-content">
        <p class="main-menu-top-ment">현명한 노무관리는 건강한 노사문화의 시작입니다.</p>
        <p class="main-menu-top-sub-ment">몰라서 발생하는 노사갈등이 발생하지 않도록 조직적이고 체계적인 대응이 갈수록 필요합니다.<br/>
          운영하시는 요양시설의 조직문화 개선과 복지향상으로 일하기 좋은 요양시설, 행복한 종사자의 미소가 어르신들에게 물들어가도록 노무관리를 지원합니다.</p>
        <v-row class="labor-menu-row">
          <v-col cols="6" class="pl-0">
            <v-table class="labor-menu-table">
              <thead>
                <tr>
                  <th colspan="2">노무서류</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="labor-menu-table-title">근로계약서,<br/>취업 규칙,<br/>합의서,</td>
                  <td class="labor-menu-table-ment">
                    <p>
                      • 노사갈등 축소 <br/>
                      • 조직운영의 기본 <br/>
                      • 탄력적 근로시간 <br/>
                      • 휴일대체근무
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="labor-menu-table-title2">참조</td>
                  <td class="labor-menu-table-ment">
                    <p>
                      • <a href="https://blog.naver.com/hamwoori365/223492309939" target="_blank">인건비 절감을 위한 임금대장 구조개선</a>
                    </p>
                  </td>                
                </tr>
                <tr></tr>
              </tbody>
            </v-table>
          </v-col>
          <v-col cols="6"  class="pr-0">
            <v-table class="labor-menu-table">
              <thead>
                <tr>
                  <th colspan="2">노무관리</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="labor-menu-table-title">세법,<br/>근로기준법<br/>최대활용</td>
                  <td class="labor-menu-table-ment">
                    <p>
                      • 근로자의 실수령액 확대<br/>
                      • 기관의 인건비 절감
                    </p>
                  </td>
                </tr>
                
                <tr>
                  <td class="labor-menu-table-title2">참조</td>
                  <td class="labor-menu-table-ment">
                    <p>
                      • <a href="https://blog.naver.com/hamwoori365/223501239733" target="_blank">사회보험 환급금 안내</a>
                    </p>
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </v-table>
          </v-col>
          
        </v-row>
        <v-row class="labor-menu-row">
          <v-col cols="6"  class="pl-0">
            <v-table class="labor-menu-table">
              <thead>
                <tr>
                  <th colspan="2">세금신고</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="labor-menu-table-title">비과세혜택<br/>최대활용</td>
                  <td class="labor-menu-table-ment">
                    <p>
                      • 소득세 절감<br/>
                      • 사회보험 절감
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="labor-menu-table-title2">참조</td>
                  <td class="labor-menu-table-ment">
                    <p>
                      • <a href="https://blog.naver.com/hamwoori365/223501322549" target="_blank">사회보험비용 절감</a>
                    </p>
                  </td>                
                </tr>
                <tr></tr>
              </tbody>
            </v-table>
          </v-col>
          </v-row>
      </div>
      
    </div>
  </v-sheet>
</template>
<style>
.labor-menu-row{
  margin: 40px 0 ;
}
.labor-menu-row .v-table {
  border-radius: 10px;
}
.labor-menu-row .v-table thead tr th{
  font-size: 22px;
  font-weight: 600 !important;
  padding: 20px 0 20px 20px !important;
  background-color: #e6e6e6;
}
.labor-menu-row .v-table tbody tr td{
  font-size: 18px;
  letter-spacing: -0.05rem;
  font-weight: 500 !important;
}
.labor-menu-table-title{
  text-align: center;
  width: 140px !important;
  background-color: #f5f5f5;
  padding: 50px 6px !important;
}
.labor-menu-table-title2{
  text-align: center;
  background-color: #f5f5f5;
}
.labor-menu-table-ment{
  padding-left: 25px !important;
}
.labor-menu-table-ment p {
  line-height: 1.8;
}
</style>
<script setup>
import { onMounted} from 'vue';

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
})
</script>