<template>
  <v-card class="workMainCard workMain1Card">
    <div class="card-content">
      <p class="workMainTitle">요청누적</p>
      <v-text-field
        type="date"
        class="reqSearchDate"
        style="background-color: #fff"
        v-model="ymd"
        color="#0f7545"
        label="이름/직위 검색"
        single-line
        :prepend-inner-icon="mdiTilde"
        flat
        hide-details="auto"
        variant="outlined"
        density="compact"
      />
    </div>

    <v-table class="workMain1_2Tb">
      <tbody>
        <tr v-for="(request, index) in requests" :key="index">
          <td class="tal">{{ request.name }}</td>
          <td>{{ request.position }}</td>
          <td>{{ request.date }}</td>
          <td :style="{ color: request.color, fontWeight: '700' }">{{ request.type }}</td>
        </tr>
      </tbody>
    </v-table>

    <v-card-actions style="display: flex; justify-content: end; align-items: center;">
      <v-btn color="primary" class="text-white fw-bold">
        전체 보기({{ requests.length }})
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { mdiTilde } from "@mdi/js";
import { ref, onMounted, watch } from "vue";

import store from "@/store";
const ymd = ref(store.state.AdminDate);

watch(
  () => store.state.AdminDate,
  async (newValue) => {
    if (newValue) {
      ymd.value = store.state.AdminDate;
    }
  }
);

onMounted(async () => {
  ymd.value = store.state.AdminDate;
});

const requests = ref([
  { name: "김노승", position: "간호사", date: "24년 1월 2일 (목)", type: "연차", color: "#2e5939" },
  { name: "전수현", position: "위생원", date: "24년 1월 11일 (토)", type: "연차", color: "#2e5939" },
  { name: "최강록", position: "조리원", date: "24년 1월 13일 (월)", type: "교대", color: "#2e5939" },
  { name: "민수진", position: "요양보호사", date: "24년 1월 13일 (월)", type: "대체", color: "#a73728" },
  { name: "이화영", position: "요양보호사", date: "24년 1월 16일 (목)", type: "연차", color: "#2e5939" },
  { name: "김정수", position: "요양보호사", date: "24년 1월 16일 (목)", type: "교대", color: "#a73728" },
]);
</script>