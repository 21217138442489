<template>
  <div style="font-weight: 400;">
    <v-btn @click="dialog = true" class="btnColorBlue">
      매니저 등록
    </v-btn>
  </div>
  <v-dialog v-model="dialog" height="800px" style="width: 500px;" class="fontNotoSans400" persistent>
    <v-card style="padding: 20px !important;"> <!--elevation : 그림자-->
        <h2 style="text-align: center" class="mb-6">매니저 등록</h2>
        <v-form action="submit" method="post" @submit.prevent="registMng" class="wageClientInfoForm">

        <div class="wageAuthForm">
          <v-text-field 
            v-if="!confirmed"
            :prepend-inner-icon="mdiLicense" 
            variant="outlined" 
            label="아이디" 
            type="text"
            v-model="form.hm_id" 
            maxlength="10" 
            :rules="idRule" 
            color="indigo" 
            autocomplete="off"
          />
          <v-text-field 
            v-if="confirmed"
            readonly
            :prepend-inner-icon="mdiLicense" 
            variant="outlined" 
            label="아이디" 
            type="text"
            v-model="form.hm_id" 
            maxlength="10" 
            :rules="idRule" 
            color="indigo" 
            autocomplete="off"
          />
          <v-btn v-if="!confirmed" @click="idCheck()">중복검사</v-btn>


          <div v-if="idLoading" class="spinner tac"></div>
        </div>
          <v-text-field 
            :prepend-inner-icon="mdiLockOutline" 
            variant="outlined" 
            label="비밀번호" 
            type="password"
            v-model="form.hm_pw" 
            maxlength="30" 
            :rules="pwRule" 
            color="indigo"
            autocomplete="off"
          />
          <v-text-field 
            :prepend-inner-icon="mdiLock" 
            variant="outlined" 
            label="비밀번호확인" 
            type="password"
            v-model="form.hm_pw_auth" 
            maxlength="30" 
            :rules="authPwRule" 
            color="indigo"
            autocomplete="off"
          />
          <v-text-field 
            :prepend-inner-icon="mdiAccountTie" 
            variant="outlined" 
            label="성명" 
            type="text"
            v-model="form.hm_name" 
            maxlength="10" 
            :rules="nameRule" 
            color="indigo"
            autocomplete="off"
          />
          <v-text-field 
            :prepend-inner-icon="mdiCellphone" 
            variant="outlined" 
            label="휴대폰" 
            type="text"
            v-model="form.hm_phone" 
            maxlength="11" 
            :rules="phoneNumberRule" 
            color="indigo"
            autocomplete="off"
          />
          <div class="wageAuthForm">

            <v-text-field v-if="form.hm_email_auth === false && seconds === 0 && !loading"
              :prepend-inner-icon="mdiEmailVariant" variant="outlined" label="이메일" hint="이메일 형식을 맞춰주세요." type="email"
              v-model="form.hm_email" maxlength="50" :rules="emailRule" color="indigo" autocomplete="off"/>

            <v-text-field v-else :prepend-inner-icon="mdiEmailVariant" variant="outlined" label="이메일"
              hint="이메일 형식을 맞춰주세요." type="email" v-model="form.hm_email" maxlength="50" :rules="emailRule"
              color="indigo" autocomplete="off" readonly />

            <v-btn v-if="seconds === 0 && !loading && !form.hm_email_auth" @click="sendWageAuthMail()">인증번호받기</v-btn>

            <v-btn v-else-if="seconds > 0 && seconds <= 270 && !loading && !form.hm_email_auth" @click="sendWageAuthMail()">재발송</v-btn>

            <div v-if="loading" class="spinner tac"></div>

          </div>


          <v-tooltip text="Tooltip" v-if="form.hm_email_auth === false && seconds !== 0">
            <template v-slot:activator="{ props }">
              <v-text-field v-bind="props" :prepend-inner-icon="mdiEmailAlert" v-model="authCodeInput" label="인증번호"
                density="compact" variant="outlined" style="padding:0 !important;" :rules="authNumRule" maxlength="6"
                color="indigo" @change="authRandomCode(authCodeInput)" />

            </template>
            <div v-if="form.hm_email_auth === false && seconds !== 0">
              <span>인증시간이 {{ minutes }}분 {{ remainingSeconds }}초 남았습니다. </span>
              <br v-if="seconds > 270" />
              <span v-if="seconds > 270">재발송은 {{ seconds - 270 }}초 후 가능합니다.</span>
            </div>

          </v-tooltip>
          <v-text-field v-else readonly :prepend-inner-icon="mdiEmailAlert" v-model="authCodeInput" label="인증번호"
            density="compact" variant="outlined" style="padding:0 !important;" :rules="authNumRule" maxlength="6"
            color="indigo" @change="authRandomCode(authCodeInput)" />
            <v-btn :disabled="LastBtnLoading" type="submit" class="clientInfoBtn">매니저 등록하기</v-btn>
            <v-btn :disabled="LastBtnLoading"  @click="dialog = false" class="cancelRegistBtn">닫기</v-btn>

        </v-form>
    </v-card>

  </v-dialog>
  <div class="text-center">
    <v-snackbar v-model="snackbar" :snacktime="snacktime">
      {{ snacktext }}
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">

import { computed,  onMounted,  onUnmounted,  reactive, ref, watch } from 'vue';
import axios from 'axios';
import store from '@/store';
import {
  mdiEmailVariant,
  mdiAccountTie,
  mdiLicense,
  mdiCellphone,
  mdiLock,
  mdiLockOutline,
  mdiEmailAlert
} from '@mdi/js'
import {
  idRule,
  pwRule,
  nameRule,
  emailRule,
  phoneNumberRule,
  authNumRule
} from '../../composables/rules';
const authPwRule = [
  (v: string) => !!v || '비밀번호가 일치하지 않습니다.',
  (v: string) => v === form.hm_pw || '비밀번호가 일치하지 않습니다.'
];
const dialog = ref(false);
const form = reactive({
  hm_id: '',
  hm_pw: '',
  hm_pw_auth: '',
  hm_name: '',
  hm_email: '',
  hm_phone: '',
  hm_role: 0,
  hm_status: 1,
  hm_email_auth: false,
});
const idLoading = ref(false);
const confirmed = ref(false);

const loading = ref(false);
const LastBtnLoading = ref(false);
const snackbar = ref(false);
const snacktext = ref(`메일이 전송되었습니다. 인증번호는 5분간 유효합니다.`);
const snacktime = ref(5000);
const randomCode = ref(generateRandomCode());
function generateRandomCode() {
  return (Math.floor(100000 + Math.random() * 900000)).toString();
}
watch(randomCode, (newValue) => {
  store.commit('setRandomCode', newValue);
});
const seconds = ref(0)
let intervalId = 0;
const startTimer = () => {
  try {
    if (seconds.value !== 0 && intervalId !== 0) {
      clearInterval(intervalId)
    }
    seconds.value = 300
    snackbar.value = true;
    intervalId = setInterval(() => {
      if (seconds.value > 0) {
        seconds.value--
      } else {
        clearInterval(intervalId)
        alert('인증번호 입력제한시간이 초과하였습니다.')
      }
    }, 1000)
  } catch {
    console.log('타이머 에러')
  }
}
const minutes = computed(() => Math.floor(seconds.value / 60))
const remainingSeconds = computed(() => seconds.value % 60)
const authCodeInput = ref('');
const authRandomCode = (code: any) => {
  if (parseInt(code) > 99999) {
    if (store.state.randomCode === authCodeInput.value) {
      clearInterval(intervalId)
      form.hm_email_auth = true;
      seconds.value = 0;
      alert('인증되었습니다.');
    }else{
      alert('인증번호가 일치하지 않습니다.')
    }
  } else {
    return;
  }
}
const sendWageAuthMail = async () => {
  if (seconds.value >= 270) {
    alert(`인증번호 재발송은 ${seconds.value - 270}초 후 가능합니다.`);
    return;
  }
  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === 'string') {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };
  const isEmailValid = emailRule.some(rule => checkAndLog(rule, form.hm_email, '올바른 이메일을 작성해주세요.'));
  if (isEmailValid) return;
  loading.value = true;
  randomCode.value = generateRandomCode();
  try {
    const response = await axios.post(`/api/sendWageAuthMail/${form.hm_email}/${randomCode.value}`);
    const result = response.data;
    if (result.success) {
      startTimer();
      return result; // 가져온 데이터를 반환
    } else {
      alert('메일 전송에 실패하였습니다. 관리자에게 문의바랍니다.')
      console.error('에러가 발생했습니다.', result);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  } finally {
    loading.value = false;
  }
};

const idCheck = async () => {
  try {
    const checkAndLog = (rule: any, value: any, ruleName: any) => {
      const ruleResult = rule(value);
      if (typeof ruleResult === 'string') {
        alert(`${ruleName}를 확인해주세요.`);
        return true;
      } else {
        return false;
      }
    };

    const isIdValid = idRule.some(rule => checkAndLog(rule, form.hm_id, '아이디'));
    if (isIdValid) return;
    
    const response = await axios.post(`/api/idCheck/${form.hm_id}`);
    const result = response.data;
    if (result.success === false) {
      const isConfirmed = window.confirm('사용가능한 아이디입니다. 사용하시겠습니까?');
      if (isConfirmed) {
        confirmed.value = true;
      }
    } else if (result.success === true) {
      alert('중복된 ID입니다.');
    } else {
      alert('아이디 중복을 처리할 수 없습니다.');
    }
  } catch (error) {
    alert('아이디 중복을 처리할 수 없습니다.');
    console.error(error);
  }
};


const registMng = async () => {
  try {
    const checkAndLog = (rule: any, value: any, ruleName: any) => {
      const ruleResult = rule(value);
      if (typeof ruleResult === 'string') {
        alert(`${ruleName} 확인해주세요.`);
        return true;
      } else {
        return false;
      }
    };

    const isIdValid = idRule.some(rule => checkAndLog(rule, form.hm_id, '아이디를'));
    if (isIdValid) return;

    const isNameValid = nameRule.some(rule => checkAndLog(rule, form.hm_name, '성명을'));
    if (isNameValid) return;

    const isPwValid = pwRule.some(rule => checkAndLog(rule, form.hm_pw, '비밀번호를'));
    if (isPwValid) return;
    const isAuthPwValid = authPwRule.some(rule => checkAndLog(rule, form.hm_pw_auth, '비밀번호가 일치하지 않습니다. 비밀번호를'));
    if (isAuthPwValid) return;
    
    const isEmailValid = emailRule.some(rule => checkAndLog(rule, form.hm_email, '이메일을'));
    if (isEmailValid) return;

    const isCpValid = phoneNumberRule.some(rule => checkAndLog(rule, form.hm_phone, '휴대폰번호를'));
    if (isCpValid) return;


    const response = await axios.post('/api/registMng', { form: form });
    const result = response.data;
    if (result.success === true) {
      alert('매니저 등록이 완료되었습니다.');
      dialog.value = false;
    } else {
      alert('매니저 등록에 실패하였습니다. 관리자에게 문의해주세요.');
    }
  } catch (error) {
    alert('매니저 등록을 처리할 수 없습니다.');
    console.error(error);
  }
};
onUnmounted(() => {
  clearInterval(intervalId);
  store.commit('setRandomCode', 'success');
});
onMounted(async () => {
  store.commit('setRandomCode', '');
  confirmed.value = false
})

</script>



<style scoped>
.spinner {
  width: 30px !important;
  margin: 0 0 18px 8px;
  display: flex;
  height: 30px !important;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>