<template>
  <v-card>
    <v-tabs
      style="align-items: center;"
      bg-color="teal-darken-3"
      slider-color="teal-lighten-3"
      show-arrows
      v-model="tab"
      height="36"
    >
      <v-tab
        v-for="item in tabInfo"
        :key="item.key"
        :value="item.value"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item
          v-for="item in tabInfo"
          :key="item.key"
          :value="item.value"
        >
          <component :is="item.component" />
        </v-tabs-window-item>
      </v-tabs-window>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import TabContent1 from '@/components/admin/services/work/schedule/tabs/TabContent1.vue';
import TabContent2 from '@/components/admin/services/work/schedule/tabs/TabContent2.vue';
import TabContent3 from '@/components/admin/services/work/schedule/tabs/TabContent3.vue';
import TabContent4 from '@/components/admin/services/work/schedule/tabs/TabContent4.vue';
import TabContent5 from '@/components/admin/services/work/schedule/tabs/TabContent5.vue';
import TabContent6 from '@/components/admin/services/work/schedule/tabs/TabContent6.vue';
import TabContent7 from '@/components/admin/services/work/schedule/tabs/TabContent7.vue';
const tab = ref('tab-1'); 
const tabInfo = [
  { key: 1, text: '월별1', value: 'tab-1', component: TabContent1 },
  { key: 2, text: '일별2', value: 'tab-2', component: TabContent2 },
  { key: 3, text: '휴가현황', value: 'tab-3', component: TabContent3 },
  { key: 4, text: '월별4', value: 'tab-4', component: TabContent4 },
  { key: 5, text: '월별5', value: 'tab-5', component: TabContent5 },
  { key: 6, text: '월별6', value: 'tab-6', component: TabContent6 },
  { key: 7, text: '월별7', value: 'tab-7', component: TabContent7 },
];
</script>
