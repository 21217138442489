<template>
    <!-- 요일 헤더 -->
    <v-row class="calendar-days" justify="space-between">
      <v-col v-for="(day, index) in daysOfWeek" :key="'day-' + index" class="day-label">
        {{ day }}
      </v-col>
    </v-row>

    <!-- 날짜 -->
    <v-row class="calendar-body" justify="start" align="start">
      <!-- 빈 칸 (이전 달의 날짜) -->
      <v-col
        v-for="(empty, index) in previousMonthDays"
        :key="'empty-' + index"
        class="calendar-cell workScheduleEmpty"
      >
        <div class="date-number previous-month">{{ empty }}</div>
      </v-col>

      <!-- 날짜 (현재 월의 날짜) -->
      <v-col
        v-for="(day, index) in daysInMonth"
        :key="'day-' + index"
        class="calendar-cell"
        @click="selectDate(day)"
      >
        <div class="date-number">{{ day }}</div>
        <div class="SsWorkDateMainSpace">
          <table>
            <thead>
              <tr>
                <th>직업\시간</th>
                <th>주간</th>
                <th>야간</th>
                <th>비번</th>
                <th>연차</th>
                <th>대체</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(s, index) in ssWorkSchedules" :key="index">
                <td>{{ s.emp_job }}</td>
                <td>{{ getScheduleTbNum(day, s.emp_job)?.D1 }}</td>
                <td>{{ getScheduleTbNum(day, s.emp_job)?.N1 }}</td>
                <td>{{ getScheduleTbNum(day, s.emp_job)?.H1 }}</td>
                <td>{{ getScheduleTbNum(day, s.emp_job)?.H2 }}</td>
                <td>{{ getScheduleTbNum(day, s.emp_job)?.B1 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>

      <!-- 빈 칸 (다음 달의 날짜) -->
      <v-col
        v-for="(empty, index) in nextMonthDays"
        :key="'next-empty-' + index"
        class="calendar-cell workScheduleEmpty"
      >
        <div class="date-number next-month">{{ empty }}</div>
      </v-col>
    </v-row>
  <v-dialog v-model="dialog" width="800px" class="fontNotoSans400">
    <ScheduleCtrl @close="dialog = false" @success="SsWorkCtrlSuccess()" />
  </v-dialog>
  <div v-if="loading" class="spinner tac"></div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import ScheduleCtrl from "@/components/admin/services/work/schedule/ScheduleCtrl.vue";

import store from "@/store";
import axios from "axios";
let code = ref(store.state.AdminSisulCode) as any;
let ym = ref(store.state.AdminYYMM) as any;

const loading = ref(false);

const SsWorkCtrlSuccess = async () => {
  await readWorkScheduleMonth();
  dialog.value = false;
};

// 오늘 날짜
const today = new Date();

let currentDate = ref(new Date(today.getFullYear(), today.getMonth(), 1));
currentDate.value = new Date(
  currentDate.value.getFullYear(),
  currentDate.value.getMonth(),
  1
);

const selectedDate = ref(null) as any;

// 요일 이름 (일요일부터 시작)
const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

// 현재 월의 일수
const daysInMonth = computed(() => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  return Array.from({ length: new Date(year, month + 1, 0).getDate() }, (_, i) => i + 1);
});
// 이전 달의 날짜 계산
const previousMonthDays = computed(() => {
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  const prevMonthLastDay = new Date(year, month, 0);
  const prevMonthDays = prevMonthLastDay.getDate();
  const prevMonthStartDay = prevMonthLastDay.getDay(); // 일요일부터 시작하므로 `getDay()`가 0일 때 일요일

  // 이전 달의 날짜가 1일에 일요일이면 이전 달의 날짜를 표시하지 않음
  if (prevMonthStartDay === 6 && currentDate.value.getDate() === 1) {
    return []; // 1일이 일요일이면 이전 달 날짜가 보이지 않도록 처리
  }
  // 그 외 경우에는 이전 달의 날짜를 정상적으로 표시
  return Array.from(
    { length: prevMonthStartDay + 1 },
    (_, i) => prevMonthDays - prevMonthStartDay + i
  );
});

// 다음 달의 날짜 계산
const nextMonthDays = computed(() => {
  const currentMonthDays = daysInMonth.value.length; // 현재 달의 일수
  const prevMonthEmptyCells = previousMonthDays.value.length; // 이전 달의 빈 칸 수

  // 현재 달의 마지막 날짜 구하기
  const year = currentDate.value.getFullYear();
  const month = currentDate.value.getMonth();
  const lastDayOfMonth = new Date(year, month + 1, 0); // 현재 달의 마지막 날짜
  const lastDayOfWeek = lastDayOfMonth.getDay(); // 마지막 날짜의 요일 (0: 일요일, 6: 토요일)
  if (lastDayOfWeek === 6) {
    return;
  }

  const totalDaysInMonth = lastDayOfMonth.getDate(); // 이번 달의 총 일수

  // 이번 달의 첫 번째 날짜의 요일 (0: 일요일, 6: 토요일)
  const firstDayOfMonth = new Date(year, month, 1);
  const firstDayOfWeek = firstDayOfMonth.getDay();
  // 첫 번째 주차는 첫 번째 날짜의 요일을 기준으로 시작하므로,
  // 주차 계산은 (총 일수 + 시작 요일) / 7로 계산
  const totalWeeks = Math.ceil((totalDaysInMonth + firstDayOfWeek) / 7);
  // totalCells 결정: 마지막 날짜가 일요일이면 42일로, 그렇지 않으면 35일로 설정
  const totalCells = totalWeeks > 5 ? 42 : 35;

  // 전체 셀에서 현재 달의 날짜 수와 이전 달의 빈 셀 수를 제외한 나머지 빈 셀 수 계산
  const nextMonthEmptyCells = totalCells - (prevMonthEmptyCells + currentMonthDays);

  // 그 외에는 빈 셀을 맞춰서 다음 달 날짜 표시
  return Array.from({ length: nextMonthEmptyCells }, (_, i) => i + 1);
});

const dialog = ref(false);

const dialogOpen = async () => {
  dialog.value = true;
};
// 날짜 선택
const selectDate = async (day: any) => {
  selectedDate.value = `d_${day}`;
  store.commit("setSsSchedule_day", selectedDate.value);
  await dialogOpen();
};
const ssWorkSchedules = ref(null) as any;
const readWorkScheduleMonth = async () => {
  try {
    loading.value = true;
    const response = await axios.get(
      `/api/readWorkScheduleMonth/${code.value}/${ym.value}`
    );
    const responseData = response.data;
    if (responseData) {
      ssWorkSchedules.value = responseData[0];
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};
function getScheduleTbNum(day: any, job: any) {
  if (!ssWorkSchedules.value || ssWorkSchedules.value.length === 0) {
    return {}; // 데이터가 없으면 빈 객체 반환
  }

  let result: any = {}; // 결과 객체 초기화

  ssWorkSchedules.value.forEach((schedule: any) => {
    if (schedule.emp_job === job) {
      const D1 = schedule[`D1_${day}`];
      const N1 = schedule[`N1_${day}`];
      const H1 = schedule[`H1_${day}`];
      const H2 = schedule[`H2_${day}`];
      const B1 = schedule[`B1_${day}`];

      // 0인 값은 제외하고 객체에 추가
      if (D1 !== "0") result.D1 = D1;
      if (N1 !== "0") result.N1 = N1;
      if (H1 !== "0") result.H1 = H1;
      if (H2 !== "0") result.H2 = H2;
      if (B1 !== "0") result.B1 = B1;
    }
  });

  return result;
}

watch(
  () => store.state.AdminYYMM,
  async (newValue) => {
    if (newValue) {
      currentDate.value = new Date(newValue);
      ym.value = store.state.AdminYYMM;
      await readWorkScheduleMonth();
    }
  }
);
onMounted(async () => {
  // SsYm.value (예: '2024-12')에서 연도와 월을 추출
  const [year, month] = store.state.AdminYYMM.split('-');
  
  // 1일 날짜 생성 (월은 0부터 시작하므로 -1을 해야 정확한 월이 됩니다)
  const date = new Date(parseInt(year), parseInt(month) - 1, 1);
  
  // currentDate.value에 해당 날짜를 할당
  currentDate.value = date;
  
  console.log(currentDate.value, 'currentDate.value');  // 원하는 형식으로 출력되는지 확인

  await readWorkScheduleMonth();
});

</script>
