<template>
  <div style="display: flex; justify-content: center; background-color: #eeeeee">
    <FormatDate />
  </div>
  <v-row>
    <div v-for="type in workTypeArr" :key="type">
      <v-col>
        {{ type.work_type }}({{ type.count }})
        <div v-for="sche in scheduleArr" :key="sche">
          <span v-if="type.work_type === sche.work_type">
            {{ sche.emp_name }}
            {{ sche.emp_job }}
          </span>
        </div>
      </v-col>
    </div>
  </v-row>
</template>
<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import FormatDate from "@/components/admin/common/datePicker/FormatDate.vue";
import axios from "axios";
import store from "@/store";
const code = ref(store.state.AdminSisulCode);
const ymd = ref(store.state.AdminDate);
const loading = ref(false);
const scheduleArr = ref([]) as any;
const workTypeArr = ref([]) as any;

const getDateSchedules = async () => {
  try {
    if (loading.value) return;
    loading.value = true;
    const response = await axios.get(`/api/getDateSchedules/${code.value}/${ymd.value}`);
    const responseData = response.data;
    scheduleArr.value = responseData.scheduleArr || [];
    workTypeArr.value = responseData.workTypeArr || [];
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};

watch(
  () => store.state.AdminDate,
  async (newValue) => {
    if (newValue) {
      ymd.value = store.state.AdminDate;
      await getDateSchedules();
    }
  }
);

onMounted(async () => {
  await getDateSchedules();
});
</script>
