<template>
  <v-sheet class="is1">
    <div class="is1_Bg">
      <div class="is1_box is_max_width">
          <b class="is1_mainMent">함께 이루는 혁신,</b>
          <br />
          <b class="is1_mainMent">함께 나누는 가치</b>
          <br />
          <b class="is1_mainMent2">Creating innovation together, building value as one.</b>
      </div>
    </div>
  </v-sheet>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
});
</script>