<template>
  <v-card class="tac leftClientCard">
    <div class="pt-3" style="width: 150px; text-align: center; margin: auto;">
      
      <v-sheet style="max-width: 600px !important; margin: auto;">
        <v-img height="150"
        src="https://demos.themeselection.com/materio-vuetify-vuejs-admin-template/demo-1/assets/avatar-3-wR29q9GN.png"
        style="border-radius: 10px;">
      </v-img>
      </v-sheet>
      
    </div>
    <v-card-title>
      <p class="fontNotoSans700" style="font-size: 17px;">
        {{ client.comp_name }}
      </p>
    </v-card-title>
    <v-card-text class="pb-3">
      <v-timeline align="start" density="compact" style="border-bottom: 2px solid #ccc;">
        <!-- <v-timeline-item v-for="mng in wageLog" :key="mng.date" :dot-color="mng.color" size="x-small"> -->
        <v-timeline-item v-for="mng in wageLog" :key="mng.date" :dot-color="mng.color" size="x-small">
          <div class="mb-4">
            <div class="font-weight-normal tal">
              {{ mng.date }}
            </div>

            <div class="tal">{{ mng.mng }}</div>
          </div>
        </v-timeline-item>
      </v-timeline>
      <div class="tal clientInfoDiv">
        <div><strong>지역: </strong><span>{{ client.sido_name }} {{ client.sigungu_name }}</span></div>
        <div><strong>사업: </strong>{{ client.comp_gubun }}</div>
        <div><strong>신청자: </strong>{{ client.client_name }}</div>
        <div><strong>휴대폰: </strong>{{ client.client_phone }}</div>
        <div><strong>이메일: </strong>{{ client.client_email }}</div>
        <div><strong>담당자: </strong>{{ client.fwa_manager }}</div>
      </div>
    </v-card-text>

  </v-card>
</template>

<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { computed, onMounted, ref, watch } from 'vue';
let client = store.state.mngOneClient;


const wageLog = ref([] as any[]);
const readWageLog = async () => {
  try {
    const response = await axios.get(`/api/readWageLog/${client.fwa_code}`);
    const responseData = response.data;
    if (responseData) {
      if (responseData.length > 1) {
        if (responseData[0]?.date === responseData[1]?.date) {
          responseData.shift();
        }else if (responseData[1]?.date === responseData[2]?.date) {
          responseData.splice(1, 1);
        }
      }
      wageLog.value = responseData;
    }
  } catch (error) {
  console.error('errorMsg:', error);
  }
}

const getFwaStatus = computed(() => {
  const getFwaStatusComputed = store.state.mngOneClient.fwa_status;
  return getFwaStatusComputed;
});

watch(getFwaStatus, async (newValue: any) => {
  if (newValue) {
    readWageLog();
  }
});

onMounted(async () => {
await readWageLog();
})

</script>

<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>