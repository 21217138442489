<template>
  <v-app id="inspire">
    <v-app-bar
      class="adminLayoutAppBar"
      style="min-width: 600px !important; padding-left: 10px; padding-right: 10px"
    >
      <v-icon class="activeClick" @click="toggleDrawer" style="margin-right: 10px">{{
        mdiFormatAlignJustify
      }}</v-icon>
      <div style="font-size: 12px !important; width: 300px; margin-left: 5px">
        <v-autocomplete
          v-model="AdminSisul"
          label="기관"
          :items="sisulList"
          variant="underlined"
          density="compact"
          hide-details="auto"
          style="max-width: 300px !important; color: #000 !important"
          class="activeClick longAutoCompleteText"
          clearable
          :prepend-inner-icon="mdiCity"
          auto-select-first
        ></v-autocomplete>
      </div>
      <div style="font-size: 12px !important; width: 170px">
        <v-select
          v-model="AdminBizGubun"
          label="사업구분"
          :items="bizGubunList"
          variant="underlined"
          density="compact"
          hide-details="auto"
          style="max-width: 260px !important"
          class="activeClick"
          :prepend-inner-icon="mdiLabelOutline"
          auto-select-first
        ></v-select>
      </div>
      <v-btn :class="{ 'glamorous-button': true, active: btnActive }" @click="changeBtn()"
        >적용</v-btn
      >

      <v-spacer></v-spacer>
      <v-btn @click="goToSisulList()">기관리스트</v-btn>
      <v-btn @click="reloadBtn()">새로고침</v-btn>

      <AdminMyInfo />
    </v-app-bar>
    <AdminNaviBar />
    <v-main>
      <div id="AdminMain">
        <slot />
      </div>
    </v-main>
    <v-footer app height="25" color="#191919" id="AdminFooter">
      <img
        src="@/assets/pictures/함우리로고.png"
      />
      <span style="width:20px;"></span>
     <span>Copyright © 2024 Hamwoori. All Rights Reserved. Design by Hamwoori</span>
    </v-footer>
  </v-app>
</template>
<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { mdiFormatAlignJustify, mdiLabelOutline, mdiCity } from "@mdi/js";
import AdminNaviBar from "./AdminNaviBar.vue";
import AdminMyInfo from "./AdminMyInfo.vue";
import store from "@/store";
import axios from "axios";
import { format } from "date-fns";
import router from "@/router";
const btnActive = ref(false);

const AdminYY = ref(null) as any;
const AdminYYMM = ref(null) as any;
const AdminStartDate = ref(null) as any;
const AdminEndDate = ref(null) as any;
const AdminDate = ref(null) as any;
const AdminSisul = ref(null) as any;
const AdminBizGubun = ref(null) as any;
const AdminSisulId = ref(null) as any;

const toggleDrawer = () => {
  store.commit("setDrawer", !store.state.drawer);
};

const sisulList = ref([]) as any;
const bizGubunList = ref([]) as any;
const serviceList = ref([]) as any;
const readSisulResult = ref([]) as any;
const today = format(new Date(), "yyyy-MM-dd");
const goToSisulList = () => {
  router.push('/AdminIndex')
}
const readSisul = async () => {
  try {
    const response = await axios.get(`/api/readSs`);
    let responseData = response.data;
    if (responseData && responseData.length > 0) {
      // 중복 제거
      readSisulResult.value = responseData.filter(
        (item: { sisul_code: any }, index: any, arr: any[]) =>
          arr.findIndex((i) => i.sisul_code === item.sisul_code) === index
      );

      // sisulList 생성
      sisulList.value = readSisulResult.value.map(
        (item: { sisul_name: any; sisul_code: any }) =>
          `${item.sisul_name}(${item.sisul_code})`
      );

      // bizGubunList 생성
      bizGubunList.value = readSisulResult.value.map(
        (item: { sisul_name: any; sisul_code: any; sisul_biz_gubun: any }) =>
          `${item.sisul_name}(${item.sisul_code})(${item.sisul_biz_gubun})`
      );

      // matchedbiz 처리
      const matchedbiz = bizGubunList.value.find((biz: string) =>
        biz.includes(AdminSisul.value)
      );
      if (matchedbiz) {
        const match = matchedbiz.match(/\(([^)]+)\)\((.*?)\)/); // 정규 표현식
        if (match && match[2]) {
          const substring = match[2]; // 두 번째 괄호 안의 내용 추출
          bizGubunList.value = substring.split("/");
          AdminBizGubun.value = substring;
        }
      }

      // readSisulResult 순회
      readSisulResult.value.forEach((item: any) => {
        const services: string[] = ["근태관리"]; // 초기값 추가

        if (
          item.sisul_wage_in <= today &&
          (!item.sisul_wage_out || item.sisul_wage_out >= today)
        ) {
          services.push("HR서비스");
        }

        if (
          item.sisul_fna_in <= today &&
          (!item.sisul_fna_out || item.sisul_fna_out >= today)
        ) {
          services.push("재무회계");
        }

        if (
          item.sisul_meal_in <= today &&
          (!item.sisul_meal_out || item.sisul_meal_out >= today)
        ) {
          services.push("위탁급식");
        }

        item.service = services.join("/"); // 슬래시로 연결
      });

      // serviceList 생성
      serviceList.value = readSisulResult.value.map(
        (item: {
          sisul_name: any;
          sisul_code: any;
          sisul_biz_gubun: any;
          service: any;
        }) =>
          `${item.sisul_name}(${item.sisul_code})(${item.sisul_biz_gubun})(${item.service})`
      );

      // matchedService 처리
      const matchedService = serviceList.value.find((service: string) =>
        service.includes(`${AdminSisul.value}(${AdminBizGubun.value})`)
      );
      if (matchedService) {
        const match = matchedService.match(/\(([^)]+)\)\((.*?)\)\((.*?)\)/); // 정규 표현식
        if (match && match[3]) {
          const substring = match[3]; // 세 번째 괄호 안의 내용 추출
          serviceList.value = substring.split("/");
        }
      }

      return responseData;
    } else {
      console.error("responseData가 비어 있습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};

watch(AdminSisul, () => {
  // bizGubunList 생성
  bizGubunList.value = readSisulResult.value.map(
    (item: { sisul_name: any; sisul_code: any; sisul_biz_gubun: any; sisul_id: any }) =>
      `${item.sisul_name}(${item.sisul_code})(${item.sisul_biz_gubun})(${item.sisul_id})`
  );

  // matchedbiz 처리
  const matchedbiz = bizGubunList.value.find((biz: string) =>
    biz.includes(AdminSisul.value)
  );
  if (matchedbiz) {
    const match = matchedbiz.match(/\(([^)]+)\)\((.*?)\)\((.*?)\)/); // 정규 표현식
    if (match && match[2]) {
      const bizGubunSubstring = match[2]; // 두 번째 괄호 안의 내용 추출 (sisul_biz_gubun)
      bizGubunList.value = bizGubunSubstring.split("/");
      AdminBizGubun.value = bizGubunSubstring;
    }
    if (match && match[3]) {
      AdminSisulId.value = match[3]; // 세 번째 괄호 안의 내용 추출 (sisul_id)
    }
  }

  // serviceList 생성
  serviceList.value = readSisulResult.value.map(
    (item: { sisul_name: any; sisul_code: any; sisul_biz_gubun: any; service: any; sisul_id: any }) =>
      `${item.sisul_name}(${item.sisul_code})(${item.sisul_biz_gubun})(${item.service})(${item.sisul_id})`
  );

  // matchedService 처리
  const matchedService = serviceList.value.find((service: string) =>
    service.includes(`${AdminSisul.value}(${AdminBizGubun.value})`)
  );
  if (matchedService) {
    const match = matchedService.match(/\(([^)]+)\)\((.*?)\)\((.*?)\)\((.*?)\)/); // 정규 표현식
    if (match && match[3]) {
      const serviceSubstring = match[3]; // 세 번째 괄호 안의 내용 추출 (service)
      serviceList.value = serviceSubstring.split("/");
    }
    if (match && match[4]) {
      AdminSisulId.value = match[4]; // 네 번째 괄호 안의 내용 추출 (sisul_id)
    }
  }

  if (AdminSisul.value === null || AdminSisul.value === "") {
    // indexpage에서 기관 X 했을 때, 리스트 초기화하는 핵심 커밋
    AdminSisul.value = "";
    AdminBizGubun.value = "";
    AdminSisulId.value = "";
  }
});

watch([AdminSisul, AdminBizGubun], async () => {
  if (
    AdminSisul.value === store.state.AdminSisul &&
    AdminBizGubun.value === store.state.AdminBizGubun
  ) {
    btnActive.value = false;
  } else {
    btnActive.value = true;
  }
});

const changeBtn = async () => {
  if (btnActive.value) {
    store.commit("setAdminSisul", AdminSisul.value);
    store.commit("setAdminSisulCode", AdminSisul.value?.match(/\(([^)]+)\)/)[1]);
    store.commit("setAdminSisulName", AdminSisul.value?.replace(/\(.*\)$/, ""));
    store.commit("setAdminBizGubun", AdminBizGubun.value);
    store.commit("setAdminSisulId", AdminSisulId.value);
    store.commit("setAdminService", serviceList.value);
    store.commit('setDrawer', true);
  }
  btnActive.value = false;
  reloadBtn();
};

const reloadBtn = () => {
  location.reload(); // 페이지 새로고침
};
onMounted(async () => {
  AdminYY.value = store.state.AdminYY;
  AdminYYMM.value = store.state.AdminYYMM;
  AdminStartDate.value = store.state.AdminStartDate;
  AdminEndDate.value = store.state.AdminEndDate;
  AdminDate.value = store.state.AdminDate;
  AdminSisul.value = store.state.AdminSisul;
  AdminBizGubun.value = store.state.AdminBizGubun;
  AdminSisulId.value = store.state.AdminSisulId;

  await readSisul();
  btnActive.value = false;
});
</script>
