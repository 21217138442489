<template>
  <v-sheet class="empContractSheet">
    <div class="empContractSheetTitle">
      <b class="empContractSheetTitleMent">임금메이커</b>
    </div>

    <div class="contractMainYearWrapper">
      <div class="contractMainYear">
        <label>기준 연도</label>
        <v-select
          v-model="year"
          :items="yearOptions"
          @update:modelValue="updateWorkDays"
          hideDetails="auto"
          density="compact"
          variant="outlined"
          control-variant="hidden"
        />
      </div>
      <div class="contractMainYear">
        <label>기준 월</label>
        <v-select
          v-model="month"
          :items="monthOptions"
          hideDetails="auto"
          @update:modelValue="updateWorkDays"
          density="compact"
          variant="outlined"
          control-variant="hidden"
        />
      </div>

      <div class="contractMainYear">
        <label>시급</label>
        <v-number-input
          v-model="timePay"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="120px"
          density="compact"
        />
      </div>

      <div class="contractMainYear">
        <label>기본근로(h)</label>
        <v-number-input
          v-model="basicWorkTime"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="110px"
          density="compact"
        />
      </div>

      <div class="contractMainYear">
        <label>연장근로(h)</label>
        <v-number-input
          v-model="overWorkTime"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="110px"
          density="compact"
        />
      </div>

      <div class="contractMainYear">
        <label>연장근로(d)</label>
        <v-number-input
          v-model="overWorkDaysPercent"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="110px"
          density="compact"
        >
          <template v-slot:append-inner>
            <span>%</span>
          </template>
        </v-number-input>
      </div>

      <div class="contractMainYear">
        <label>야간근로(h)</label>
        <v-number-input
          v-model="nightWorkTime"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="110px"
          density="compact"
        />
      </div>
      <div class="contractMainYear">
        <label>야간근로(d)</label>
        <v-number-input
          v-model="nightWorkDaysPercent"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="110px"
          density="compact"
        >
          <template v-slot:append-inner>
            <span>%</span>
          </template>
        </v-number-input>
      </div>
    </div>
    <div class="contractMainYearWrapper">
      <div class="contractMainYear">
        <label>중식대</label>
        <v-number-input
          v-model="lunchPay"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="120px"
          density="compact"
        />
      </div>
      <div class="contractMainYear">
        <label>차량비</label>
        <v-number-input
          v-model="carPay"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="120px"
          density="compact"
        />
      </div>
      <div class="contractMainYear">
        <label>양육비</label>
        <v-number-input
          v-model="childPay"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="120px"
          density="compact"
        />
      </div>
      <div class="contractMainYear">
        <label>보존수당</label>
        <v-number-input
          v-model="bojonPay"
          controlVariant="stacked"
          hideDetails="auto"
          variant="outlined"
          width="120px"
          density="compact"
        />
      </div>
    </div>

    <table class="workCalcTable_empContract tac">
      <thead>
        <tr>
          <th rowspan="2">구분</th>
          <th colspan="6">일수</th>
          <th colspan="3">근로시간</th>
          <th colspan="8">급여</th>
        </tr>
        <tr>
          <th>월간</th>
          <th>근로</th>
          <th>토</th>
          <th>일</th>
          <th>소계</th>
          <th>공휴일</th>
          <th>기본({{ basicWorkTime || 0 }}h)</th>
          <th>연장({{ overWorkTime || 0 }}h)</th>
          <th>야간({{ nightWorkTime || 0 }}h)</th>
          <th>기본급</th>
          <th>연장수당</th>
          <th>야간수당</th>
          <th>보존수당</th>
          <th>중식대</th>
          <th>차랑비</th>
          <th>양육비</th>
          <th>급여총액</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in wageLists" :key="index">
          <td>{{ row.year }}년 {{ row.month }}월</td>
          <td>{{ row.totalDays }}</td>
          <td>{{ row.onlyBlackDays }}</td>
          <td>{{ row.saturdayCount }}</td>
          <td>{{ row.sundayCount }}</td>
          <td>{{ row.saturdayCount + row.sundayCount }}</td>
          <td>{{ row.holidayCount }}</td>
          <td>{{ row.basicTimeMonth }}</td>
          <td>{{ row.overTimeMonth }}</td>
          <td>{{ row.nightTimeMonth }}</td>
          <td class="tar">{{ row.basicPay }}</td>
          <td class="tar">{{ row.overPay }}</td>
          <td class="tar">{{ row.nightPay }}</td>
          <td class="tar">{{ ac(bojonPay) || 0 }}</td>
          <td class="tar">{{ ac(lunchPay) || 0 }}</td>
          <td class="tar">{{ ac(carPay) || 0 }}</td>
          <td class="tar">{{ ac(childPay) || 0 }}</td>
          <td class="tar">{{ row.totalPay }}</td>
        </tr>
        <tr class="empContractSumResultsTr">
          <td>{{ sumResults.month }}</td>
          <td>{{ sumResults.totalDays }}</td>
          <td>{{ sumResults.onlyBlackDays }}</td>
          <td>{{ sumResults.saturdayCount }}</td>
          <td>{{ sumResults.sundayCount }}</td>
          <td>{{ sumResults.weekendCount }}</td>
          <td>{{ sumResults.holidayCount }}</td>
          <td>{{ sumResults.basicTimeMonth }}</td>
          <td>{{ sumResults.overTimeMonth }}</td>
          <td>{{ sumResults.nightTimeMonth }}</td>
          <td class="tar">{{ sumResults.basicPay }}</td>
          <td class="tar">{{ sumResults.overPay }}</td>
          <td class="tar">{{ sumResults.nightPay }}</td>
          <td class="tar">{{ ac(bojonPay * 12) || 0 }}</td>
          <td class="tar">{{ ac(lunchPay * 12) || 0 }}</td>
          <td class="tar">{{ ac(carPay * 12) || 0 }}</td>
          <td class="tar">{{ ac(childPay * 12) || 0 }}</td>
          <td class="tar">{{ sumResults.totalPay }}</td>
        </tr>
        <tr class="empContractAvgResultsTr">
          <td>{{ avgResults.month }}</td>
          <td>{{ avgResults.totalDays }}</td>
          <td>{{ avgResults.onlyBlackDays }}</td>
          <td>{{ avgResults.saturdayCount }}</td>
          <td>{{ avgResults.sundayCount }}</td>
          <td>{{ avgResults.weekendCount }}</td>
          <td>{{ avgResults.holidayCount }}</td>
          <td>{{ avgResults.basicTimeMonth }}</td>
          <td>{{ avgResults.overTimeMonth }}</td>
          <td>{{ avgResults.nightTimeMonth }}</td>
          <td class="tar">{{ avgResults.basicPay }}</td>
          <td class="tar">{{ avgResults.overPay }}</td>
          <td class="tar">{{ avgResults.nightPay }}</td>
          <td class="tar">{{ ac(bojonPay) || 0 }}</td>
          <td class="tar">{{ ac(lunchPay) || 0 }}</td>
          <td class="tar">{{ ac(carPay) || 0 }}</td>
          <td class="tar">{{ ac(childPay) || 0 }}</td>
          <td class="tar">{{ avgResults.totalPay }}</td>
        </tr>
      </tbody>
    </table>
  </v-sheet>
  <v-sheet class="empContractSheet">
    <div class="empContractSheetTitle">
      <b class="empContractSheetTitleMent">임금계약표</b>
      <span>기준:</span><b class="empContractSheetDate">{{ refYearMonth }}</b>
    </div>

    <div class="empListCtrlBtn_empContract">
      <v-btn @click="saveEmpWageContract" color="#fff" elevation="0">저장하기</v-btn>
      <v-btn @click="wageInput" color="#fff" elevation="0">임금가져오기</v-btn>
      <v-btn @click="wageOutput" color="#fff" elevation="0">임금내보내기</v-btn>
      <v-btn @click="getEmpContractInfoForBtn" color="#fff" elevation="0">초기화</v-btn>
    </div>
    <table class="tac empListTable_empContract">
      <thead>
        <tr>
          <th class="empCtCheckTd" rowspan="2">
            <input type="checkbox" v-model="selectAll" @change="toggleAll" />
          </th>
          <th rowspan="2">번호</th>
          <th colspan="2">사원</th>
          <th colspan="2">계약시점</th>
          <th colspan="3">근로시간</th>
          <th colspan="9">급여</th>
        </tr>
        <tr>
          <th>이름</th>
          <th>직종</th>
          <th>연도</th>
          <th>월</th>
          <th>기본</th>
          <th>연장</th>
          <th>야간</th>
          <th>시급</th>
          <th>기본급</th>
          <th>연장수당</th>
          <th>야간수당</th>
          <th>보존수당</th>
          <th>중식대</th>
          <th>차랑비</th>
          <th>양육비</th>
          <th>급여총액</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in empList" :key="index" class="empContractListTr">
          <td class="empCtCheckTd"><input type="checkbox" v-model="row.checked" /></td>
          <td class="empCtNumTd">{{ index + 1 }}</td>
          <td class="empCtTd">{{ row.emp_name }}</td>
          <td class="empCtTd">{{ row.emp_job }}</td>
          <td class="empCtTd">{{ row.ct_start_year || "" }}</td>
          <td class="empCtTd">{{ row.ct_start_month || "" }}</td>
          <td class="empCtTd">{{ row.ct_basic_work_time_month || 0 }}</td>
          <td class="empCtTd">{{ row.ct_over_work_time_month || 0 }}</td>
          <td class="empCtTd">{{ row.ct_night_work_time_month || 0 }}</td>
          <td class="empCtMoneyTd">{{ ac(row.ct_time_pay) || 0 }}</td>
          <td class="empCtMoneyTd">{{ row.ct_basic_pay || 0 }}</td>
          <td class="empCtMoneyTd">{{ row.ct_over_pay || 0 }}</td>
          <td class="empCtMoneyTd">{{ row.ct_night_pay || 0 }}</td>
          <td class="empCtMoneyTd">{{ ac(row.ct_bojon_pay) || 0 }}</td>
          <td class="empCtMoneyTd">{{ ac(row.ct_lunch_pay) || 0 }}</td>
          <td class="empCtMoneyTd">{{ ac(row.ct_car_pay) || 0 }}</td>
          <td class="empCtMoneyTd">{{ ac(row.ct_child_pay) || 0 }}</td>
          <td class="empCtMoneyTd">{{ row.ct_total_pay || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </v-sheet>
  <div style="height: 80px"></div>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref, watch, onMounted } from "vue";
import store from "@/store";

const sisulCode = ref("");

const endYear = ref(0);
const endMonth = ref(0);
const year = ref(0);
const month = ref(1);
const yearOptions = ref([]) as any;
const monthOptions = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) as any;
const wageLists = ref([]) as any;
const timePay = ref(0);
const basicWorkTime = ref(8);
const overWorkTime = ref(1);
const overWorkDaysPercent = ref(50);
const nightWorkTime = ref(4.5) || 0;
const nightWorkDaysPercent = ref(50);

const bojonPay = ref(0) as any;
const lunchPay = ref(0) as any;
const carPay = ref(0) as any;
const childPay = ref(0) as any;

//교대인 경우, 주간:야간 비율로 연장근로
const empList = ref([]) as any;

const selectAll = ref(false);
const refYearMonth = ref(null) as any;

const toggleAll = () => {
  const isAllChecked = empList.value.every((row: { checked: any }) => row.checked);
  selectAll.value = !isAllChecked;
  empList.value.forEach((row: { checked: any }) => {
    row.checked = selectAll.value;
  });
};

// 개별 체크박스가 변경될 때 전체 선택 상태 업데이트
watch(
  empList,
  (newVal) => {
    selectAll.value = newVal.every((row: { checked: any }) => row.checked);
  },
  { deep: true }
);

function ac(number: any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return 0;
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}
function nc(value: any) {
  // value가 null 또는 undefined인 경우 빈 문자열로 처리
  if (value == null) {
    return 0; // 기본값을 0으로 설정하거나 다른 적합한 값으로 설정
  }

  // 컴마를 제거하고 숫자로 변환
  return parseFloat(value.toString().replace(/,/g, ""));
}

const wageInput = () => {
  // 가져오기
  const checkedRows = empList.value.filter((row: { checked: any }) => row.checked);

  // 체크된 항목이 없다면 경고 메시지 출력
  if (checkedRows.length === 0) {
    alert("사원을 체크해주세요.");
    return; // 체크된 항목이 없으면 함수 종료
  }

  // "정말 임금 데이터를 가져오시겠습니까?" 확인 창
  const isConfirmed = window.confirm(
    "임금메이커에서 임금데이터를 [ 가져오기 ] 하시겠습니까?"
  );

  if (isConfirmed) {
    // 확인을 클릭했을 때 실행되는 로직
    empList.value = empList.value.map((row: { checked: any }) => {
      if (row.checked) {
        return {
          ...row,
          ct_start_year: year.value,
          ct_start_month: month.value,
          ct_end_year: endYear.value,
          ct_end_month: endMonth.value,
          ct_time_pay: timePay.value,
          ct_basic_work_time: basicWorkTime.value,
          ct_over_work_time: overWorkTime.value,
          ct_night_work_time: nightWorkTime.value,
          ct_over_work_days_percent: overWorkDaysPercent.value,
          ct_night_work_days_percent: nightWorkDaysPercent.value,

          ct_basic_work_time_month: avgResults.value.basicTimeMonth,
          ct_over_work_time_month: avgResults.value.overTimeMonth,
          ct_night_work_time_month: avgResults.value.nightTimeMonth,

          ct_basic_pay: avgResults.value.basicPay,
          ct_over_pay: avgResults.value.overPay,
          ct_night_pay: avgResults.value.nightPay,

          ct_bojon_pay: bojonPay.value,
          ct_lunch_pay: lunchPay.value,
          ct_car_pay: carPay.value,
          ct_child_pay: childPay.value,

          ct_total_pay: avgResults.value.totalPay,

          mng_id: store.getters.id,
          sisul_code: store.state.AdminSisulCode,
        };
      }
      return row; // 체크 안 된 항목은 그대로 둠
    });

    console.log(empList.value); // 원본 empList 유지 + checked가 true인 항목만 수정
  } else {
    // 취소를 클릭했을 때 처리 (필요한 경우)
    console.log("임금 데이터 가져오기 취소");
  }
};

const wageOutput = () => {
  // 임금내보내기
  const checkedRows = empList.value.filter((row: { checked: any }) => row.checked);

  // 체크된 항목이 1개 초과인 경우 경고 메시지 출력
  if (checkedRows.length > 1) {
    alert("하나만 체크해주세요.");
    return; // 1개 초과일 경우 함수 종료
  }
  if (checkedRows.length === 1) {
    const isConfirmed = window.confirm(
      "체크된 사원의 임금데이터를 임금메이커로 [ 내보내기 ] 하시겠습니까?"
    );
    if (isConfirmed) {
      const selectedRow = checkedRows[0];
      year.value = selectedRow.ct_start_year;
      month.value = selectedRow.ct_start_month;
      timePay.value = selectedRow.ct_time_pay;
      basicWorkTime.value = selectedRow.ct_basic_work_time;
      overWorkTime.value = selectedRow.ct_over_work_time;
      nightWorkTime.value = selectedRow.ct_night_work_time;
      overWorkDaysPercent.value = selectedRow.ct_over_work_days_percent;
      nightWorkDaysPercent.value = selectedRow.ct_night_work_days_percent;
      bojonPay.value = selectedRow.ct_bojon_pay;
      lunchPay.value = selectedRow.ct_lunch_pay;
      carPay.value = selectedRow.ct_car_pay;
      childPay.value = selectedRow.ct_child_pay;
    }
  } else {
    alert("체크된 사원이 없습니다.");
  }
};

const updateWorkDays = async () => {
  await getMonthHolidays();
  let allWorkDays = getWorkDays(year.value, month.value);
  if (year.value >= 2025) {
    timePay.value = 10030;
  } else {
    timePay.value = 9860;
  }
  // 순서대로 합치기
  wageLists.value = allWorkDays;
};

const updatePays = async () => {
  let allWorkDays = getWorkDays(year.value, month.value);
  wageLists.value = allWorkDays;
};

watch(year, updateWorkDays);
watch(month, updateWorkDays);
watch(timePay, updatePays);
watch(basicWorkTime, updatePays);
watch(overWorkTime, updatePays);
watch(nightWorkTime, updatePays);
watch(overWorkDaysPercent, updatePays);
watch(nightWorkDaysPercent, updatePays);
watch(bojonPay, updatePays);
watch(lunchPay, updatePays);
watch(carPay, updatePays);
watch(childPay, updatePays);


const holidays = ref([]) as any;
const sumResults = ref([]) as any;
const avgResults = ref([]) as any;
const getWorkDays = (year: number, month: number) => {
  let results = [];
  let totals = {
    totalDays: 0,
    onlyBlackDays: 0,
    saturdayCount: 0,
    sundayCount: 0,
    holidayCount: 0,
    basicTimeMonth: 0,
    overTimeMonth: 0,
    nightTimeMonth: 0,
    basicPay: 0,
    overPay: 0,
    nightPay: 0,
    totalPay: 0,
  };

  // 12개월 동안 반복
  for (let i = 0; i < 12; i++) {
    let currentYear = year;
    let currentMonth = month + i;

    // 월이 12를 초과할 경우 연도를 증가시키고, 월을 1월로 리셋
    if (currentMonth > 12) {
      currentYear++;
      currentMonth = currentMonth - 12;
    }

    let totalDays = new Date(currentYear, currentMonth, 0).getDate();
    let firstDayOfWeek = new Date(currentYear, currentMonth - 1, 1).getDay();

    let saturdayCount = 0;
    let sundayCount = 0;
    let holidayCount = 0;

    let weekFullyStarted = true; // 기본적으로 모든 주 포함

    // 1월: 첫 주가 월요일로 시작하지 않으면 토/일 제외
    if (i === 0 && firstDayOfWeek !== 1) {
      weekFullyStarted = false;
    }

    for (let day = 1; day <= totalDays; day++) {
      let date = new Date(currentYear, currentMonth - 1, day);
      let dayOfWeek = date.getDay();
      let formattedDate = `${String(currentMonth).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;

      // 첫 주 제외 로직
      if (!weekFullyStarted && (dayOfWeek === 6 || dayOfWeek === 0)) {
        continue;
      }

      if (dayOfWeek === 6) saturdayCount++;
      if (dayOfWeek === 0) sundayCount++;
      if (holidays.value.includes(formattedDate)) holidayCount++;

      if (dayOfWeek === 5) {
        weekFullyStarted = true;
      }
    }

    let onlyBlackDays = totalDays - (saturdayCount + sundayCount + holidayCount) || 0; // 근로일 계산
    let basicWorkDays = onlyBlackDays + sundayCount + holidayCount || 0; // 기본근로일 계산

    let basicTimeMonth =
      parseFloat((basicWorkDays * basicWorkTime.value).toFixed(2)) || 0;
    let overTimeMonth =
      parseFloat(
        (
          ((onlyBlackDays * overWorkDaysPercent.value) / 100) *
          overWorkTime.value
        ).toFixed(2)
      ) || 0;
    let nightTimeMonth =
      parseFloat(
        (
          ((onlyBlackDays * nightWorkDaysPercent.value) / 100) *
          nightWorkTime.value
        ).toFixed(2)
      ) || 0;

    let basicPay =
      ac(Math.ceil(basicWorkDays * basicWorkTime.value * timePay.value)) || 0;
    let overPay = ac(Math.ceil(overTimeMonth * timePay.value * 1.5)) || 0;
    let nightPay = ac(Math.ceil(nightTimeMonth * timePay.value * 0.5)) || 0;
    let bojonPay_format = bojonPay.value || 0;
    let lunchPay_format = lunchPay.value || 0;
    let carPay_format = carPay.value || 0;
    let childPay_format = childPay.value || 0;

    let totalPay =
      ac(
        nc(basicPay) +
          nc(overPay) +
          nc(nightPay) +
          bojonPay_format +
          lunchPay_format +
          carPay_format +
          childPay_format
      ) || 0;

    if (i === 11) {
      endYear.value = currentYear;
      endMonth.value = currentMonth;
    }
    // 해당 월의 결과 저장
    results.push({
      year: currentYear,
      month: currentMonth,
      totalDays,
      onlyBlackDays: onlyBlackDays,
      saturdayCount,
      sundayCount,
      weekendCount: saturdayCount + sundayCount,
      holidayCount,
      basicTimeMonth,
      overTimeMonth,
      nightTimeMonth,
      basicPay,
      overPay,
      nightPay,
      totalPay,
    });

    // 합산
    totals.totalDays += totalDays || 0;
    totals.onlyBlackDays += onlyBlackDays || 0;
    totals.saturdayCount += saturdayCount || 0;
    totals.sundayCount += sundayCount || 0;
    totals.holidayCount += holidayCount || 0;
    totals.basicTimeMonth += basicTimeMonth || 0;
    totals.overTimeMonth += overTimeMonth || 0;
    totals.nightTimeMonth += nightTimeMonth || 0;
  }
  let sumBasicPay = Math.ceil(totals.basicTimeMonth * timePay.value);
  let sumOverPay = Math.ceil(totals.overTimeMonth * timePay.value * 1.5);
  let sumNightPay = Math.ceil(totals.nightTimeMonth * timePay.value * 0.5);

  // 합계 추가
  sumResults.value = {
    month: "합계",
    totalDays: totals.totalDays || 0,
    onlyBlackDays: totals.onlyBlackDays || 0,
    saturdayCount: totals.saturdayCount || 0,
    sundayCount: totals.sundayCount || 0,
    weekendCount: totals.saturdayCount + totals.sundayCount || 0,
    holidayCount: totals.holidayCount || 0,
    basicTimeMonth: totals.basicTimeMonth.toFixed(2) || 0,
    overTimeMonth: totals.overTimeMonth.toFixed(2) || 0,
    nightTimeMonth: totals.nightTimeMonth.toFixed(2) || 0,
    basicPay: ac(sumBasicPay) || 0,
    overPay: ac(sumOverPay) || 0,
    nightPay: ac(sumNightPay) || 0,
    totalPay: ac(nc(sumBasicPay) + nc(sumOverPay) + nc(sumNightPay)) || 0,
  };
  // 평균 추가 (반올림)

  let avgBasicTimeMonth = parseFloat((totals.basicTimeMonth / 12).toFixed(2));
  let avgOverTimeMonth = parseFloat((totals.overTimeMonth / 12).toFixed(2));
  let avgNightTimeMonth = parseFloat((totals.nightTimeMonth / 12).toFixed(2));

  if (avgBasicTimeMonth >= 209.1 && avgBasicTimeMonth <= 209.9) {
    // 209.1~209.9 사이일 경우 소수점 절삭 (내림)
    avgBasicTimeMonth = Math.floor(avgBasicTimeMonth);
  } else if (avgBasicTimeMonth >= 208.1 && avgBasicTimeMonth <= 208.9) {
    // 208.1~208.9 사이일 경우 올림
    avgBasicTimeMonth = Math.ceil(avgBasicTimeMonth);
  } else {
    // 그 외의 경우는 반올림
    avgBasicTimeMonth = Math.round(avgBasicTimeMonth);
  }

  let avgBasicPay = Math.ceil(avgBasicTimeMonth * timePay.value);
  let avgOverPay = Math.ceil(avgOverTimeMonth * timePay.value * 1.5);
  let avgNightPay = Math.ceil(avgNightTimeMonth * timePay.value * 0.5);

  let bojonPay_format = bojonPay.value || 0;
  let lunchPay_format = lunchPay.value || 0;
  let carPay_format = carPay.value || 0;
  let childPay_format = childPay.value || 0;

  avgResults.value = {
    month: "평균",
    totalDays: (totals.totalDays / 12).toFixed(2) || 0,
    onlyBlackDays: (totals.onlyBlackDays / 12).toFixed(2) || 0,
    saturdayCount: (totals.saturdayCount / 12).toFixed(2) || 0,
    sundayCount: (totals.sundayCount / 12).toFixed(2) || 0,
    weekendCount: ((totals.saturdayCount + totals.sundayCount) / 12).toFixed(2) || 0,
    holidayCount: (totals.holidayCount / 12).toFixed(2) || 0,

    basicTimeMonth: avgBasicTimeMonth || 0,
    overTimeMonth: avgOverTimeMonth || 0,
    nightTimeMonth: avgNightTimeMonth || 0,

    basicPay: ac(avgBasicPay) || 0,
    overPay: ac(avgOverPay) || 0,
    nightPay: ac(avgNightPay) || 0,

    totalPay:
      ac(
        avgBasicPay +
          avgOverPay +
          avgNightPay +
          bojonPay_format +
          lunchPay_format +
          carPay_format +
          childPay_format
      ) || 0,
  };
  // 수당은 모두 반올림 고정,
  return results;
};
const getYears = async () => {
  try {
    const response = await axios.get("/api/getYears");
    if (response.data) {
      yearOptions.value = response.data;
      const currentYear = new Date().getFullYear();
      year.value = currentYear;
    } else {
      console.error("연도 정보를 불러오는 데 실패했습니다.");
    }
  } catch (error) {
    console.error("에러 발생:", error);
  } finally {
    await updateWorkDays();
  }
};
const getMonthHolidays = async () => {
  try {
    const response = await axios.get(
      `/api/getMonthHolidays/${year.value}/${month.value}`
    );
    if (response.data) {
      holidays.value = response.data;
    } else {
      console.error("공휴일정보를 불러오는 데 실패했습니다.");
    }
  } catch (error) {
    console.error("에러 발생:", error);
  }
};
const getEmpContractInfo = async () => {
  try {
    const response = await axios.get(
      `/api/getEmpContractInfo/${sisulCode.value}/${refYearMonth.value}`
    );
    if (response.data) {
      empList.value = response.data;
    } else {
      console.error("사원정보 불러오기에 실패하였습니다.");
    }
  } catch (error) {
    console.error("에러 발생:", error);
  }
};
const getEmpContractInfoForBtn = async () => {
  const isConfirmed = window.confirm("임금 가져오기 전의 리스트로 돌아갑니다.");
  if (isConfirmed) {
    try {
      const response = await axios.get(
        `/api/getEmpContractInfo/${sisulCode.value}/${refYearMonth.value}`
      );
      if (response.data) {
        empList.value = response.data;
      } else {
        console.error("사원정보 불러오기에 실패하였습니다.");
      }
    } catch (error) {
      console.error("에러 발생:", error);
    }
  }else{
    return;
  }
};

const saveEmpWageContract = async () => {
  // 가져오기
  const checkedRows = empList.value.filter((row: { checked: any }) => row.checked);

  // 체크된 항목이 없다면 경고 메시지 출력
  if (checkedRows.length === 0) {
    alert("사원을 체크해주세요.");
    return; // 체크된 항목이 없으면 함수 종료
  }

  // "정말 임금 데이터를 가져오시겠습니까?" 확인 창
  const isConfirmed = window.confirm("체크한 사원의 임금계약 정보를 저장하시겠습니까?");

  if (isConfirmed) {
    try {
      const response = await axios.post("/api/saveEmpWageContract", {
        form: checkedRows,
      });
      const result = response.data;
      if (result.success) {
        console.log(result, "result");
        alert("성공적으로 저장되었습니다.");
      } else {
        alert("실패하였습니다. 관리자에게 문의해주세요.");
      }
    } catch (error) {
      alert("처리할 수 없습니다.");
      console.error(error);
    }
  } else {
    return;
  }
};

onMounted(async () => {
  sisulCode.value = store.state.AdminSisulCode;
  const currentDate = new Date();
  refYearMonth.value =
    currentDate.getFullYear() + "년 " + (currentDate.getMonth() + 1) + "월";
  await getYears();
  await getEmpContractInfo();
});
</script>
