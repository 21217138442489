<template>
  <div style="display: flex; justify-content: center; background-color: #eeeeee;">
    <FormatMonth/>
  </div>
  <div style="display: relative">
    <v-btn
      :disabled="saveBtnLoading"
      :prepend-icon="mdiContentSaveOutline"
      @click="saveScheduleList"
      class="btnColorWhite scheduleListSaveBtn"
      >저장</v-btn
    >
    <v-btn
      :prepend-icon="mdiFilter"
      @click="dialog = true"
      class="btnColorWhite scheduleListFilterBtn"
      >필터</v-btn
    >
    <v-btn @click="autoFillInputs" class="btnColorWhite scheduleListTestBtn"
      >Auto - schedule</v-btn
    >

    <!-- 데이터 테이블 -->
    <v-data-table
      v-if="!loading && filteredWorkSchedules.length > 0"
      :headers="filteredHeaders"
      :items="filteredWorkSchedules"
      item-value="name"
      no-data-text="데이터가 존재하지 않습니다."
      fixed-header
      density="compact"
      scrollable
      class="scheduleListTb"
      :items-per-page="-1"
      :items-per-page-options="[
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
        { value: 50, title: '50' },
      ]"
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="tac">
            <span>{{ index + 1 }}.</span>
          </td>
          <td class="tac">{{ item.emp_name }}</td>
          <td class="tac">{{ item.emp_job }}</td>
          <!-- 주야비연 -->
          <td class="tac">{{ item.calcD }}</td>
          <td class="tac">{{ item.calcN }}</td>
          <td class="tac">{{ item.calcB }}</td>
          <td class="tac">{{ item.calcH }}</td>
          <!-- 날짜별 컬럼 출력 -->
          <template v-for="(header, index) in filteredHeaders.slice(7)" :key="index">
            <td
              class="tac"
              :style="{
                'min-width': '3rem',
                borderBottom: `2px solid ${getDayColor(header.key)}`,
              }"
            >
              {{ item[header.key] }}
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </div>
  <!-- 로딩 스피너 -->
  <div v-if="loading">
    <AdminLoadingAction />
  </div>

  <v-dialog
    v-model="dialog"
    height="800px"
    width="500px"
    class="fontNotoSans400"
    persistent
  >
    <v-card>
      <v-card-title class="tac" style="padding: 10px 0">
        <b style="margin-left: 109px">직원검색필터</b>
        <v-btn
          style="float: right; margin-right: 10px"
          class="btnColorWhite"
          @click="allSelectDie()"
          >전체 체크해제</v-btn
        >
      </v-card-title>
      <v-card-actions>
        <v-sheet>
          <div v-for="job in jobOptions" :key="job.value" class="inline-div">
            <v-checkbox
              class="tal"
              :label="job.label"
              v-model="selectJob"
              :value="job.value"
              hide-details
            />
          </div>
        </v-sheet>
      </v-card-actions>
      <v-btn @click="dialog = false" class="cancelRegistBtn" style="border-radius: 0"
        >닫기</v-btn
      >
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import FormatMonth from "@/components/admin/common/datePicker/FormatMonth.vue";
import axios from "axios";
import store from "@/store";
import { getDaysInMonth, parse, getDay } from "date-fns";
import AdminLoadingAction from "@/components/admin/layout/AdminLoadingAction.vue";
import { mdiFilter, mdiContentSaveOutline } from "@mdi/js";
const saveBtnLoading = ref(false);
const dialog = ref(false);
const selectJob = ref<string[]>([]);
const jobOptions = [
  { label: "시설장", value: "시설장" },
  { label: "사회복지사", value: "사회복지사" },
  { label: "간호사", value: "간호사" },
  { label: "간호조무사", value: "간호조무사" },
  { label: "물리치료사", value: "물리치료사" },
  { label: "사무장", value: "사무장" },
  { label: "사무원", value: "사무원" },
  { label: "영양사", value: "영양사" },
  { label: "조리사", value: "조리사" },
  { label: "조리원", value: "조리원" },
  { label: "위생원", value: "위생원" },
  { label: "요양보호사", value: "요양보호사" },
  { label: "운전사", value: "운전사" },
  { label: "기타", value: "기타" },
];

const allSelectDie = () => {
  selectJob.value = [];
};

const code = ref(store.state.AdminSisulCode);
const ym = ref(store.state.AdminYYMM);
const loading = ref(false);
const ssWorkSchedules = ref<any[]>([]);

// 계산된 속성: 직업 필터링
const filteredWorkSchedules = computed(() => {
  if (selectJob.value.length === 0) {
    return ssWorkSchedules.value; // 필터가 없으면 전체 데이터 반환
  }
  return ssWorkSchedules.value.filter((item) => selectJob.value.includes(item.emp_job));
});

watch(
  () => ssWorkSchedules.value, 
  (newValue) => {
    if (newValue) {
      // ssWorkSchedules 배열의 각 항목에 대해 calcD, calcN, calcB, calcH 계산
      ssWorkSchedules.value.forEach((item) => {
        let countD = 0;
        let countN = 0;
        let countB = 0;
        let countH = 0;

        // d_1 ~ d_31까지 순회
        for (let i = 1; i <= 31; i++) {
          const key = `d_${i}`;
          if (item[key]) {
            if (item[key].includes("D")) countD++;
            if (item[key].includes("N")) countN++;
            if (item[key].includes("B")) countB++;
            if (item[key].includes("H")) countH++;
          }
        }

        // 계산된 count 값을 calcD, calcN, calcB, calcH에 추가
        item.calcD = countD;
        item.calcN = countN;
        item.calcB = countB;
        item.calcH = countH;
      });
    }
  },
  { immediate: true }  // 초기화 시점에 바로 실행
);

// 테이블 헤더 정의
const headers = [
  { title: "번호", align: "center", key: "ws_idx", sortable: false },
  { title: "직원명", align: "center", key: "emp_name", sortable: true },
  { title: "직무", align: "center", key: "emp_job", sortable: true },
  { title: "주간", align: "center", key: "", sortable: false },
  { title: "야간", align: "center", key: "", sortable: false },
  { title: "비번", align: "center", key: "", sortable: false },
  { title: "연차", align: "center", key: "", sortable: false },

  { title: "1", align: "center", key: "d_1", sortable: false },
  { title: "2", align: "center", key: "d_2", sortable: false },
  { title: "3", align: "center", key: "d_3", sortable: false },
  { title: "4", align: "center", key: "d_4", sortable: false },
  { title: "5", align: "center", key: "d_5", sortable: false },
  { title: "6", align: "center", key: "d_6", sortable: false },
  { title: "7", align: "center", key: "d_7", sortable: false },
  { title: "8", align: "center", key: "d_8", sortable: false },
  { title: "9", align: "center", key: "d_9", sortable: false },
  { title: "10", align: "center", key: "d_10", sortable: false },
  { title: "11", align: "center", key: "d_11", sortable: false },
  { title: "12", align: "center", key: "d_12", sortable: false },
  { title: "13", align: "center", key: "d_13", sortable: false },
  { title: "14", align: "center", key: "d_14", sortable: false },
  { title: "15", align: "center", key: "d_15", sortable: false },
  { title: "16", align: "center", key: "d_16", sortable: false },
  { title: "17", align: "center", key: "d_17", sortable: false },
  { title: "18", align: "center", key: "d_18", sortable: false },
  { title: "19", align: "center", key: "d_19", sortable: false },
  { title: "20", align: "center", key: "d_20", sortable: false },
  { title: "21", align: "center", key: "d_21", sortable: false },
  { title: "22", align: "center", key: "d_22", sortable: false },
  { title: "23", align: "center", key: "d_23", sortable: false },
  { title: "24", align: "center", key: "d_24", sortable: false },
  { title: "25", align: "center", key: "d_25", sortable: false },
  { title: "26", align: "center", key: "d_26", sortable: false },
  { title: "27", align: "center", key: "d_27", sortable: false },
  { title: "28", align: "center", key: "d_28", sortable: false },
  { title: "29", align: "center", key: "d_29", sortable: false },
  { title: "30", align: "center", key: "d_30", sortable: false },
  { title: "31", align: "center", key: "d_31", sortable: false },
] as any;
// 날짜 헤더 색상 계산
const getDayColor = (key: string) => {
  const day = parseInt(key.split("_")[1]);
  const date = new Date(`${ym.value}-${day < 10 ? "0" : ""}${day}`);
  const weekday = getDay(date); // 요일 구하기
  if (weekday === 0) return "red"; // 일요일 (red)
  if (weekday === 6) return "blue"; // 토요일 (blue)
  return "none"; // 기본 색상 없음
};
const filteredHeaders = computed(() => {
  const date = parse(store.state.AdminYYMM, "yyyy-MM", new Date());
  const lastDayOfMonth = getDaysInMonth(date);
  return headers.slice(0, 7).concat(headers.slice(7, 7 + lastDayOfMonth));
}) as any;

const autoFillInputs = () => {
  const values = ["D1", "N1", "B1", "H1", "H2"]; // 입력할 값 리스트
  ssWorkSchedules.value = ssWorkSchedules.value.map((item) => {
    // 날짜 컬럼에만 값 자동 할당
    Object.keys(item).forEach((key) => {
      if (key.startsWith("d_")) {
        item[key] = values[Math.floor(Math.random() * values.length)];
      }
    });
    return item;
  });
};

const getWorkScheduleList = async () => {
  try {
    if (loading.value) return;
    loading.value = true;
    const response = await axios.get(
      `/api/getWorkScheduleList/${code.value}/${ym.value}`
    );
    const responseData = response.data;
    ssWorkSchedules.value = responseData || [];
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};
const saveScheduleList = async () => {
  try {
    const form = ssWorkSchedules.value;
    if (form.length === 0) {
      return;
    }
    if (loading.value) return;
    loading.value = true;
    saveBtnLoading.value = true;
    const response = await axios.post(`/api/saveScheduleList/${ym.value}`, {
      form: form,
    });
    const result = response.data;
    if (result.success === true) {
      alert("저장이 완료되었습니다.");
    } else {
      alert("실패하였습니다. 관리자에게 문의해주세요.");
    }
    return result;
  } catch (error) {
    alert("작업을 처리할 수 없습니다.");
    console.error(error);
  } finally {
    loading.value = false;
    setTimeout(() => {
      saveBtnLoading.value = false;
    }, 5000);
  }
};
watch(
  () => store.state.AdminYYMM,
  async (newValue) => {
    if (newValue) {
      ym.value = store.state.AdminYYMM;
      await getWorkScheduleList();
    }
  }
);

onMounted(async () => {
  await getWorkScheduleList();
});
</script>
