<template>
  <v-card class="clientLeftCardHeight">
    <v-card-text>
      <v-divider class="mb-2"></v-divider>
      <v-table class="AdminCompInfoTb">
        <tbody>
          <tr>
            <td><b>기관 명</b></td>
            <td>{{ SsInfo.sisul_name }}</td>
          </tr>
          <tr>
            <td><b>기관대표사진</b></td>
            <td>
              <img
                @click="dialog = true"
                style="width: auto; height: 100px; cursor: pointer"
                :src="imageUrl"
                alt="Promotional Image"
              />
            </td>
          </tr>
          <tr>
            <td><b>계약서 인감</b></td>
            <td><AdminCompSignImg /></td>
          </tr>
          <tr>
            <td><b>서비스</b></td>
            <td>
              <div v-for="(service, index) in sortedServices" :key="index">
                <v-chip style="margin: 0 1px" :color="service.color">
                  <h4>{{ service.type }}</h4>
                </v-chip>
              </div>
            </td>
          </tr>
          <tr>
            <td><b>장기요양기관기호</b></td>
            <td>{{ SsInfo.sisul_code }}</td>
          </tr>
          <tr>
            <td><b>사업자등록번호</b></td>
            <td>{{ SsInfo.sisul_biz_num }}</td>
          </tr>
          <tr>
            <td><b>기관 주소</b></td>
            <td>{{ SsInfo.sisul_addr }}</td>
          </tr>
          <tr>
            <td><b>기관 전화번호</b></td>
            <td>{{ SsInfo.sisul_tell }}</td>
          </tr>
          <tr>
            <td><b>은행</b></td>
            <td>{{ SsInfo.sisul_bank }} / {{ SsInfo.sisul_bank_num }}</td>
          </tr>
          <tr>
            <td><b>예금주</b></td>
            <td>{{ SsInfo.sisul_bank_owner }}</td>
          </tr>
          <tr>
            <td><b>대표자 성명</b></td>
            <td>{{ SsInfo.sisul_boss_name }}</td>
          </tr>
          <tr>
            <td><b>대표자 번호</b></td>
            <td>{{ SsInfo.sisul_boss_phone }}</td>
          </tr>
          <tr>
            <td><b>대표자 이메일</b></td>
            <td>{{ SsInfo.sisul_boss_email }}</td>
          </tr>
        </tbody>
      </v-table>
      <v-divider class="mb-2"></v-divider>
      <v-divider class="mb-2"></v-divider>

      <SsBasicEdit @success="readSsId()" />
    </v-card-text>

    <v-dialog v-model="dialog" style="width: 700px" class="fontNotoSans400">
      <v-card>
        <div style="background-color: #333; color: #fff; padding: 10px 20px">
          <h3>이미지 변경 시 기존 이미지는 삭제됩니다.</h3>
        </div>
        <v-card-text>
          <div>
            <input
              type="file"
              accept="image/*"
              @change="handleFileUpload"
              style="width: 100%; margin-bottom: 10px; border-bottom: 2px solid #333"
            />
          </div>
          <div>
            <img
              :src="imageMiribogi || imageUrl"
              alt=" 이미지 확인 후 업로드버튼을 눌러주세요."
              style="max-width: 100%; max-height: 100%; margin-bottom: 5px"
            />
          </div>
        </v-card-text>
        <v-btn
          style="border-radius: 0 !important"
          @click="sendSisulMainImg"
          class="btnColorBlue"
          >업로드</v-btn
        >
        <v-btn
          style="border-radius: 0 !important"
          @click="dialog = false"
          class="btnColorDark"
          >닫기</v-btn
        >
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script setup lang="ts">
import store from "@/store";
import axios from "axios";
import { ref, onMounted, computed, onUnmounted } from "vue";
import {} from "@mdi/js";
import AdminCompSignImg from "@/components/sisul_comp/AdminCompSignImg.vue";
import SsBasicEdit from "@/components/sisul_comp/SsBasicEdit.vue";
const id = ref("") as any;

const SsInfo = ref([] as any);
type Service = {
  type: string;
  in: string;
  out: string | null;
  color: string;
};
const services = ref<Service[]>([]);

const sortedServices = computed(() => {
  return services.value
    .filter((service) => service.in && !service.out)
    .sort((a, b) => new Date(a.in).getTime() - new Date(b.in).getTime());
});

const dialog = ref(false);
const loading = ref(false);
const imageMiribogi = ref();
const imageFile = ref();
const ext = ref();
const imageType = ref();
const imageUrl = ref();
const imgName = ref();
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    ext.value = file.name.split(".").pop();
    const reader = new FileReader();
    reader.onload = () => {
      imageMiribogi.value = reader.result;
    };
    reader.readAsDataURL(file);
    imageFile.value = file;
  }
};
const fetchImage = async () => {
  imageUrl.value = await getSisulMainImg();
  console.log(imageUrl.value, "imageUrl.value");
};
const getSisulMainImg = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`/api/getSisulMainImg/${imgName.value}`, {
      responseType: "blob",
    });
    if (response.data) {
      //이미지타입 추출
      imageType.value = response.data.type.split("/")[1];
      if (imageType.value === "jpeg") {
        imageType.value = "jpg";
      }
      const imageBlob = response.data;
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL; // 가져온 이미지 URL을 반환
    }
  } catch (error) {
    const imageObjectURL = "nothing";
    return imageObjectURL;
  } finally {
    loading.value = false;
  }
};

const sendSisulMainImg = async () => {
  if (!imageFile.value) {
    alert("이미지를 선택하세요.");
    return;
  }
  const allowedTypes = ["image/jpeg", "image/png"];
  if (!allowedTypes.includes(imageFile.value.type)) {
    alert("JPG 또는 PNG 파일만 업로드 가능합니다.");
    loading.value = false;
    return;
  }
  loading.value = true;

  try {
    if (imageUrl.value !== "nothing") {
      await deleteSilsulMainImg();
    }
    const formData = new FormData();
    formData.append("image", imageFile.value);
    const response = await axios.post(
      `/api/sendSisulMainImg/${imgName.value}/${ext.value}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response && response.data.success) {
      await fetchImage();
      dialog.value = false;
    } else {
      console.error("이미지 업로드 실패", response.data);
    }
  } catch (error) {
    console.error("이미지 업로드 중 에러 발생", error);
  } finally {
    loading.value = false;
  }
};
const deleteSilsulMainImg = async () => {
  try {
    const response = await axios.post(`/api/deleteSilsulMainImg/${imgName.value}`);
    if (!response) {
      alert("기존 파일 삭제 에러 발생.");
      return;
    }
  } catch (error) {
    console.error("이미지 업로드 중 에러 발생", error);
  }
};

const readSsId = async () => {
  try {
    console.log(id.value, "id.value");
    const response = await axios.get(`/api/readSsId/${id.value}`);
    const responseData = response.data;
    if (responseData) {
      SsInfo.value = responseData;
      services.value = [
        {
          type: "노무관리",
          in: SsInfo.value.sisul_wage_in,
          out: SsInfo.value.sisul_wage_out,
          color: "blue-darken-2",
        },
        {
          type: "재무회계",
          in: SsInfo.value.sisul_fna_in,
          out: SsInfo.value.sisul_fna_out,
          color: "purple-darken-2",
        },
        {
          type: "위탁급식",
          in: SsInfo.value.sisul_meal_in,
          out: SsInfo.value.sisul_meal_out,
          color: "orange-darken-2",
        },
      ];
      imgName.value = SsInfo.value.sisul_code + "img";

      store.commit("setSsInfoBox", SsInfo.value);
      return responseData;
    } else {
      console.error("에러가 발생했습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
onMounted(async () => {
  id.value = store.state.AdminSisulId;
  await readSsId();
  await fetchImage();
});
onUnmounted(() => {
  sessionStorage.removeItem("SsInfoBox");
});
</script>
