<template>
  <v-card class="workMainCard workMain2Card">
    <div class="card-content">
      <p class="workMainTitle">휴가 현황</p>
      <v-btn
        :prepend-icon="mdiFilterOutline"
        @click="dialog = true"
      >필터</v-btn>
    </div>

    <v-data-table
  v-if="!loading && filteredEmpVacations.length > 0"
  :headers="headers"
  :items="filteredEmpVacations"
  item-value="name"
  no-data-text="데이터가 존재하지 않습니다."
  fixed-header
  density="compact"
  scrollable
  class="workMain2_1Tb"
  :items-per-page="-1"
  :items-per-page-options="[
    { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
    { value: 50, title: '50' },
  ]"
>
  <template v-slot:item="{ item }">
    <tr>
      <td class="tac">{{ item.emp_name }}</td>
      <td class="tac">{{ item.emp_job }}</td>
      <template v-for="(type, index) in holidayTypes" :key="index">
        <td class="tac">
          <div class="flexCenter">
            <span class="holidayNumber">{{ item[type] }}</span>
            <v-progress-linear
              :model-value="(item[type] * 100) / item[`${type}_total`]"
              :color="holidayColors[index]"
              class="ml-2 mr-2"
            ></v-progress-linear>
            <span class="holidayNumber">{{ item[`${type}_total`] }}</span>
          </div>
        </td>
      </template>
    </tr>
  </template>
</v-data-table>

    <div v-if="loading">
      <AdminLoadingAction />
    </div>
  </v-card>

  <v-dialog
    v-model="dialog"
    width="500px"
    class="fontNotoSans400"
  >
    <v-card>
      <v-card-title class="tac" style="padding: 10px 0">
        <b style="margin-left: 109px">직원검색필터</b>
        <v-btn
          style="float: right; margin-right: 10px"
          class="btnColorWhite"
          @click="allSelectDie()"
          >전체 체크해제</v-btn
        >
      </v-card-title>
      <v-card-actions>
        <v-sheet>
          <div v-for="job in jobOptions" :key="job.value" class="inline-div">
            <v-checkbox
              class="tal"
              :label="job.label"
              v-model="selectJob"
              :value="job.value"
              hide-details
            />
          </div>
        </v-sheet>
      </v-card-actions>
      <v-btn @click="dialog = false" class="cancelRegistBtn" style="border-radius: 0"
        >닫기</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import { mdiFilterOutline } from "@mdi/js";
import store from "@/store";
import AdminLoadingAction from "@/components/admin/layout/AdminLoadingAction.vue";
const dialog = ref(false);
const selectJob = ref<string[]>([]);
const jobOptions = [
  { label: "시설장", value: "시설장" },
  { label: "사회복지사", value: "사회복지사" },
  { label: "간호사", value: "간호사" },
  { label: "간호조무사", value: "간호조무사" },
  { label: "물리치료사", value: "물리치료사" },
  { label: "사무장", value: "사무장" },
  { label: "사무원", value: "사무원" },
  { label: "영양사", value: "영양사" },
  { label: "조리사", value: "조리사" },
  { label: "조리원", value: "조리원" },
  { label: "위생원", value: "위생원" },
  { label: "요양보호사", value: "요양보호사" },
  { label: "운전사", value: "운전사" },
  { label: "기타", value: "기타" },
];
const holidayTypes = ['eh_basic', 'eh_replace', 'eh_summer', 'eh_special', 'eh_etc'];
const holidayColors = ['dark', 'blue', 'orange', 'purple', 'gray'];
const allSelectDie = () => {
  selectJob.value = [];
};

const code = ref(store.state.AdminSisulCode);
const ymd = ref(store.state.AdminDate);
const loading = ref(false);
const empVacations = ref<any[]>([]);

// 계산된 속성: 직업 필터링
const filteredEmpVacations = computed(() => {
  if (selectJob.value.length === 0) {
    return empVacations.value; // 필터가 없으면 전체 데이터 반환
  }
  return empVacations.value.filter((item) => selectJob.value.includes(item.emp_job));
});

// 테이블 헤더 정의
const headers = [
  { title: "직원명", align: "center", key: "emp_name", sortable: true },
  { title: "직무", align: "center", key: "emp_job", sortable: true },
  { title: "연차휴가", align: "center", key: "eh_basic", sortable: true },
  { title: "대체휴가", align: "center", key: "eh_replace", sortable: true },
  { title: "여름휴가", align: "center", key: "eh_summer", sortable: true },
  { title: "특별휴가", align: "center", key: "eh_special", sortable: true },
  { title: "기타휴가", align: "center", key: "eh_etc", sortable: true },
] as any;

const getEmpVacationList = async () => {
  try {
    if (loading.value) return;
    loading.value = true;
    const response = await axios.get(
      `/api/getEmpVacationList/${code.value}/${ymd.value}`
    );
    const responseData = response.data;
    empVacations.value = responseData || [];
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    loading.value = false;
  }
};
watch(
  () => store.state.AdminDate,
  async (newValue) => {
    if (newValue) {
      ymd.value = store.state.AdminDate;
      await getEmpVacationList();
    }
  }
);

onMounted(async () => {
  await getEmpVacationList();
});
</script>
