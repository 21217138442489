<template>
    <div class="fade-in-up">

    <h2 class="registMainTitle">기관의 정보를 입력해주세요</h2>

  <div class="wageClientInfoForm">
    <p class="tac registSubTitle" style="font-size: 14px; opacity: 0.8; margin: 3px 0 25px 0">
      회원가입에 필요한 정보를 입력해주세요.
    </p>
    <v-text-field :prepend-inner-icon="mdiNoteTextOutline" variant="outlined" label="사업자등록번호" type="text"
      v-model="form.sisul_biz_num" maxlength="11" :rules="bizNumberRule" color="indigo" autocomplete="off" />
          
    <v-text-field
      :prepend-inner-icon="mdiAccountTie"
      variant="outlined"
      label="대표자 명"
      type="text"
      v-model="form.sisul_boss_name"
      maxlength="10"
      :rules="bossNameRule"
      color="indigo"
      autocomplete="off"
    />
    <v-text-field
      :prepend-inner-icon="mdiCellphone"
      variant="outlined"
      label="대표자 휴대폰 번호"
      type="text"
      v-model="form.sisul_boss_phone"
      maxlength="11"
      :rules="phoneNumberRule"
      color="indigo"
      autocomplete="off"
    />
    <v-btn
      :class="{ clientInfoBtn: status, 'not-agree-disabled': !status }"
      @click="getForm()"
      >확인</v-btn
    >
  </div>
  <p class="registPrevBtn" @click="prev()">이전단계로 돌아가기</p>

  </div>
</template>
<script setup lang="ts">
import { mdiAccountTie, mdiCellphone, mdiNoteTextOutline } from "@mdi/js";
import { phoneNumberRule, bossNameRule, bizNumberRule } from "../../composables/rules";
import { reactive, ref, watch } from "vue";
import store from "@/store";

const status = ref(false);
const form = reactive({
  sisul_boss_name: "",
  sisul_boss_phone: "",
  sisul_biz_num: ''
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["success", "prev"]);
const prev = () => {
  emit('prev');
}
watch(form, async () => {
  const checkAndLog = (rule: any, value: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      return true;
    } else {
      return false;
    }
  };
  const bizNumValid = bizNumberRule.some((rule) =>
    checkAndLog(rule, form.sisul_biz_num)
  );
  const bossNameValid = bossNameRule.some((rule) =>
    checkAndLog(rule, form.sisul_boss_name)
  );
  const phoneValid = phoneNumberRule.some((rule) =>
    checkAndLog(rule, form.sisul_boss_phone)
  );

  if (form.sisul_boss_phone && form.sisul_boss_name && !bizNumValid && !bossNameValid && !phoneValid) {
    status.value = true;
  } else {
    status.value = false;
  }
});
const getForm = async () => {
  const checkAndLog = (rule: any, value: any, ruleName: any) => {
    const ruleResult = rule(value);
    if (typeof ruleResult === "string") {
      alert(`${ruleName}`);
      return true;
    } else {
      return false;
    }
  };
  const bizNumValid = bizNumberRule.some((rule) =>
    checkAndLog(rule, form.sisul_biz_num, "올바른 사업자등록번호를 작성해주세요.")
  );
  const bossNameValid = bossNameRule.some((rule) =>
    checkAndLog(rule, form.sisul_boss_name, "올바른 성함을 작성해주세요.")
  );
  const phoneValid = phoneNumberRule.some((rule) =>
    checkAndLog(rule, form.sisul_boss_phone, "올바른 번호를 작성해주세요.")
  );


  if (!form.sisul_boss_phone || !form.sisul_boss_name || bizNumValid || bossNameValid || phoneValid) {
    return;
  } else {
    let registForm = store.state.RegistForm;
    registForm.sisul_biz_num = form.sisul_biz_num;
    registForm.sisul_boss_name = form.sisul_boss_name;
    registForm.sisul_boss_phone = form.sisul_boss_phone;
    store.commit("setRegistForm", registForm);
    emit("success");
  }
};
</script>
