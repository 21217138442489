<template>
  <v-sheet
    class="registMainSheet"
  >
    <v-card
      class="registMainCard"
      elevation="0"
    >
      <SsRegistEmail v-if="step === 0" @success="next()" />
      <SsRegistFindOrg v-if="step === 1" @success="next()" />
      <SsRegistWhoIsBoss v-if="step === 2" @success="next()" @prev="prev()"  />
      <SsRegistService v-if="step === 3" @success="next()" @prev="prev()"  />
      <SsRegistBank v-if="step === 4" @success="next()" @prev="prev()"  />
      <SsRegistCMS v-if="step === 5" @success="next()" @prev="prev()"  />
      <SsRegistPassword v-if="step === 6" @success="next()" @prev="prev()"  />
      <SsRegistComplete v-if="step === 7" />
    </v-card>
  </v-sheet>
</template>

<script setup lang="ts">
import { ref } from "vue";
import SsRegistEmail from "@/components/login-regist/SsRegistEmail.vue";
import SsRegistFindOrg from "@/components/login-regist/SsRegistFindOrg.vue";
import SsRegistWhoIsBoss from "@/components/login-regist/SsRegistWhoIsBoss.vue";
import SsRegistService from "@/components/login-regist/SsRegistService.vue";
import SsRegistBank from "@/components/login-regist/SsRegistBank.vue";
import SsRegistCMS from "@/components/login-regist/SsRegistCMS.vue";
import SsRegistPassword from "@/components/login-regist/SsRegistPassword.vue";
import SsRegistComplete from "@/components/login-regist/SsRegistComplete.vue";

const step = ref(0);

const next = () => {
  if (step.value < 7) {
    step.value += 1;
  }
};
const prev = () => {
  if (step.value > 0) {
    step.value -= 1;
  }
};
</script>
